import Axios from "axios";
import FamilyAction from "MetaCell/actions/Family";
import GenericApi from "Api";

export default class FamilyApi {
  /**
   * it generates the url to the family api
   * @param {Number} id - the family id if there is one
   * @return {String} - the generated family endpoint url
   */
  static familiesUrl = (id = "") => `${GenericApi.getBaseUrl()}/families/${id}`;

  /**
   * the url for family members endpoint
   * @param {Number} id - the family id
   * @return {String} - the family members endpoint url
   */
  static membersByFamilyUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/familymembers/?family=${id}`;

  /**
   * the url for family member endpoint
   * @param {Number} id - the member id
   * @return {String} - the family member endpoint url
   */
  static memberUrl = (memberId = "") =>
    `${GenericApi.getBaseUrl()}/familymembers/${memberId}`;

  /**
   * @param {Number} id - the member id
   * @return {String} - url to get info about the family member structure
   */
  static memberStructureInfoUrl = memberId =>
    `${this.memberUrl(memberId)}/structure_info`;

  static getFamilyMemberDiscretizedUrl = familyMemberId =>
    `${GenericApi.getBaseUrl()}/familymembers/${familyMemberId}/discretized`;

  /**
   * the url for family simulation jobs
   * @param {Number} id - the family id
   * @return {String} - the endpoint url for family simulation jobs
   */
  static simulationJobsUrl = familyId =>
    `${GenericApi.getBaseUrl()}/family/${familyId}/jobs`;

  static findCompatibleJobUrl = familyId =>
    `${GenericApi.getBaseUrl()}/family/${familyId}/jobs/find_compatible/`;

  static createFamilyMembersJobUrl = familyId =>
    `${GenericApi.getBaseUrl()}/family/${familyId}/jobs/create_family_members/`;

  static getFamilyPdkPreferencesUrl = familyId =>
    `${GenericApi.getBaseUrl()}/family/${familyId}/pdk_preferences`;

  static getFamilyStructuresUrl = familyId =>
    `${GenericApi.getBaseUrl()}/family/${familyId}/structure_info`;

  /**
   * it fetches the family simulation jobs
   * @return {Promise} the promise from the axios call
   */
  static fetchSimulationJobs = (familyId = "") => {
    const url = FamilyApi.simulationJobsUrl(familyId);
    return Axios.get(url)
      .then(res => {
        const simJobs = res.data;
        return simJobs;
      })
      .catch(error => {
        console.log("Family members error from API: ", error.message);
      });
  };

  /**
   * it can fetch all the existing families in the api's database
   * @return {Function} a function that receive a dispatcher to redux
   */
  static fetchFamilies = (id = "") => {
    return dispatch => {
      const url = FamilyApi.familiesUrl(id);
      return Axios.get(url)
        .then(res => {
          const families = res.data;
          const onlyOneFamily = id !== "";
          if (onlyOneFamily) {
            dispatch(FamilyAction.upsertFamilies([families]));
          } else {
            dispatch(FamilyAction.upsertFamilies(families));
          }
          return families;
        })
        .catch(error => {
          console.log("Families error from API: ", error.message);
        });
    };
  };

  /**
   * it fetches the family members
   * @return {Promise} the promise from the axios call
   */
  static fetchMembersByFamily = id => {
    const url = FamilyApi.membersByFamilyUrl(id);
    return Axios.get(url)
      .then(res => {
        const members = res.data;
        return members;
      })
      .catch(error => {
        console.log("Family members error from API: ", error.message);
      });
  };

  /**
   * @return {Function} a function that receives a dispatcher to redux,
   * makes an backend api call to retrieve all family members and dispatch them to redux store
   */
  static fetchFamilyMembers = () => dispatch =>
    Axios.get(this.memberUrl())
      .then(res => res.data)
      .then(familyMembers =>
        dispatch(FamilyAction.upsertFamilyMembers(familyMembers))
      );

  static fetchFamilyMemberStructureInfo = familyMemberId =>
    Axios.get(this.memberStructureInfoUrl(familyMemberId)).then(
      res => res.data
    );

  static fetchFamilyStructures = familyId =>
    Axios.get(this.getFamilyStructuresUrl(familyId)).then(res => res.data);

  static fetchFamilyMemberDiscretized = (familyMemberId, simulationId) =>
    Axios.get(this.getFamilyMemberDiscretizedUrl(familyMemberId), {
      params: { simulation_id: simulationId }
    }).then(res => res.data);

  /**
   * it updates a family member
   * @return {Promise} the promise from the axios call
   */
  static updateMember = (memberId, newData) => {
    const url = FamilyApi.memberUrl(memberId);
    return Axios.patch(url, newData)
      .then(res => {
        const member = res.data;
        return member;
      })
      .catch(error => {
        console.log("Family member error from API: ", error.message);
      });
  };

  static startCreateFamilyMembersJob = familyMembers => {
    return Axios.post(FamilyApi.memberUrl(), familyMembers).then(
      res => res.data
    );
  };

  static createFamilyMembersJobProgress = (familyId, ignore_errors = false) => {
    const url = this.createFamilyMembersJobUrl(familyId) + "progress";
    if (ignore_errors) {
      return Axios.get(url);
    } else {
      return GenericApi.runApiCall(
        Axios.get(url),
        "Failed to save family members"
      );
    }
  };

  static createFamilyMembersJobResults = familyId => {
    const url = this.createFamilyMembersJobUrl(familyId) + "results";
    return GenericApi.runApiCall(
      Axios.get(url),
      "Failed to retrieve family members"
    );
  };

  /**
   * it makes a request to delete a family member from the database
   * @param {Object[]} familyId - which family the members belong to
   * @param {Number} memberId - the member id
   * @return {Promise} the promise from the axios call
   */
  static deleteMember = (familyId, memberId) => {
    const url = FamilyApi.memberUrl(memberId);
    return dispatch => {
      return Axios.delete(url)
        .then(() => dispatch(FamilyAction.deleteFamilyMember([memberId])))
        .then(() => this.fetchFamilies(familyId)(dispatch));
    };
  };

  /**
   * it makes a request to delete multiple family members
   * @param {Number} familyId - the family to which the members belong
   * @param {Number[]} memberIds - the family members' ids
   */
  static deleteMembers = (familyId, memberIds) => {
    const url = FamilyApi.memberUrl();
    return dispatch => {
      return Axios.delete(url, { data: memberIds })
        .then(() => dispatch(FamilyAction.deleteFamilyMember(memberIds)))
        .then(() => this.fetchFamilies(familyId)(dispatch));
    };
  };

  /**
   * it makes a request to create a family in the database
   * @param {Object} family - the family object
   * @returns {Function} a function that receives the redux dispatcher
   */
  static createFamily = family => {
    return dispatch => {
      return Axios.post(FamilyApi.familiesUrl(), family)
        .then(response => response.data)
        .then(createdFamily =>
          dispatch(FamilyAction.upsertFamilies([createdFamily]))
        )
        .catch(error =>
          console.log("Families error from API: ", error.message)
        );
    };
  };

  /**
   * it makes a request to delete a family from the database
   * @param {Number} familyId - the family id
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteFamily = familyId => {
    return dispatch => {
      return Axios.delete(FamilyApi.familiesUrl(familyId)).then(() =>
        dispatch(FamilyAction.deleteFamily(familyId))
      );
    };
  };

  /**
   * it makes a request to delete multiple families
   * @param {Number[]} familyIds - the family ids
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteFamilies = familyIds => {
    return dispatch => {
      return Axios.delete(FamilyApi.familiesUrl(), {
        data: familyIds
      }).then(() => dispatch(FamilyAction.deleteFamily(familyIds)));
    };
  };

  /**
   * it makes a request to update a family from the database
   * @param {Number} familyId - the family id
   * @returns {Function} a function that receives the redux dispatcher
   */
  static updateFamily = (familyId, newData) => {
    return dispatch => {
      return Axios.put(FamilyApi.familiesUrl(familyId), newData)
        .then(({ data }) => dispatch(FamilyAction.updateFamily(data)))
        .catch(error => {
          console.log("error from API:", error.message);
        });
    };
  };

  static startFindCompatibleJob = id => {
    const url = this.findCompatibleJobUrl(id) + "start";
    return Axios.post(url, {}).then(res => res);
  };

  static stopFindCompatibleJob = id => {
    const url = this.findCompatibleJobUrl(id) + "stop";
    return Axios.post(url, {}).then(res => res);
  };

  static findCompatibleJobProgress = id => {
    const url = this.findCompatibleJobUrl(id) + "progress";
    return GenericApi.runApiCall(
      Axios.get(url),
      "Failed to find compatible jobs"
    );
  };

  static getFamilyPdkPreferences = id => {
    const url = this.getFamilyPdkPreferencesUrl(id);
    return GenericApi.runApiCall(
      Axios.get(url),
      "Failed to get PDK preferences for selected family"
    );
  };
}
