import React, { PureComponent } from "react";
import LayerStack from "./components/LayerStack/LayerStack";
import MetaCellGlobalParameters from "./components/MetaCellGlobalParameters/MetaCellGlobalParameters";
import Preview2D from "./components/Preview2D/Preview2D";
import "./index.css";
import { metaCellPaths } from "MetaCell/MetaCell";
import Grid from "@material-ui/core/Grid";
import OpenSimulationSideView from "MetaCell/components/OpenSimulationSideView/OpenSimulationSideView";
import Spinner from "components/Spinner/Spinner";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import StructureSelector from "MetaCell/selectors/Structure";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import StructureApi from "MetaCell/api/Structure";

const styles = {
  progress: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 50
  }
};

/**
 * a class component to show structure components
 * @Ibtihel
 * TODO: extract the similarities it has with other canvases
 */
export class StructureCanvas extends PureComponent {
  /**
   * it sets the open page for this component as soon as the component mounts
   */
  componentDidMount() {
    this.props.setPage(metaCellPaths.STRUCTURE);
    const {
      simulations,
      simulationOpenId,
      familyStructures,
      fetchFamilyStructures
    } = this.props;
    const familyId = simulations?.byId[simulationOpenId]?.family_id;

    if (simulationOpenId !== -1 && familyId && familyStructures.length === 0) {
      fetchFamilyStructures(familyId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      simulations,
      simulationOpenId,
      familyStructures,
      fetchFamilyStructures
    } = this.props;

    if (
      prevProps.simulationOpenId !== simulationOpenId &&
      simulationOpenId !== -1 &&
      !familyStructures
    ) {
      const familyId = simulations.byId[simulationOpenId]?.family_id;
      if (
        simulationOpenId !== -1 &&
        familyId &&
        familyStructures.length === 0
      ) {
        fetchFamilyStructures(familyId);
      }
    }
  }

  /**
   * it calls the editing saver for this component as soon as the component unmounts
   */
  componentWillUnmount() {
    this.saveEditings();
  }

  saveEditings = () => {
    this.props.saveEditings(metaCellPaths.STRUCTURE);
  };

  render() {
    const { isFetching, classes, simulations, simulationOpenId } = this.props;
    if (isFetching) {
      return (
        <div className={classes.progress}>
          <Spinner name="Waiting" size={68} timeout={30000} />
        </div>
      );
    }
    const isFamilySimulation = !!simulations?.byId[simulationOpenId]?.family_id;

    return (
      <div style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item direction="column" xs={7}>
            <LayerStack isFamilySimulation={isFamilySimulation} />
          </Grid>
          <Grid item direction="column" xs={5}>
            <Grid xs>
              <MetaCellGlobalParameters
                onSave={this.saveEditings}
                isFamilySimulation={isFamilySimulation}
              />
            </Grid>
            <Grid container spacing={2} xs>
              <Grid item xs={7}>
                <div style={{ width: "100%", padding: 10 }}>
                  <Preview2D isFamilySimulation={isFamilySimulation} />
                </div>
              </Grid>
              <Grid item xs={5}>
                <OpenSimulationSideView
                  title={"Side view"}
                  selectedLayerId={this.props.selectedLayerId}
                  showDownloadButton={true}
                  isFamilySimulation={isFamilySimulation}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapState = state => ({
  selectedLayerId: StructureSelector.getSelectedLayerId(state),
  simulations: DirectoryExplorerSelector.getSimulations(state),
  simulationOpenId: DirectoryExplorerSelector.getSimulationOpenId(state),
  familyStructures: StructureSelector.getFamilyStructures(state),
  selectedFamilyMemberId: StructureSelector.getSelectedFamilyMemberId(state)
});

const mapDispatchToProps = dispatch => ({
  fetchFamilyStructures: familyId =>
    dispatch(StructureApi.fetchFamilyStructures(familyId))
});

export default connect(
  mapState,
  mapDispatchToProps
)(withStyles(styles)(StructureCanvas));
