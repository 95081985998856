import React, { PureComponent } from "react";
import { withStyles, TextField, Input, Checkbox } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as yup from "yup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import { isEqual } from "lodash";
import NumberInput from "components/NumberInput/NumberInput";
import ParametersView from "./components/SweepParametersView/SweepParametersView";
import VariableAssignmentExamplesDialog from "./components/VariableAssignmentExamples/VariableAssignmentExamples";
import GenericTextInput from "../GenericTextInput/GenericTextInput";
import HelperUtils from "MetaCell/helper/HelperUtils";
import { omitBy } from "lodash";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box",
    paddingRight: "20px"
  },
  inputTooltip: {
    whiteSpace: "pre-line"
  }
};

/**
 * it builds a tooltip for the sweep data input
 * @param {String[]} params - the sweep data parameters
 * @returns {String} the tooltip text
 */
const makeSweepDataTooltip = params => {
  return `${params.join(";")}\u000d
    The accepted separators are colon and semicolon.
  `;
};

export const sweepData = {
  Linear: {
    text: "Linear",
    explanation: makeSweepDataTooltip(["start", "stop", "stepsize"])
  },
  Exponential: {
    text: "Exponential",
    explanation: makeSweepDataTooltip([
      "ampl",
      "tau",
      "t_start",
      "t_stop",
      "t_step"
    ])
  },
  Formula: {
    text: "Formula",
    explanation: "Add custom sweep variables with formula. e.g. radius/2",
    canHide: true
  },
  Optimization: {
    text: "Optimization",
    explanation: makeSweepDataTooltip(["start", "stop", "initial guess"]),
    canHide: true
  },
  Family: {
    text: "Meta cell group",
    explanation:
      "Meta cells of the selected meta cell group, which will be used in the simulation.",
    isDisabled: true
  }
};

const blockedWords = ["sin", "cos", "tan", "sqrt", "log", "pi", "deg"];

export const getValidationSchema = (data, aliasList) => {
  let parametersRegex;
  const { types } = data,
    invalidInputText = "Invalid input",
    requiredFieldText = "This field is required",
    uniqueFieldText = "This field must be unique",
    noSlashText = "Slashes are not allowed",
    noSlashRegex = /^[^\/]*$/,
    startsWithCharRegex = /^[a-zA-Z]/,
    nameRegex = /^[a-zA-Z]([a-zA-Z0-9_]*?)$/;
  if (types === "Linear" || types === "Optimization") {
    parametersRegex = /^(\-?(\d*\.?\d+))[:;](\-?(\d*\.?\d+))[:;](\d*\.?\d+)$/;
  } else if (types === "Exponential") {
    parametersRegex = /^(\d*\.?\d+)[:;](\d*\.?\d+)[:;](\-?(\d*\.?\d+))[:;](\-?(\d*\.?\d+))[:;](\d*\.?\d+)$/;
  }
  return yup.object().shape({
    parameters: yup
      .string()
      .required(requiredFieldText)
      .matches(parametersRegex, invalidInputText)
      .test(
        "start is smaller than stop",
        "stop parameter should be greater than start",
        function(value) {
          const parameters = value.split(/[:;]/),
            start = parameters[parameters.length - 3],
            stop = parameters[parameters.length - 2];
          return parseFloat(start) < parseFloat(stop);
        }
      )
      .test(
        "step not 0",
        types === "Linear" || types === "Exponential"
          ? "step cannot be 0"
          : "initial guess should lie between start and stop",
        function(value) {
          if (types === "Linear" || types === "Exponential") {
            const parameters = value.split(/[:;]/),
              step = parameters[parameters.length - 1];
            return parseFloat(step) !== 0;
          } else if (types === "Optimization") {
            const optimizerSweepParameters = value.split(/[:;]/),
              start =
                optimizerSweepParameters[optimizerSweepParameters.length - 3],
              stop =
                optimizerSweepParameters[optimizerSweepParameters.length - 2],
              initialGuess =
                optimizerSweepParameters[optimizerSweepParameters.length - 1];
            return (
              parseFloat(initialGuess) >= start &&
              parseFloat(initialGuess) <= stop
            );
          }
        }
      ),
    name: yup
      .string()
      .required(requiredFieldText)
      .matches(noSlashRegex, noSlashText)
      .matches(startsWithCharRegex, "Variable name should start with a letter")
      .matches(nameRegex, "No special characters allowed")
      .test(
        "existsCheck",
        uniqueFieldText,
        value => !aliasList.includes(value.toLowerCase())
      )
      .test("not-blocked-word", "", function(value) {
        const foundBlockedWord = blockedWords.find(
          blockedWord => value === blockedWord
        );
        if (foundBlockedWord) {
          return this.createError({
            message: `The word "${foundBlockedWord}" is not allowed as a variable name`
          });
        }
        return true;
      })
      .test("not-containing-blocked-word", "", function(value) {
        const foundBlockedWord = blockedWords.find(blockedWord =>
          value.includes(blockedWord)
        );
        if (foundBlockedWord) {
          return this.createError({
            message: `Variable name cannot contain the word "${foundBlockedWord}"`
          });
        }
        return true;
      }),
    is_general_parameter: yup.boolean()
  });
};

export const validateVariableData = (
  data,
  aliasList,
  { sweptVariables = [], simulationId }
) => {
  data.name = data.name?.replace(/ /g, "_");
  const trimmedParameters = data.parameters?.replace(/\s+/g, ""),
    originalFormula = data.formula?.replace(/\s+/g, ""),
    trimmedFormula = originalFormula
      ?.replace(/\s+/g, "")
      .replace(/\[+/g, "")
      .replace(/\]+/g, "");

  if (data.id) {
    sweptVariables = sweptVariables.filter(sw => sw.id != data.id);
  }
  data.parameters = trimmedParameters;
  data.formula = trimmedFormula;
  let sweptVariableFound = false;
  if (data.types == "Formula") {
    const name = data.name;
    const foundBlockedWord = blockedWords.find(blockedWord =>
      name.includes(blockedWord)
    );
    if (foundBlockedWord) {
      return Promise.reject({
        path: "name",
        message: `Variable name cannot contain the word "${foundBlockedWord}"`
      });
    }

    const value = data.formula;
    sweptVariableFound =
      sweptVariables &&
      sweptVariables.some(
        ({ simulation, variableName }) =>
          simulation === simulationId && value.includes(variableName)
      );
    if (!sweptVariableFound)
      return Promise.reject("Some of the sweep variables were not found");
    const sweptVariableFoundInsideMathExpression =
      sweptVariables &&
      sweptVariables.some(
        ({ simulation, variableName }) =>
          simulation === simulationId &&
          value.includes(variableName) &&
          HelperUtils.validateMathExpression(
            value,
            sweptVariables
              .filter(sv => sv.simulation == simulationId)
              .map(sv => sv.variableName)
          )
      );
    if (!sweptVariableFoundInsideMathExpression)
      return Promise.reject("Bad input for formula");
    data.formula = originalFormula;
    return Promise.resolve(data);
  } else {
    const schema = getValidationSchema(data, aliasList);
    return schema.validate(data);
  }
};

export const makeParametersColumn = (
  error,
  inputTooltipClassName,
  restrictFormula = false
) => ({
  title: restrictFormula ? "Parameters" : "Parameters/Formula",
  field: "parameters",
  editComponent: ({ value, onChange, rowData, onRowDataChange }) => {
    if (rowData?.types != "Formula") {
      var hasError = error && error["parameters"] ? true : false,
        tooltipText = rowData.explanation || sweepData.Linear.explanation;
      return (
        <FormControl {...(hasError ? { error: true } : {})}>
          <Tooltip
            classes={{ tooltip: inputTooltipClassName }}
            title={<Typography color="inherit">{tooltipText}</Typography>}
            placement="bottom"
            style={{ fontSize: "18px !important" }}
          >
            <NumberInput
              validCharacters={[";", ":"]}
              value={value || ""}
              onKeyDown={e => {
                if (e.keyCode === 13 || e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              allowNegative={
                tooltipText === sweepData.Linear.explanation
                  ? [true, true, false]
                  : [false, false, true, true, false]
              }
              onChange={event => {
                onChange(event.target.value);
              }}
              error={hasError}
            />
          </Tooltip>
          {hasError && <FormHelperText>{error["parameters"]}</FormHelperText>}
        </FormControl>
      );
    } else {
      hasError = error && error["parameters"] ? true : false;
      return (
        <FormControl {...(hasError ? { error: true } : {})}>
          <GenericTextInput
            value={rowData.formula}
            handleUpdateField={e => {
              onRowDataChange({
                ...rowData,
                ...{
                  formula: e.target.value
                }
              });
            }}
            error={hasError}
          ></GenericTextInput>
          {hasError && <FormHelperText>{error["parameters"]}</FormHelperText>}
        </FormControl>
      );
    }
  },
  render: rowData => {
    if (rowData.is_dependent) {
      return <span>{rowData.formula}</span>;
    } else {
      return <ParametersView parameters={rowData.parameters} />;
    }
  }
});

const columns = (
  ref,
  error,
  setState,
  inputTooltipClassName,
  restrictFormula
) => [
  {
    title: "Alias",
    field: "name",
    editComponent: editProps => {
      return (
        <FormControl {...(error && error.name ? { error: true } : {})}>
          <Input
            onChange={e => editProps.onChange(e.target.value)}
            value={editProps.value}
            error={error.name}
            onKeyDown={e => {
              if (e.keyCode === 13 || e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            helperText={error.name}
            name="name"
            inputProps={{ id: "aliasInput" }}
            autoFocus={true}
          />
          {error && error.name && <FormHelperText>{error.name}</FormHelperText>}
        </FormControl>
      );
    }
  },
  {
    title: "Type",
    field: "types",
    editComponent: props => {
      if (!ref.current) {
        setState({ error: {} });
      }
      const hasError = error && error.types ? true : false;
      const sweepDataKeys = Object.keys(
        restrictFormula
          ? omitBy(sweepData, (v, k) => v.canHide)
          : omitBy(sweepData, (v, k) => v.isDisabled)
      );
      return (
        <FormControl {...(hasError ? { error: true } : {})}>
          <Select
            ref={ref}
            value={props.value}
            inputProps={{
              name: "SweptVarTypes"
            }}
            onChange={e => {
              if (e.keyCode === 13 || e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
              } else {
                const { target } = e;
                props.onRowDataChange({
                  ...props.rowData,
                  ...{
                    types: target.value,
                    explanation: sweepData[target.value].explanation
                  }
                });
              }
            }}
          >
            {sweepDataKeys.map(key => (
              <MenuItem name="SelectOptions" value={key} key={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
          {hasError && <FormHelperText>{error.types}</FormHelperText>}
        </FormControl>
      );
    },
    render: rowData => {
      const text = rowData.types ? sweepData[rowData.types].text : "";
      return <div>{text}</div>;
    }
  },
  makeParametersColumn(error, inputTooltipClassName, restrictFormula),
  {
    title: "General Parameter",
    field: "is_general_parameter",
    render: rowData => {
      return rowData.types === "Optimization" ? (
        <Checkbox
          color="primary"
          checked={rowData.is_general_parameter}
          disabled
        />
      ) : (
        <Checkbox color="primary" indeterminate disabled />
      );
    },
    editComponent: props => {
      return props.rowData.types === "Optimization" ? (
        <Checkbox
          color="primary"
          checked={props.value}
          onChange={e => {
            props.onRowDataChange({
              ...props.rowData,
              ...{
                is_general_parameter: e.target.checked
              }
            });
          }}
        />
      ) : (
        <Checkbox color="primary" indeterminate disabled />
      );
    }
  }
];

export const components = error => ({
  EditField: ({ value, onChange, columnDef }) => {
    const hasError = error && error[columnDef.field] ? true : false;
    return (
      <FormControl {...(hasError ? { error: true } : {})}>
        <TextField
          name="SweptVarInput"
          value={value === undefined ? "" : value}
          onChange={event => onChange(event.target.value)}
          error={hasError}
        />
        {hasError && <FormHelperText>{error[columnDef.field]}</FormHelperText>}
      </FormControl>
    );
  }
});

/**
 * @todo write tests for this component.
 */
export class SweepOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      error: {},
      data: null,
      examplesDialogOpen: false
    };
  }

  componentDidMount() {
    const { sweptVariables, simulationId } = this.props;
    if (sweptVariables && simulationId) {
      this.setData(this.props.sweptVariables, this.props.simulationId);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.simulationId !== this.props.simulationId) {
      this.setState({ data: null });
    }
    if (
      (!nextState.data && nextProps.sweptVariables) ||
      !isEqual(nextProps.sweptVariables, this.props.sweptVariables)
    ) {
      this.setData(nextProps.sweptVariables, nextProps.simulationId);
    }
    return true;
  }

  setData = (sweptVariables, simulationId) => {
    this.setState({
      data: sweptVariables
        .filter(({ simulation }) => simulation === simulationId)
        .map(
          (
            {
              variableName,
              sweepType,
              parameters,
              id,
              formula,
              is_dependent,
              is_general_parameter
            },
            i
          ) => ({
            id,
            parameters: sweepType === "Family" ? "Meta cells" : parameters,
            formula,
            is_dependent,
            is_general_parameter,
            name: variableName,
            types: sweepType,
            explanation: sweepData[sweepType].explanation,
            tableData: { id: i }
          })
        )
    });
  };

  getAliasList = () => {
    const { data } = this.state;
    return data
      .filter(({ tableData }) => !tableData.editing)
      .map(({ name }) => name.toLowerCase());
  };

  getSweptVariablesAndSimulationId = () => {
    const { sweptVariables, simulationId } = this.props;
    return {
      sweptVariables: sweptVariables,
      simulationId: simulationId
    };
  };

  onRowAdd = newData =>
    new Promise((resolve, reject) => {
      const { addSweptVariable, simulationId } = this.props;
      validateVariableData(
        newData,
        this.getAliasList(),
        this.getSweptVariablesAndSimulationId()
      )
        .then(validatedData => {
          const {
            name,
            types,
            parameters,
            formula,
            is_general_parameter
          } = validatedData;
          addSweptVariable({
            parameters,
            formula,
            variableName: name,
            sweepType: types,
            simulation: simulationId,
            is_general_parameter: is_general_parameter
          })
            .then(() => {
              this.props.onEditingFinished &&
                this.props.onEditingFinished(newData.name);
              return resolve();
            })
            .catch(err => {
              reject();
              this.setState({
                error: {
                  [err.path
                    ? err.path
                    : err.response
                    ? err.response.data.field
                    : "parameters"]: err.response
                    ? err.response.data.message
                    : err.message
                    ? err.message
                    : err
                }
              });
            });
        })
        .catch(err => {
          reject();
          this.setState({
            error: {
              [err.path
                ? err.path
                : err.response
                ? err.response.data.field
                : "parameters"]: err.response
                ? err.response.message
                : err.message
                ? err.message
                : err
            }
          });
        });
    });

  onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      const { updateSweptVariable, fetchAllData } = this.props;
      validateVariableData(
        newData,
        this.getAliasList(),
        this.getSweptVariablesAndSimulationId()
      )
        .then(validatedData => {
          const {
            id,
            name,
            types,
            parameters,
            formula,
            is_general_parameter
          } = validatedData;
          updateSweptVariable(id, {
            parameters,
            formula,
            variableName: name,
            sweepType: types,
            is_general_parameter: is_general_parameter
          })
            .then(async () => {
              await fetchAllData();
              resolve();
            })
            .catch(err => {
              reject();
              this.setState({
                error: {
                  [err.path
                    ? err.path
                    : err.response
                    ? err.response.data.field
                    : "parameters"]: err.response
                    ? err.response.data.message
                    : err.message
                    ? err.message
                    : err
                }
              });
            });
        })
        .catch(err => {
          reject();
          this.setState({
            error: {
              [err.path
                ? err.path
                : err.response
                ? err.response.field
                : "parameters"]: err.response
                ? err.response.message
                : err.message
                ? err.message
                : err
            }
          });
        });
    });

  onRowDelete = oldData =>
    new Promise((resolve, reject) => {
      const { removeSweptVariable, fetchAllData } = this.props,
        { id } = oldData;
      removeSweptVariable(id)
        .then(() => fetchAllData())
        .then(() => resolve())
        .catch(() => reject());
    });

  render = () => {
    const { data } = this.state,
      { classes, restrictFormula, hideExistingSweeps } = this.props;
    if (!data) {
      return null;
    }
    return (
      <div className={classes.main}>
        <EnhancedMaterialTable
          onEditingCancelled={this.props.onEditingFinished}
          setTableRef={this.props.setTableRef}
          slim
          options={{
            search: false,
            paging: false,
            sorting: false,
            draggable: false,
            maxBodyHeight: 350,
            toolbar: !this.props.hideToolbar,
            actionsColumnIndex: -1
          }}
          title="Sweep Variables"
          tooltip={
            <>
              <p>
                Define parameter variations in simulations. The variables
                created in this table can be assigned to a parameter of the
                simulation by typing “=Alias” in the parameter field instead of
                a single numeric value.
              </p>
              <p>
                It is also possible to combine the variable assignment with a
                math expression.
              </p>
              <a
                test-data={"showExamples"}
                href="https://www.w3schools.com"
                style={{ color: "#FFF", textDecoration: "underline" }}
                onClick={e => {
                  e.preventDefault();
                  this.setState({ examplesDialogOpen: true });
                }}
              >
                Show examples
              </a>
            </>
          }
          columns={columns(
            this.ref,
            this.state.error,
            state => this.setState(state),
            classes.inputTooltip,
            restrictFormula
          )}
          data={!hideExistingSweeps ? data : []}
          editable={{
            isEditable: rowData => rowData.types !== "Family",
            isDeletable: rowData => rowData.types !== "Family",
            onRowAdd: this.onRowAdd,
            onRowUpdate: this.onRowUpdate,
            onRowDelete: this.onRowDelete
          }}
          components={components(this.state.error)}
          localization={{
            body: {
              editRow: {
                deleteText:
                  "Deleting this variable will impact on all places it is used. Are you sure?"
              },
              emptyDataSourceMessage: this.props.hideEmptyMessage
                ? null
                : "No records to display"
            }
          }}
        />
        <VariableAssignmentExamplesDialog
          open={this.state.examplesDialogOpen}
          onClose={() => this.setState({ examplesDialogOpen: false })}
        />
      </div>
    );
  };
}

export default withErrorBoundary(withStyles(styles)(SweepOverview));
