import React, { Component } from "react";
import { withStyles, Typography, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import StructureSelector from "MetaCell/selectors/Structure";
import ContainerDimensions from "react-container-dimensions";
import SaveIcon from "@material-ui/icons/Save";
import Axios from "axios";
import TopView from "../TopView";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import GenericApi from "Api";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10
  }
};

export class Preview2D extends Component {
  export = () => {
    const {
        selectedFamilyMemberId,
        selectedLayerId,
        simulationOpenId
      } = this.props,
      url = `${GenericApi.getBaseUrl()}/layers/${selectedLayerId}/export_view`;
    GenericApi.runApiCall(
      Axios({
        url,
        method: "GET",
        responseType: "blob",
        params: {
          family_member_id: selectedFamilyMemberId,
          simulation_id: simulationOpenId
        }
      }).then(res => res.data),
      "Failed to export layer image."
    )
      .then(data => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `layer-${selectedLayerId}.png`);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error));
  };

  render() {
    const {
      classes,
      selectedLayerId,
      selectedUsedMaterialId,
      isFamilySimulation
    } = this.props;
    return (
      <div className={classes.main} test-data="preview2D">
        {selectedLayerId && selectedLayerId !== -1 && (
          <ContainerDimensions>
            {({ width, height }) => (
              <>
                <div className={classes.title}>
                  <Typography>Top view</Typography>
                  <IconButton aria-label="download" onClick={this.export}>
                    <SaveIcon />
                  </IconButton>
                </div>
                <TopView
                  width={width}
                  height={height}
                  showAxis={true}
                  selectedLayerId={selectedLayerId}
                  selectedUsedMaterialId={selectedUsedMaterialId}
                  isFamilySimulation={isFamilySimulation}
                />
              </>
            )}
          </ContainerDimensions>
        )}
      </div>
    );
  }
}

const mapState = state => ({
  selectedLayerId: StructureSelector.getSelectedLayerId(state),
  selectedUsedMaterialId: StructureSelector.getSelectedUsedMaterialId(state),
  selectedFamilyMemberId: StructureSelector.getSelectedFamilyMemberId(state),
  simulationOpenId: DirectoryExplorerSelector.getSimulationOpenId(state)
});

export default connect(
  mapState,
  null
)(withErrorBoundary(withStyles(styles)(Preview2D)));
