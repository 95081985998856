import React, { PureComponent } from "react";
import GenericHeatmapImage from "components/Heatmap/HeatmapImage";
import DrilldownInput from "components/DrilldownInput";

export const wavefrontTypes = Object.freeze({
  AMPLITUDE: "Amplitude",
  PHASE: "Phase"
});

export class GenericImageWavefront extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedWavefrontType: wavefrontTypes.AMPLITUDE
    };
  }

  getWavefrontImage() {
    const { wavefrontImages } = this.props;
    const { selectedWavefrontType } = this.state;
    const optionIndex = Object.values(wavefrontTypes).indexOf(
      selectedWavefrontType
    );
    return wavefrontImages[optionIndex];
  }

  getZLabel() {
    if (this.props.colorBarUnit) {
      return this.props.colorBarUnit;
    }
    if (this.props.hidePhase) {
      return "W/m2";
    }
    return this.state.selectedWavefrontType === wavefrontTypes.AMPLITUDE
      ? "V/m"
      : "rad";
  }

  onSelectDrilldownOption = optionIndex => {
    const { onWaveFrontSelect } = this.props;
    this.setState({
      selectedWavefrontType: Object.values(wavefrontTypes)[optionIndex]
    });
    if (onWaveFrontSelect) {
      onWaveFrontSelect(Object.values(wavefrontTypes)[optionIndex]);
    }
  };

  getDrilldownOptions() {
    return [
      Object.values(wavefrontTypes).map(wfType => ({
        text: wfType,
        isSelected: false
      }))
    ];
  }

  render() {
    const {
      wavefrontImages,
      hidePhase,
      rowsCount,
      columnsCount,
      crossSectionOrientation,
      crossSectionIndex,
      handleCrossSection,
      hideHeatMapCrossSectionOrientationLabel,
      xLabel,
      yLabel,
      wavefront_shape
    } = this.props;
    const { selectedWavefrontType } = this.state;
    return (
      <>
        <div style={{ width: "100%", display: "flex" }}>
          {wavefrontImages && !hidePhase && (
            <DrilldownInput
              float={"left"}
              value={this.state.selectedWavefrontType}
              options={this.getDrilldownOptions()}
              onSelect={this.onSelectDrilldownOption}
              showCount={false}
            />
          )}
        </div>

        {this.props.wfHeight && this.props.wfWidth && wavefrontImages && (
          <div style={{ width: "100%" }}>
            <GenericHeatmapImage
              title={this.props.title}
              width={400}
              height={400}
              wavefrontImage={this.getWavefrontImage()}
              xLabel={
                xLabel
                  ? xLabel
                  : this.props.unit && `width (${this.props.unit})`
              }
              yLabel={
                yLabel
                  ? yLabel
                  : this.props.unit && `length (${this.props.unit})`
              }
              valueUnit={this.getZLabel()}
              showLegend={this.props.showLegend}
              enableCrossSection={this.props.enableCrossSection}
              crossSectionRowsCount={rowsCount ? rowsCount : wavefront_shape[0]}
              crossSectionColumnsCount={
                columnsCount ? columnsCount : wavefront_shape[1]
              }
              crossSectionOrientation={crossSectionOrientation}
              crossSection3DUnit={this.props.unit}
              crossSectionColorbarUnit={
                selectedWavefrontType === "Phase"
                  ? "Phase(rad)"
                  : selectedWavefrontType === "Amplitude"
                  ? "Amp(V/m)"
                  : "I(W/m2)"
              }
              crossSectionIndex={crossSectionIndex}
              handleCrossSection={handleCrossSection}
              hideHeatMapCrossSectionOrientationLabel={
                hideHeatMapCrossSectionOrientationLabel
              }
              zmin={this.props.zmin}
              zmax={this.props.zmax}
              sweptVariableValues={this.props.sweptVariableValues}
            />
          </div>
        )}
      </>
    );
  }
}

export default GenericImageWavefront;
