import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import DimensionsForm from "./components/DimensionsForm/DimensionsForm";
import Grid from "@material-ui/core/Grid";
import EditActions from "components/EditActions/EditActions";
import IconTooltip from "components/IconTooltip/IconTooltip";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 30
  },
  title: {
    float: "left"
  }
});

/**
 * A class component that has a form to change dimensions. See {@link DimensionsForm}
 * @typedef {Component} Dimensions
 * @author Ibtihel
 */
export class Dimensions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }

  /**
   * everytime it is no longer editing it means that the saving operation is finished.
   */
  componentDidUpdate() {
    const { editingDimensions } = this.props;
    if (this.state.saving && editingDimensions === null) {
      this.setState({ saving: false });
    }
  }

  /**
   * It is supposed to be passed to the form to handle field change.
   * @callback
   * @param {Object} newParameters - the parameter object
   */
  updateField = newParameters => {
    const { updateDimensions } = this.props;
    updateDimensions(newParameters);
  };

  /**
   * it calls the parent save handler and change the saving state
   * @callback
   */
  handleSave = () => {
    const { width, height, unit } = this.props.editingDimensions;
    if (width && height && unit) {
      this.setState({ saving: true });
      this.props.onSave();
    }
  };

  /**
   * it saves if the enter key was listened to
   * @param {Object} event - the keydown event
   * @callback
   */
  handleKeyDown = event => {
    if (event.key === "Enter") this.handleSave();
  };

  render() {
    const {
        classes,
        title,
        tooltip,
        editingDimensions,
        dimensions,
        editDimensions,
        cancelDimensionsEditing,
        unitOptions,
        unitTooltip,
        isSweep,
        isFamilySimulation
      } = this.props,
      dimensionsAreLoaded = dimensions !== null,
      saving =
        this.props.saving !== undefined ? this.props.saving : this.state.saving;
    return dimensionsAreLoaded ? (
      <Paper className={classes.root} onKeyDown={this.handleKeyDown}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div test-data="titleWrapper">
              <Typography className={classes.title} variant="h5" component="h3">
                {title}
              </Typography>
              <IconTooltip text={tooltip} />
            </div>
            {!isFamilySimulation && (
              <div style={{ float: "right" }}>
                <EditActions
                  isEditing={editingDimensions !== null}
                  isSaving={saving}
                  onEdit={() => editDimensions()}
                  onCancel={() => cancelDimensionsEditing()}
                  onSave={this.handleSave}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <DimensionsForm
              isSweep={isSweep}
              dimensions={editingDimensions ? editingDimensions : dimensions}
              updateFieldCallback={this.updateField}
              isEditing={editingDimensions !== null && !saving}
              unitOptions={unitOptions}
              unitTooltip={unitTooltip}
              handleSave={this.handleSave}
            />
          </Grid>
        </Grid>
      </Paper>
    ) : null;
  }
}

export default withStyles(styles)(Dimensions);
