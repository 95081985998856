import React, { PureComponent } from "react";
import {
  withStyles,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  FormLabel,
  Typography,
  Checkbox
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SweepInput, { disablingType } from "components/SweepInput/SweepInput";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import IconTooltip from "components/IconTooltip/IconTooltip";
import center_coordinates from "assets/center_coordinates.png";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import HelperUtils from "MetaCell/helper/HelperUtils";

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
});

const requiredFieldText = "required";

const settingsFormSchema = Yup.object().shape({
  width: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  height: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  rows_count: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  columns_count: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  X: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  Y: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  Z: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  alpha: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  beta: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  gama: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  angular_center_angle_x: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  angular_center_angle_y: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  opening_angle_x: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  }),
  opening_angle_y: Yup.string().when("selected_wave_front", {
    is: "NFWF",
    then: schema => schema,
    otherwise: Yup.string().required(requiredFieldText)
  })
});

const wavefrontOptions = Object.freeze({
  "Near-field": "NFWF",
  "Far-field": "FFWF",
  Fourier: "FOURIER",
  All: "ALL"
});

/**
 * a component to allow the analysis settings to be edited
 * @author Akira Kotsugai
 */
export class AnalysisSettings extends PureComponent {
  /**
   * if there is an existing analysis settings, the initial
   * form values will consist of it,
   * otherwise, it will be empty.
   * @returns {Object} the form values
   */
  getFormInitialValues() {
    const { settings, defaultValues } = this.props,
      settingsInexists = settings === null;
    if (settingsInexists) {
      return defaultValues;
    }
    const {
        selected_wave_front,
        width,
        height,
        rows_count,
        columns_count,
        center_coord,
        euler_angles,
        ff_target_unit,
        opening_angles,
        angular_center_angles,
        angular_unit,
        ff_prop_type,
        ff_zero_padding,
        ff_bandlimit
      } = settings,
      X = center_coord[0],
      Y = center_coord[1],
      Z = center_coord[2],
      opening_angle_x = opening_angles[0],
      opening_angle_y = opening_angles[1],
      angular_center_angle_x = angular_center_angles[0],
      angular_center_angle_y = angular_center_angles[1],
      alpha = euler_angles[0],
      beta = euler_angles[1],
      gama = euler_angles[2];
    return {
      selected_wave_front,
      width,
      height,
      rows_count,
      columns_count,
      X,
      Y,
      Z,
      alpha,
      beta,
      gama,
      ff_target_unit,
      angular_unit,
      opening_angle_x,
      opening_angle_y,
      angular_center_angle_x,
      angular_center_angle_y,
      ff_prop_type,
      ff_zero_padding,
      ff_bandlimit
    };
  }

  getPreparedCenterCoords = (values, defaultValues, selected_wave_front) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FFWF") {
      return values;
    } else {
      return [defaultValues.X, defaultValues.Y, defaultValues.Z];
    }
  };

  getPreparedFFWFUnit = (value, defaultValues, selected_wave_front) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FFWF") {
      return value;
    } else {
      return defaultValues.ff_target_unit;
    }
  };

  getPreparedAngularCenterAngles = (
    values,
    defaultValues,
    selected_wave_front
  ) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FOURIER") {
      return values;
    } else {
      return [
        defaultValues.angular_center_angle_x,
        defaultValues.angular_center_angle_y
      ];
    }
  };

  getPreparedOpeningAngles = (values, defaultValues, selected_wave_front) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FOURIER") {
      return values;
    } else {
      return [defaultValues.opening_angle_x, defaultValues.opening_angle_y];
    }
  };

  getPreparedAngularUnit = (value, defaultValues, selected_wave_front) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FOURIER") {
      return value;
    } else {
      return defaultValues.angular_unit;
    }
  };

  getPreparedWidth = (value, defaultValues, selected_wave_front) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FFWF") {
      return value;
    } else {
      return defaultValues.width;
    }
  };

  getPreparedHeight = (value, defaultValues, selected_wave_front) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FFWF") {
      return value;
    } else {
      return defaultValues.height;
    }
  };

  getPreparedRowsCount = (value, defaultValues, selected_wave_front) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FFWF") {
      return value;
    } else {
      return defaultValues.rows_count;
    }
  };

  getPreparedColumnsCount = (value, defaultValues, selected_wave_front) => {
    if (selected_wave_front === "ALL" || selected_wave_front === "FFWF") {
      return value;
    } else {
      return defaultValues.columns_count;
    }
  };

  /**
   * before calling the parent submitter, it prepares the data that matches the endpoint's
   * expected format
   * @param {Object} values - the form values to submit
   */
  handleSubmit = values => {
    const initialValues = this.props.defaultValues;
    const {
      selected_wave_front,
      width,
      height,
      rows_count,
      columns_count,
      X,
      Y,
      Z,
      ff_target_unit,
      angular_center_angle_x,
      angular_center_angle_y,
      opening_angle_x,
      opening_angle_y,
      angular_unit,
      ff_prop_type,
      ff_zero_padding,
      ff_bandlimit
    } = values;
    const preparedValues = {
      selected_wave_front,
      width: this.getPreparedWidth(width, initialValues, selected_wave_front),
      height: this.getPreparedHeight(
        height,
        initialValues,
        selected_wave_front
      ),
      rows_count: this.getPreparedRowsCount(
        rows_count,
        initialValues,
        selected_wave_front
      ),
      columns_count: this.getPreparedColumnsCount(
        columns_count,
        initialValues,
        selected_wave_front
      ),
      center_coord: this.getPreparedCenterCoords(
        ["" + X, "" + Y, "" + Z],
        initialValues,
        selected_wave_front
      ), // endpoint expects array of strings
      euler_angles: ["0", "0", "0"], // zeros are submitted until euler angles are supported
      ff_target_unit: this.getPreparedFFWFUnit(
        ff_target_unit,
        initialValues,
        selected_wave_front
      ),
      angular_center_angles: this.getPreparedAngularCenterAngles(
        ["" + angular_center_angle_x, "" + angular_center_angle_y],
        initialValues,
        selected_wave_front
      ),
      opening_angles: this.getPreparedOpeningAngles(
        ["" + opening_angle_x, "" + opening_angle_y],
        initialValues,
        selected_wave_front
      ),
      angular_unit: this.getPreparedAngularUnit(
        angular_unit,
        initialValues,
        selected_wave_front
      ),
      ff_prop_type,
      ff_zero_padding,
      ff_bandlimit
    };
    this.props.onSubmit(preparedValues);
  };

  /**
   * it updates the far field dimensions and distances when the unit changes
   * @param {Object} formValues - all form values
   * @param {String} newUnit - the new unit
   * @param {Function} fieldValueSetter - the formik function that sets values in the form
   */
  changeUnit = (formValues, newUnit, fieldValueSetter) => {
    fieldValueSetter("ff_target_unit", newUnit);
    const formFieldsToBeScaled = ["width", "height", "X", "Y", "Z"];
    formFieldsToBeScaled.forEach(fieldToBeScaled => {
      const fieldValue = formValues[fieldToBeScaled];
      const fieldValueIsNumber = !isNaN(fieldValue);
      if (fieldValueIsNumber) {
        const multiplier = HelperUtils.scaleUnit(
          formValues.ff_target_unit,
          newUnit
        );
        const newValue = parseFloat(fieldValue) * multiplier;
        fieldValueSetter(fieldToBeScaled, newValue);
      }
    });
  };

  changeAngularUnit = (formValues, newUnit, fieldValueSetter) => {
    const formFieldsToBeScaled = [
      "angular_center_angle_x",
      "angular_center_angle_y",
      "opening_angle_x",
      "opening_angle_y"
    ];
    const previousUnit = formValues["angular_unit"];
    if (previousUnit !== newUnit) {
      const scaleFunction =
        newUnit === "radians"
          ? HelperUtils.toRadians
          : newUnit === "degrees"
          ? HelperUtils.toDegrees
          : (value, precision) => value;
      formFieldsToBeScaled.forEach(fieldToBeScaled => {
        const fieldValue = formValues[fieldToBeScaled];
        const fieldValueIsNumber = !isNaN(fieldValue);
        if (fieldValueIsNumber) {
          const newValue = scaleFunction(fieldValue);
          fieldValueSetter(fieldToBeScaled, newValue);
        }
      });
    }
    fieldValueSetter("angular_unit", newUnit);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Formik
          initialValues={this.getFormInitialValues()}
          enableReinitialize
          validationSchema={settingsFormSchema}
          onSubmit={this.handleSubmit}
        >
          {({ values, submitForm, resetForm, setFieldValue }) => {
            // making it possible to call these formik functions from parent components
            this.props.bindSubmitForm(submitForm);
            this.props.bindResetForm(resetForm);
            const ffParametersDisabled =
              values.selected_wave_front === "NFWF" ||
              values.selected_wave_front === "FOURIER";
            const fourierParametersDisabled =
              values.selected_wave_front === "NFWF" ||
              values.selected_wave_front === "FFWF";
            return (
              <Form>
                <Grid container justify={"space-around"} spacing={2}>
                  <Grid item xs={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} style={{ marginLeft: 10 }}>
                        <FormControl style={{ width: "100%" }}>
                          <FormLabel style={{ fontSize: 12 }} htmlFor="Y">
                            Wavefront
                          </FormLabel>
                          <UnselfishSelect
                            style={{ marginTop: 4 }}
                            name="selected_wave_front"
                            value={values.selected_wave_front}
                            onChange={event =>
                              setFieldValue(
                                "selected_wave_front",
                                event.target.value
                              )
                            }
                          >
                            {Object.keys(wavefrontOptions).map(
                              wavefrontType => (
                                <MenuItem
                                  value={wavefrontOptions[wavefrontType]}
                                >
                                  {wavefrontType}
                                </MenuItem>
                              )
                            )}
                          </UnselfishSelect>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={
                      !fourierParametersDisabled && !ffParametersDisabled
                        ? 5
                        : !fourierParametersDisabled && ffParametersDisabled
                        ? 0
                        : 10
                    }
                  >
                    {!ffParametersDisabled && (
                      <Paper className={classes.paper} elevation={3}>
                        <Typography variant="h6" component="h3">
                          {"Far-field Wavefront Parameters"}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={fourierParametersDisabled ? 6 : 12}>
                            <Paper className={classes.paper}>
                              <Grid container spacing={3}>
                                <Grid item xs={4}>
                                  <FormLabel style={{ fontSize: 12 }}>
                                    Width
                                  </FormLabel>
                                  <SweepInput
                                    value={values.width}
                                    onChange={value =>
                                      setFieldValue("width", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      ffParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    htmlFor="columns_count"
                                  >
                                    Res
                                  </FormLabel>
                                  <IconTooltip
                                    text={
                                      "The width resolution (How many columns the wavefront will have)."
                                    }
                                  />
                                  <SweepInput
                                    value={values.columns_count}
                                    onChange={value =>
                                      setFieldValue("columns_count", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      ffParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl style={{ width: "100%" }}>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      htmlFor="ff_target_unit"
                                    >
                                      Unit
                                    </FormLabel>
                                    <UnselfishSelect
                                      disabled={ffParametersDisabled}
                                      style={{ marginTop: 4 }}
                                      name={"ff_target_unit"}
                                      value={values.ff_target_unit}
                                      onChange={event =>
                                        this.changeUnit(
                                          values,
                                          event.target.value,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <MenuItem value="μm">μm</MenuItem>
                                      <MenuItem value="mm">mm</MenuItem>
                                    </UnselfishSelect>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel style={{ fontSize: 12 }}>
                                    Height
                                  </FormLabel>
                                  <SweepInput
                                    value={values.height}
                                    onChange={value =>
                                      setFieldValue("height", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      ffParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    htmlFor="rows_count"
                                  >
                                    Res
                                  </FormLabel>
                                  <IconTooltip
                                    text={
                                      "The height resolution (How many rows the wavefront will have)."
                                    }
                                  />
                                  <SweepInput
                                    value={values.rows_count}
                                    onChange={value =>
                                      setFieldValue("rows_count", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      ffParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                          <Grid item xs={fourierParametersDisabled ? 6 : 12}>
                            <Paper className={classes.paper}>
                              <Grid container spacing={3}>
                                <Grid item xs={4}>
                                  <FormLabel style={{ fontSize: 12 }}>
                                    dX
                                  </FormLabel>
                                  <IconTooltip image={center_coordinates} />
                                  <SweepInput
                                    allowNegative
                                    value={values.X}
                                    onChange={value =>
                                      setFieldValue("X", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      ffParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel style={{ fontSize: 12 }}>
                                    dY
                                  </FormLabel>
                                  <IconTooltip image={center_coordinates} />
                                  <SweepInput
                                    allowNegative
                                    value={values.Y}
                                    onChange={value =>
                                      setFieldValue("Y", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      ffParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel style={{ fontSize: 12 }}>
                                    dZ
                                  </FormLabel>
                                  <IconTooltip image={center_coordinates} />
                                  <SweepInput
                                    value={values.Z}
                                    onChange={value =>
                                      setFieldValue("Z", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      ffParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Checkbox
                                    onClick={value =>
                                      setFieldValue(
                                        "ff_zero_padding",
                                        !values.ff_zero_padding
                                      )
                                    }
                                    checked={values.ff_zero_padding}
                                    color="primary"
                                  />
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    htmlFor="ff_zero_padding"
                                  >
                                    Zero padding
                                  </FormLabel>
                                  <IconTooltip
                                    text={
                                      "The component is extended with opaque area to increase the far field resolution. It is suggested to only switch this off for very large components that fail otherwise."
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Checkbox
                                    onClick={value =>
                                      setFieldValue(
                                        "ff_bandlimit",
                                        !values.ff_bandlimit
                                      )
                                    }
                                    checked={values.ff_bandlimit}
                                    color="primary"
                                  />
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    htmlFor="ff_bandlimit"
                                  >
                                    Bandlimit
                                  </FormLabel>
                                  <IconTooltip
                                    text={
                                      "Angular spectral band clipping to avoid aliasing due to the periodic boundary within the fft calculation."
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl style={{ width: "100%" }}>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      htmlFor="ff_prop_type"
                                    >
                                      Type
                                      <IconTooltip
                                        text={
                                          "Type of propagation: \
                                        - Angular spectrum method (ASM) \
                                        - Fraunhofer propagation, advised when W^2 / (wl * dZ) (with W = component width) \
                                        - Fresnel propagation, advised when a^4 / (4 dZ^3 wl)<<1 (with a = component width / 2)"
                                        }
                                      />
                                    </FormLabel>
                                    <UnselfishSelect
                                      disabled={ffParametersDisabled}
                                      style={{ marginTop: 4 }}
                                      name={"ff_prop_type"}
                                      value={values.ff_prop_type}
                                      onChange={event =>
                                        setFieldValue(
                                          "ff_prop_type",
                                          event.target.value
                                        )
                                      }
                                    >
                                      <MenuItem value="ASM">ASM</MenuItem>
                                      <MenuItem value="fraunhofer">
                                        Fraunhofer
                                      </MenuItem>
                                      <MenuItem value="fresnel">
                                        Fresnel
                                      </MenuItem>
                                    </UnselfishSelect>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Paper>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={
                      !fourierParametersDisabled && !ffParametersDisabled
                        ? 5
                        : 9
                    }
                  >
                    {!fourierParametersDisabled && (
                      <Paper className={classes.paper} elevation={3}>
                        <Typography variant="h6" component="h3">
                          {"Fourier Wavefront Parameters"}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Paper className={classes.paper}>
                              <Grid container spacing={3}>
                                <Grid item xs={4}>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    htmlFor="opening_angle_x"
                                  >
                                    Opening Angle X
                                  </FormLabel>
                                  <SweepInput
                                    value={values.opening_angle_x}
                                    onChange={value =>
                                      setFieldValue("opening_angle_x", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      fourierParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    htmlFor="opening_angle_y"
                                  >
                                    Opening Angle Y
                                  </FormLabel>
                                  <SweepInput
                                    value={values.opening_angle_y}
                                    onChange={value =>
                                      setFieldValue("opening_angle_y", value)
                                    }
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      fourierParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl style={{ width: "100%" }}>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      htmlFor="angular_unit"
                                    >
                                      Angular Unit
                                    </FormLabel>
                                    <UnselfishSelect
                                      disabled={fourierParametersDisabled}
                                      style={{ marginTop: 4 }}
                                      name={"angular_unit"}
                                      value={values.angular_unit}
                                      onChange={event =>
                                        this.changeAngularUnit(
                                          values,
                                          event.target.value,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <MenuItem value="radians">
                                        Radians
                                      </MenuItem>
                                      <MenuItem value="degrees">
                                        Degrees
                                      </MenuItem>
                                    </UnselfishSelect>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    htmlFor="angular_center_angle_x"
                                  >
                                    Center Angle X
                                  </FormLabel>
                                  <SweepInput
                                    value={values.angular_center_angle_x}
                                    onChange={value =>
                                      setFieldValue(
                                        "angular_center_angle_x",
                                        value
                                      )
                                    }
                                    allowNegative
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      fourierParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    htmlFor="angular_center_angle_y"
                                  >
                                    Center Angle Y
                                  </FormLabel>
                                  <SweepInput
                                    value={values.angular_center_angle_y}
                                    onChange={value =>
                                      setFieldValue(
                                        "angular_center_angle_y",
                                        value
                                      )
                                    }
                                    allowNegative
                                    simulationId={-1}
                                    sweptVariables={this.props.sweptVariables}
                                    disabled={
                                      fourierParametersDisabled &&
                                      disablingType.DISABLE_INPUT
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(AnalysisSettings));
