import React, { Component } from "react";
import { connect } from "react-redux";
import StructureSelector from "MetaCell/selectors/Structure";
import MaterialSelector from "MetaCell/selectors/Material";
import SimulationSettingsSelector from "MetaCell/selectors/SimulationSettings";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import SideView from "MetaCell/containers/StructureCanvas/components/SideView";
import StructureHelper from "MetaCell/helper/Structure";

export class OpenSimulationSideView extends Component {
  /**
   * @returns {Object[]} all layers sorted by index
   */
  getSortedLayers() {
    const {
      isFamilySimulation,
      familyStructures,
      selectedFamilyMemberId
    } = this.props;
    if (
      isFamilySimulation &&
      familyStructures !== undefined &&
      selectedFamilyMemberId !== undefined
    ) {
      return familyStructures[selectedFamilyMemberId];
    }
    return StructureHelper.getExpandedStaircaseLayers(this.props.layers);
  }

  getSweptVariables() {
    const { sweptVariables } = this.props;
    if (sweptVariables) {
      return this.props.sweptVariables.filter(
        ({ simulation }) => simulation === this.props.simulationId
      );
    }
    return [];
  }

  getMaterialColor = usedMaterialId => {
    const { materials, usedMaterials } = this.props;
    try {
      const materialId = usedMaterials.byId[usedMaterialId].material;
      return materials.byId[materialId].color;
    } catch {
      return "transparent";
    }
  };

  getLayerColors = layerIndex => {
    const layer = this.getSortedLayers()[layerIndex];
    return layer.usedmaterial_set.map(usedMaterialId =>
      this.getMaterialColor(usedMaterialId)
    );
  };

  render() {
    const {
      probePosition,
      selectedLayerId,
      showDownloadButton,
      title,
      familyDiscretizedData,
      isFamilySimulation
    } = this.props;
    return (
      <SideView
        sweptVariables={this.getSweptVariables()}
        sortedLayers={this.getSortedLayers()}
        probePosition={probePosition}
        title={title}
        selectedLayerId={selectedLayerId}
        getLayerColors={this.getLayerColors}
        showDownloadButton={showDownloadButton}
        familyDiscretizedData={familyDiscretizedData}
        isFamilySimulation={isFamilySimulation}
      />
    );
  }
}

const mapState = state => ({
  usedMaterials: StructureSelector.getUsedMaterials(state),
  layers: StructureSelector.getLayers(state),
  materials: MaterialSelector.getMaterials(state),
  sweptVariables: SimulationSettingsSelector.getSweptVariables(state),
  simulationId: DirectoryExplorerSelector.getSimulationOpenId(state),
  familyStructures: StructureSelector.getFamilyStructures(state),
  selectedFamilyMemberId: StructureSelector.getSelectedFamilyMemberId(state),
  familyDiscretizedData: StructureSelector.getfamilyDiscretizedData(state)
});

export default connect(
  mapState,
  null
)(withErrorBoundary(OpenSimulationSideView));
