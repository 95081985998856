import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { FormControl, MenuItem } from "@material-ui/core";
import isEqual from "lodash/isEqual";

import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import AnalysisSelector from "MetaComponent/selectors/Analysis";
import GenericHeatmap from "components/Heatmap/Heatmap";
import LinePlot from "components/LinePlot/LinePlot";

/**
 * the cross section view of the heatmap
 */

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
};

export class HeatmapCrossSection extends PureComponent {
  state = {
    orientation: null,
    index: null,
    plot_data: null
  };

  // returns the middle index of row/col count for cross section
  getMiddleIndex(orientation) {
    if (orientation == "xz") {
      return Math.floor(this.props.columnsCount / 2);
    } else {
      return Math.floor(this.props.rowsCount / 2);
    }
  }

  async componentDidMount() {
    this.setState({ plot_data: null });
    const {
      crossSectionOrientation,
      crossSectionIndex,
      handleCrossSection
    } = this.props;
    var plot_data = null;
    if (crossSectionIndex == null) {
      let middleIndex = this.getMiddleIndex(crossSectionOrientation);
      plot_data = await handleCrossSection(
        crossSectionOrientation,
        middleIndex - 1
      );
      this.setState({
        plot_data,
        orientation: crossSectionOrientation,
        index: middleIndex
      });
    } else {
      plot_data = await handleCrossSection(
        crossSectionOrientation,
        crossSectionIndex
      );
      this.setState({
        plot_data,
        orientation: crossSectionOrientation,
        index: crossSectionIndex
      });
    }
  }

  areFloatArraysEqual = (arr1, arr2, epsilon = Number.EPSILON) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (Math.abs(arr1[i] - arr2[i]) > epsilon) {
        return false;
      }
    }

    return true;
  };

  async componentDidUpdate(prevProps, prevState) {
    const {
      selectedAnalysisJobId,
      sweptVariableValues,
      xData,
      yData
    } = this.props;
    const prev_xData = prevProps.xData;
    const prev_yData = prevProps.yData;
    const { orientation, index } = this.state;
    var newIndex = index;
    if (!yData || !xData) {
      await this.changeIndex(0);
      return;
    }
    if (orientation == "xz" && !this.areFloatArraysEqual(prev_yData, yData)) {
      this.setState({ plot_data: null });
      if (index >= yData.length) {
        newIndex = yData.length;
      }
      await this.changeIndex(newIndex);
    } else if (
      orientation == "yz" &&
      !this.areFloatArraysEqual(prev_xData, xData)
    ) {
      this.setState({ plot_data: null });
      if (index >= xData.length) {
        newIndex = xData.length;
      }
      await this.changeIndex(newIndex);
    }
    if (
      !isEqual(prevProps.sweptVariableValues, sweptVariableValues) &&
      prevProps.selectedAnalysisJobId === selectedAnalysisJobId
    ) {
      await this.updateCrossSectionPlot();
    }
  }

  changeOrientation = e => {
    this.setState({ plot_data: null });
    let orientation = e.target.value;
    let index = this.getMiddleIndex(orientation);
    this.setState({ orientation, index }, this.updateCrossSectionPlot);
  };

  changeIndex = async value => {
    const { orientation } = this.state;
    let index = value;
    if (index <= 0) return;
    if (orientation == "xz") {
      if (index <= this.props.columnsCount) {
        this.setState({ index }, this.updateCrossSectionPlot);
      } else {
        this.setState(
          { index: this.props.yData?.length },
          this.updateCrossSectionPlot
        );
      }
    } else {
      if (index <= this.props.rowsCount) {
        this.setState({ index }, this.updateCrossSectionPlot);
      } else {
        this.setState(
          { index: this.props.xData?.length },
          this.updateCrossSectionPlot
        );
      }
    }
  };

  updateCrossSectionPlot = async () => {
    this.setState({ plot_data: null });
    const { orientation, index } = this.state;
    let plot_data = await this.props.handleCrossSection(orientation, index - 1);
    this.setState({ plot_data });
  };

  getCrossSectionPlot = () => {
    const {
      width,
      height,
      hideHeatMapCrossSectionOrientationLabel
    } = this.props;
    const { plot_data, orientation } = this.state;
    let title = `Cross section in ${orientation}-plane`;
    let xLabel = `${
      hideHeatMapCrossSectionOrientationLabel ? "" : orientation[0] + "-"
    } ${(orientation == "xz" ? this.props.xLabel : this.props.yLabel) || ""}`;
    let yLabel = "";

    if (plot_data?.plot_type == "2D") {
      yLabel = this.props.crossSectionColorbarUnit || "I(W/m2)";
      return (
        <LinePlot
          x={orientation == "xz" ? plot_data.x_range : plot_data.y_range}
          y={
            orientation == "xz"
              ? plot_data.ordered_rows[0]
              : plot_data.ordered_cols[0]
          }
          xLabel={xLabel}
          yLabel={yLabel}
          width={width}
          height={height}
          title={title}
        />
      );
    } else {
      yLabel = this.props.crossSection3DUnit
        ? `z (${this.props.crossSection3DUnit})`
        : "z";
      return (
        plot_data && (
          <GenericHeatmap
            title={title}
            width={width}
            height={height}
            x={orientation == "xz" ? plot_data.x_range : plot_data.y_range}
            y={plot_data.ordered_zs}
            z={
              orientation == "xz"
                ? plot_data.ordered_rows
                : plot_data.ordered_cols
            }
            xLabel={xLabel}
            yLabel={yLabel}
            valueUnit={this.props.crossSectionColorbarUnit || "I(W/m2)"}
            showLegend
            // showLegend={this.props.showLegend}
            disableSideView
          />
        )
      );
    }
  };

  render() {
    const { orientation, index, plot_data } = this.state;
    const {
      xLabel,
      yLabel,
      rowsCount,
      columnsCount,
      xData,
      yData
    } = this.props;
    let orientationOptions = [
      {
        title: xLabel ? xLabel : "Row",
        value: "xz"
      },
      {
        title: yLabel ? yLabel : "Column",
        value: "yz"
      }
    ];
    return (
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="row"
        alignContent="center"
      >
        <Grid item xs={9}>
          <FormControl
            style={{ width: "100%", marginBottom: "5px", marginTop: "10px" }}
          >
            <InputLabel htmlFor="orientation-select">Orientation</InputLabel>
            <Select
              value={orientation || " "}
              onChange={this.changeOrientation}
              inputProps={{
                name: "orientation-select",
                id: "orientation-select"
              }}
              test-data="orientation-select"
            >
              {orientationOptions.map(o => (
                <MenuItem key={o.value} value={o.value}>
                  <span>{o.title}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <IconButton aria-label="slider-down">
                <ArrowLeftIcon
                  test-data="slider-left-button"
                  onClick={() => this.changeIndex(index - 1)}
                />
              </IconButton>
            </Grid>
            <Grid item xs>
              {columnsCount && orientation == "xz" ? (
                <Slider
                  track={false}
                  value={index}
                  name="Index"
                  min={1}
                  max={columnsCount}
                  marks={true}
                  step={1}
                  ValueLabelComponent={props =>
                    yData ? (
                      <ValueLabelComponent
                        {...props}
                        value={`y=${this.props.yData[index - 1]?.toPrecision(
                          4
                        )}`}
                        open={true}
                      />
                    ) : (
                      <ValueLabelComponent
                        {...props}
                        value={`y index=${index}`}
                        open={true}
                      />
                    )
                  }
                  onChangeCommitted={(event, value) => this.changeIndex(value)}
                  valueLabelDisplay="on"
                  test-data="xz-slider"
                  aria-labelledby="input-slider"
                />
              ) : null}
              {rowsCount && orientation == "yz" ? (
                <Slider
                  track={false}
                  value={index}
                  name="Index"
                  min={1}
                  max={rowsCount}
                  marks={true}
                  step={1}
                  test-data="yz-slider"
                  ValueLabelComponent={props =>
                    xData ? (
                      <ValueLabelComponent
                        {...props}
                        value={`x=${this.props.xData[index - 1]?.toPrecision(
                          4
                        )}`}
                        open={true}
                      />
                    ) : (
                      <ValueLabelComponent
                        {...props}
                        value={`x index=${index}`}
                        open={true}
                      />
                    )
                  }
                  onChangeCommitted={(event, value) => this.changeIndex(value)}
                  valueLabelDisplay="on"
                  aria-labelledby="input-slider"
                />
              ) : null}
            </Grid>
            <Grid item>
              <IconButton aria-label="slider-up">
                <ArrowRightIcon
                  test-data="slider-right-button"
                  onClick={() => this.changeIndex(index + 1)}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {plot_data && this.getCrossSectionPlot()}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedAnalysisJobId: AnalysisSelector.getSelectedJobId(state)
  };
};

export default connect(mapStateToProps)(HeatmapCrossSection);
