import React, { Component } from "react";
import { withStyles, Typography, IconButton } from "@material-ui/core";
import arrows from "assets/arrows.png";
import arrow from "assets/arrow.png";
import ContainerDimensions from "react-container-dimensions";
import SideViewItem from "./SideViewItem";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import IconTooltip from "components/IconTooltip/IconTooltip";
import SaveIcon from "@material-ui/icons/Save";
import domtoimage from "dom-to-image";
import HelperUtils from "MetaCell/helper/HelperUtils";

const styles = {
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "20px 10px"
  }
};

export class SideView extends Component {
  /**
   * @param {Object} layer - layer to get the thickness from
   * @returns the real thickness of the layer
   */
  getHeight = layer => {
    const { thickness } = layer || {};
    let height = 0;
    if (thickness) {
      if (thickness[0] === "=") {
        const thicknessSweepVar = thickness.substring(1);
        const simulationVariables = this.props.sweptVariables;
        const sweepVariableNames = simulationVariables.map(
          variable => variable.variableName
        );
        const validVariableName = HelperUtils.extractVariableName(
          thicknessSweepVar,
          sweepVariableNames
        );
        if (validVariableName) {
          const sweep = Object.values(simulationVariables).find(
            variable => variable.variableName === validVariableName
          );
          let validVarValue = undefined;
          if (sweep.sweepType === "Optimization") {
            validVarValue = Number(sweep.parameters.split(";").slice(-1));
          } else {
            try {
              validVarValue = JSON.parse(sweep.values)[0];
            } catch {
              // in getSweptVariables we already parse sweep values,
              // so if this function gets called after, JSON parsing breaks
              validVarValue = sweep.values[0];
            }
          }
          var variableValues = {};
          variableValues[validVariableName] = validVarValue;
          const evaluatedValue = HelperUtils.resolveMathExpression(
            thicknessSweepVar,
            variableValues
          );
          if (evaluatedValue) {
            height = parseFloat(evaluatedValue, 10);
          }
        } else {
          height = 0;
        }
      } else {
        height = parseFloat(thickness, 10);
      }
    }
    return isNaN(height) ? 0 : height;
  };

  /**
   * @param {Object} layerHeight - the current layer height
   * @param {Object} width - the width of the layer
   * @returns {Number} a proportional increased layer height if the width is greater than 100
   */
  scaleLayerHeightBasedOnWidth(layerHeight, width) {
    const noScaleNeededWidth = 100;
    if (width >= noScaleNeededWidth) {
      return (layerHeight * width) / noScaleNeededWidth;
    }
    return layerHeight;
  }

  /**
   * @returns the height of the shortest layer
   */
  getMinHeight() {
    const heights = this.props.sortedLayers
      .map(layer => this.getHeight(layer))
      .filter(val => val > 0);
    return Math.min(...heights);
  }

  /**
   * @param {Number} width - the layer width
   * @param {Object} layer - the layer
   * @returns {Object} the height in pixels of the layer and whether it was resized or not (layers too thick are shrunk)
   */
  getLayerRenderHeight(width, layer) {
    const minRenderHeight = 8;
    const minHeight = this.getMinHeight();
    let isResized = false;
    let height = this.getHeight(layer);
    if (height > minHeight * 15) {
      height = minHeight * 3;
      isResized = true;
    }
    height = (height * minRenderHeight) / minHeight;
    height = this.scaleLayerHeightBasedOnWidth(height, width);
    return {
      height,
      isResized
    };
  }

  /**
   * @param {Number} width - the layer width
   * @param {Object} layer - the layer
   * @param {Number} nrOfLayers - the nr of layers to check whether it is the last layer
   * @returns {Number} the layer render height plus the cosmetics added to the layer
   */
  getStyledLayerRenderHeight(width, layer, nrOfLayers) {
    const { height } = this.getLayerRenderHeight(width, layer);
    // when there is no height there is an extra space of 1px
    let renderHeight = height ? height : 1;
    // every layer has a top border of 1px
    renderHeight += 1;
    // the last layer has a bottom border
    if (layer.index === nrOfLayers) {
      renderHeight += 1;
    }
    return renderHeight;
  }

  /**
   * @param {Number} width - the layers width
   * @param {Object[]} layers - the layers
   * @returns {Number} the sum of the rendered layers' height
   */
  getTotalLayersRenderHeight(width, layers) {
    const heights = layers.map(layer => {
      return this.getStyledLayerRenderHeight(width, layer, layers.length);
    });
    const layersHeight = heights.reduce((a, b) => a + b, 0);
    return layersHeight;
  }

  /**
   * @param {Object[]} layers - the layers
   * @returns {Number} the sum of the real layers' height
   */
  getLayersTotalHeight(layers) {
    const heights = layers.map(layer => this.getHeight(layer));
    const layersHeight = heights.reduce((a, b) => a + b, 0);
    return layersHeight;
  }

  /**
   * @param {Number} probePosition - the position of the probe
   * @param {Object[]} layers - layers to be checked
   * @returns {Object} - the layer where the probe position points to
   */
  getLayerWhereProbeIs(probePosition, layers) {
    let cellThickness = 0;
    for (const layer of layers) {
      cellThickness += this.getHeight(layer);
      if (cellThickness >= probePosition) {
        return layer;
      }
    }
    return null;
  }

  /**
   * @param {Number} layersWidth - the width of the layers
   * @returns {Number} the proportional rendered probe position
   */

  getPixelProbePosition(layersWidth) {
    const allLayers = this.props.sortedLayers;
    const { probePosition } = this.props;
    const layerWhereProbeIs = this.getLayerWhereProbeIs(
      probePosition,
      allLayers
    );
    const layersBefore = allLayers.filter(
      layer => layer.index < layerWhereProbeIs.index
    );
    const realProbePositionAtLayer =
      probePosition - this.getLayersTotalHeight(layersBefore);
    const layerRealHeight = this.getHeight(layerWhereProbeIs);
    const layerRenderHeight = this.getLayerRenderHeight(
      layersWidth,
      layerWhereProbeIs
    );
    const renderProbePositionAtLayer =
      (layerRenderHeight.height * realProbePositionAtLayer) / layerRealHeight;
    return (
      this.getTotalLayersRenderHeight(layersWidth, layersBefore) +
      renderProbePositionAtLayer
    );
  }

  /**
   * @returns {Boolean} - the probe line can be show if the position is numeric and not higher than the whole cell thickness
   */
  shouldShowProbeLine() {
    const { probePosition } = this.props;
    const probeIsNumber = probePosition !== null && !isNaN(probePosition);
    if (probeIsNumber) {
      if (probePosition <= this.getLayersTotalHeight(this.props.sortedLayers)) {
        return true;
      }
    }
    return false;
  }

  /**
   * it downloads an image of the chosen plot
   * @callback
   */
  downloadSideView = () => {
    const element = document.getElementById(`sideViewCanvas`);
    domtoimage
      .toPng(element, { quality: 1, bgcolor: "white" })
      .then(dataUrl => {
        var link = document.createElement("a");
        link.download = `side_view.png`;
        link.href = dataUrl;
        link.click();
      });
  };

  render() {
    const {
      classes,
      selectedLayerId,
      title,
      sortedLayers,
      isFamilySimulation,
      familyDiscretizedData
    } = this.props;
    return (
      <div className={classes.main}>
        {sortedLayers.length > 0 && (
          <ContainerDimensions>
            {({ width }) => (
              <>
                {title && (
                  <div style={{ marginBottom: 30 }} test-data="titleGroup">
                    <Typography style={{ float: "left" }}>{title}</Typography>
                    <IconTooltip text="Cross-section of the layer stack. Arrows indicate: incident, reflected and transmitted beams." />
                    {this.props.showDownloadButton && (
                      <IconButton
                        style={{ "padding-top": "0px" }}
                        aria-label="download"
                        onClick={this.downloadSideView}
                      >
                        <SaveIcon />
                      </IconButton>
                    )}
                  </div>
                )}
                <div id="sideViewCanvas">
                  <img src={arrows} alt="" />
                  <div className={classes.main}>
                    {sortedLayers.map((layer, i) => {
                      const { height, isResized } = this.getLayerRenderHeight(
                        width,
                        layer
                      );
                      return (
                        <SideViewItem
                          key={layer.id}
                          layer={layer}
                          colors={this.props.getLayerColors(i)}
                          width={width}
                          isResized={isResized}
                          height={height}
                          isSelected={
                            !selectedLayerId || selectedLayerId === layer.id
                          }
                          isLast={i === sortedLayers.length - 1}
                          isFamilySimulation={isFamilySimulation}
                          familyDiscretizedData={familyDiscretizedData}
                        />
                      );
                    })}
                    {this.shouldShowProbeLine() && (
                      <svg
                        test-data="probePositionLine"
                        width={width + width / 2}
                        height={this.getTotalLayersRenderHeight(
                          width,
                          sortedLayers
                        )}
                        style={{
                          position: "absolute",
                          zIndex: 200
                        }}
                      >
                        <line
                          x1="0"
                          x2="100%"
                          y1={this.getPixelProbePosition(width)}
                          y2={this.getPixelProbePosition(width)}
                          strokeDasharray="5, 5"
                          stroke="rgb(0, 0, 0)"
                          strokeWidth={2}
                        />
                      </svg>
                    )}
                  </div>
                  <img src={arrow} alt="" />
                </div>
              </>
            )}
          </ContainerDimensions>
        )}
      </div>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(SideView));
