import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@material-ui/core";
import HeatmapSide from "./components/HeatmapSide";
import HeatmapCrossSection from "./components/HeatmapCrossSection";
import HeatmapTop from "./components/HeatmapTop";
import HelperUtils from "MetaCell/helper/HelperUtils";
import CustomizeHeatmapColorscale from "./components/CustomizeHeatmapColorscale";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export default class HeatmapImage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCrossSection: false
    };
  }

  render() {
    const {
      enableCrossSection,
      crossSectionRowsCount,
      crossSectionColumnsCount,
      crossSectionOrientation,
      crossSectionIndex,
      handleCrossSection,
      hideHeatMapCrossSectionOrientationLabel,
      wavefrontImage
    } = this.props;
    const { showCrossSection } = this.state;
    return (
      <>
        <Grid container spacing={0} direction="row" justify="center">
          {enableCrossSection && (
            <Grid item xs={7}>
              <Button
                test-data="toggleCrossSection"
                variant="contained"
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
                onClick={() => {
                  this.setState({ showCrossSection: !showCrossSection });
                  if (this.props.toggleProbeSliderVisibility) {
                    this.props.toggleProbeSliderVisibility();
                  }
                }}
              >
                Cross Section
                {showCrossSection ? (
                  <VisibilityOff style={{ marginLeft: "5px" }} />
                ) : (
                  <Visibility style={{ marginLeft: "10px" }} />
                )}
              </Button>
            </Grid>
          )}
        </Grid>
        <Typography
          variant="subtitle1"
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px"
          }}
        >
          {this.props.title}
        </Typography>
        {showCrossSection ? (
          <HeatmapCrossSection
            rowsCount={crossSectionRowsCount}
            columnsCount={crossSectionColumnsCount}
            handleCrossSection={handleCrossSection}
            crossSectionOrientation={crossSectionOrientation}
            crossSectionIndex={crossSectionIndex}
            width={this.props.width}
            height={this.props.height}
            xLabel={this.props.xLabel}
            yLabel={this.props.yLabel}
            crossSection3DUnit={this.props.crossSection3DUnit}
            crossSectionColorbarUnit={this.props.crossSectionColorbarUnit}
            hideHeatMapCrossSectionOrientationLabel={
              hideHeatMapCrossSectionOrientationLabel
            }
            xData={this.props.x}
            yData={this.props.y}
            sweptVariableValues={this.props.sweptVariableValues}
          />
        ) : (
          <div style={{ marginRight: "10px" }}>
            <img
              src={URL.createObjectURL(wavefrontImage)}
              width="100%"
              test-data="wavefrontImage"
            />
          </div>
        )}
      </>
    );
  }
}
