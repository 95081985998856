import React, { Component } from "react";
import { connect } from "react-redux";
import Selector from "MetaCell/selectors/GlobalParameters";
import Action from "MetaCell/actions/GlobalParameters";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Dimensions from "components/Dimensions/Dimensions";

export const unitOptions = ["μm", "nm", "pm"];

/**
 * A class component to connect to redux state and serve the dimensions form. See {@link DimensionsForm}
 * @typedef {Component} MetaCellGlobalParameters
 * @author Ibtihel
 */
export class MetaCellGlobalParameters extends Component {
  /**
   * it checks which dimension field is being changed and calls the correct redux action
   * @param {Object} newParameters - the parameters that changed
   * @callback
   */
  updateGlobalParameters = newParameters => {
    const { width, height, unit } = newParameters;
    const { updateGlobalParametersAction } = this.props;
    if (width !== undefined) updateGlobalParametersAction({ cellWidth: width });
    else if (height !== undefined)
      updateGlobalParametersAction({ cellHeight: height });
    else if (unit !== undefined) updateGlobalParametersAction({ unit });
  };

  render() {
    const {
        globalParameters,
        editingGlobalParameters,
        isFamilySimulation
      } = this.props,
      globalParameter = Object.values(globalParameters.byId)[0],
      dimensions = globalParameter
        ? {
            width: globalParameter.cellWidth,
            height: globalParameter.cellHeight,
            unit: globalParameter.unit
          }
        : null,
      editingDimensions = editingGlobalParameters
        ? {
            width: editingGlobalParameters.cellWidth,
            height: editingGlobalParameters.cellHeight,
            unit: editingGlobalParameters.unit
          }
        : null;
    return (
      <Dimensions
        title={"Meta cell global parameters"}
        tooltip={"Global width and length setting for all layers"}
        dimensions={dimensions}
        editingDimensions={editingDimensions}
        onSave={this.props.onSave}
        updateDimensions={this.updateGlobalParameters}
        editDimensions={this.props.editGlobalParametersAction}
        cancelDimensionsEditing={this.props.cancelGlobalParametersEditingAction}
        unitOptions={unitOptions}
        unitTooltip={"Global unit for length (including wavelength)"}
        isSweep={true}
        isFamilySimulation={isFamilySimulation}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    globalParameters: Selector.get(state),
    editingGlobalParameters: Selector.getEditing(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateGlobalParametersAction: parameters =>
      dispatch(Action.changeEditingField(parameters)),
    editGlobalParametersAction: () => dispatch(Action.edit()),
    cancelGlobalParametersEditingAction: () => dispatch(Action.cancelEditing())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(MetaCellGlobalParameters));
