import { actionType } from "MetaCell/actions/Structure";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * it updates the id mapping sorted by the layers' indexes
 * @param {Object} layers - layers state
 */
const sortLayersByIndex = layers => {
  let sortedLayersIds = [];
  for (var layer of Object.values(layers.byId)) {
    const layerId = Array.isArray(layer) ? layer[0].id : layer.id;
    const layerIndex = Array.isArray(layer) ? layer[0].index : layer.index;
    sortedLayersIds[layerIndex - 1] = layerId;
  }
  layers.allIds = sortedLayersIds;
};

export const testLayers = {
  byId: {
    1: {
      id: 1,
      index: 1,
      name: "air",
      thickness: 100000,
      usedmaterial_set: [1],
      scale: 1,
      discretized: [
        [0, 1, 1],
        [0, 1, 0],
        [0, 0, 1]
      ],
      structure: null,
      fileName: "image.png",
      parameterList: JSON.stringify({ parameter: {} })
    },
    2: {
      id: 2,
      index: 2,
      name: "Substrate",
      thickness: 800,
      usedmaterial_set: [2],
      scale: 1,
      discretized: [
        [0, 1, 1],
        [0, 1, 0],
        [0, 0, 1]
      ],
      structure: "circle",
      fileName: null,
      parameterList: JSON.stringify({ parameter: {} })
    },
    3: {
      id: 3,
      index: 3,
      name: "air",
      thickness: 50000,
      usedmaterial_set: [3, 4],
      scale: 1,
      discretized: null,
      structure: null,
      fileName: null,
      parameterList: null
    },
    4: {
      id: 4,
      index: 4,
      name: "some layer",
      thickness: 800,
      usedmaterial_set: [5],
      scale: 1,
      discretized: [
        [0, 1, 1],
        [0, 1, 0],
        [0, 0, 1]
      ],
      structure: "circle+rectangle",
      fileName: null,
      parameterList: JSON.stringify([{ parameter: {} }])
    }
  },
  allIds: [1, 2, 3, 4]
};

export const testUsedMaterials = {
  byId: {
    1: {
      id: 1,
      MatId: 1,
      layer: 1,
      material: 1,
      refractiveIndex: 67,
      absorptionCoeff: 67
    },
    2: {
      id: 2,
      MatId: 2,
      layer: 2,
      material: 2,
      refractiveIndex: 2,
      absorptionCoeff: 1
    },
    3: {
      id: 3,
      MatId: 1,
      layer: 3,
      material: 1,
      refractiveIndex: 68,
      absorptionCoeff: 68
    },
    4: {
      id: 4,
      MatId: 2,
      layer: 3,
      material: 2,
      refractiveIndex: "",
      absorptionCoeff: ""
    },
    5: {
      id: 5,
      MatId: 2,
      layer: 4,
      material: 2,
      refractiveIndex: "",
      absorptionCoeff: ""
    }
  },
  allIds: [1, 2, 3, 4]
};

export const testParameterizedStructures = ["rectangle", "circle"];

export const defaultState = {
  entities: {
    layers: {
      byId: {},
      allIds: []
    },
    usedMaterials: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    selectedLayerId: -1,
    selectedUsedMaterialId: -1,
    parameterizedStructures: [],
    familyStructures: [],
    familyAliases: {},
    familyDiscretizedData: {}
  }
};

/**
 * reducer function for the structure screen
 * @param {Object} state - the state
 * @param {Object} action - the action
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.UPDATE_EDITING_LAYER_AND_REPLACE_USED_MATERIALS: {
      let layers = cloneDeep(state.entities.layers);
      Utils.addOrUpdateEntities([payload], layers);
      sortLayersByIndex(layers);
      let usedMaterials = cloneDeep(state.entities.usedMaterials);
      const oldUsedMaterialsIds = Object.values(
        state.entities.usedMaterials.byId
      )
        .filter(usedMaterial => usedMaterial.layer === payload.id)
        .map(usedMaterial => usedMaterial.id);
      Utils.deleteEntities(oldUsedMaterialsIds, usedMaterials);
      Utils.addOrUpdateEntities(payload.usedmaterial_set, usedMaterials);
      return {
        ...state,
        entities: { ...state.entities, layers, usedMaterials }
      };
    }

    case actionType.UPSERT_LAYERS: {
      let newLayers = cloneDeep(state.entities.layers);
      const ids = payload.map(layer => layer.id);
      const uniqueIds = [...new Set(ids)];
      const groupedLayers = uniqueIds.map(id =>
        payload.filter(layer => layer.id === id)
      );
      const layersContainingStaircases = groupedLayers.reduce(
        (accumulator, groupedLayer) => {
          if (groupedLayer.length === 1) {
            accumulator.push(groupedLayer[0]);
          } else {
            accumulator.push(groupedLayer);
          }
          return accumulator;
        },
        []
      );

      Utils.addOrUpdateEntities(layersContainingStaircases, newLayers);
      sortLayersByIndex(newLayers);
      const sortedIds = newLayers.allIds;
      const { selectedLayerId } = state.ui;
      let newSelectedLayerId = -1;
      if (sortedIds.length !== 0) {
        if (selectedLayerId !== -1) {
          const selectedLayerIsStaircase = Array.isArray(
            newLayers.byId[selectedLayerId]
          );
          newSelectedLayerId = selectedLayerIsStaircase
            ? `${selectedLayerId}#1`
            : selectedLayerId;
        } else {
          newSelectedLayerId = sortedIds[0];
        }
      }
      return {
        ...state,
        entities: { ...state.entities, layers: newLayers },
        ui: { ...state.ui, selectedLayerId: newSelectedLayerId }
      };
    }

    case actionType.ADD_LAYER: {
      const { layer, newUsedMaterials, layersToUpdate } = payload;
      let layers = cloneDeep(state.entities.layers);
      const layersToUpsert = layersToUpdate.concat(layer);
      Utils.addOrUpdateEntities(layersToUpsert, layers);
      sortLayersByIndex(layers);
      let usedMaterials = cloneDeep(state.entities.usedMaterials);
      Utils.addOrUpdateEntities(newUsedMaterials, usedMaterials);
      return {
        ...state,
        entities: { ...state.entities, layers, usedMaterials },
        ui: { ...state.ui, selectedLayerId: layer.id }
      };
    }

    case actionType.SELECT_LAYER: {
      return {
        ...state,
        ui: { ...state.ui, selectedLayerId: payload }
      };
    }

    case actionType.DELETE_LAYER: {
      const { layerId, layersToUpdate } = payload;
      let newLayers = cloneDeep(state.entities.layers);
      const layer = newLayers.byId[layerId];
      const layerArrayIndex = newLayers.allIds.indexOf(layer.id);
      const usedMaterialsToDelete = layer.usedmaterial_set;
      Utils.deleteEntities([layerId], newLayers);
      newLayers.allIds = newLayers.allIds.filter(id => id !== layerId);
      Utils.addOrUpdateEntities(layersToUpdate, newLayers);
      sortLayersByIndex(newLayers);
      const updatedSortedIds = newLayers.allIds;
      let newUsedMaterials = cloneDeep(state.entities.usedMaterials);
      Utils.deleteEntities(usedMaterialsToDelete, newUsedMaterials);
      let newSelectedLayerId = -1;
      if (updatedSortedIds.length !== 0) {
        if (layer.index > 1)
          newSelectedLayerId = updatedSortedIds[layerArrayIndex - 1];
        else newSelectedLayerId = updatedSortedIds[0];
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          layers: newLayers,
          usedMaterials: newUsedMaterials
        },
        ui: { ...state.ui, selectedLayerId: newSelectedLayerId }
      };
    }

    case actionType.SELECT_USED_MATERIAL: {
      return {
        ...state,
        ui: { ...state.ui, selectedUsedMaterialId: payload }
      };
    }

    case actionType.UPSERT_USED_MATERIALS: {
      const { reset } = action;
      let usedMaterials = cloneDeep(
        reset
          ? defaultState.entities.usedMaterials
          : state.entities.usedMaterials
      );
      Utils.addOrUpdateEntities(payload, usedMaterials);
      return {
        ...state,
        entities: { ...state.entities, usedMaterials }
      };
    }

    case actionType.PATCH_USED_MATERIAL: {
      let usedMaterials = cloneDeep(state.entities.usedMaterials);
      usedMaterials.byId[payload.id] = payload;
      return {
        ...state,
        entities: { ...state.entities, usedMaterials }
      };
    }

    case actionType.RESET_STRUCTURE: {
      return {
        ...defaultState,
        ui: {
          ...defaultState.ui,
          parameterizedStructures: state.ui.parameterizedStructures
        }
      };
    }

    case actionType.SET_PARAMETERIZED_STRUCTURES: {
      return {
        ...state,
        ui: {
          ...state.ui,
          parameterizedStructures: payload
        }
      };
    }

    case actionType.FETCH_FAMILY_STRUCTURES: {
      const updatedEntities = { ...state.entities.usedMaterials.byId };
      const allUsedMaterialIds = new Set(state.entities.usedMaterials.allIds);
      let familyAliases = {};
      const familyStructuresMap = payload.reduce(
        (acc, { memberId, alias, structure }) => {
          familyAliases[memberId] = alias;
          structure = structure
            .map(({ parameters, used_materials, correct_order, ...layer }) => {
              const usedMaterialIds = Object.values(used_materials).map(
                material => {
                  const materialId = parseInt(material.MetacellId);
                  if (!updatedEntities[materialId]) {
                    updatedEntities[materialId] = {
                      ...material,
                      material: material.MetacellMatId
                    };
                    allUsedMaterialIds.add(materialId);
                  }
                  return materialId;
                }
              );

              return {
                ...layer,
                id: correct_order,
                index: correct_order,
                correct_order: correct_order,
                tempStructure: layer.structure || layer.fileName,
                parameterList: JSON.stringify(parameters),
                usedmaterial_set: usedMaterialIds,
                used_materials: used_materials,
                structure: layer.structure,
                fileName: layer.fileName,
                materials: usedMaterialIds
                  .map(id => updatedEntities[id]?.name)
                  .join(", ")
              };
            })
            .sort((a, b) => a.index - b.index);

          acc[memberId] = structure;
          return acc;
        },
        {}
      );

      return {
        ...state,
        ui: {
          ...state.ui,
          familyStructures: familyStructuresMap,
          familyAliases: familyAliases
        },
        entities: {
          ...state.entities,
          usedMaterials: {
            byId: updatedEntities,
            allIds: Array.from(allUsedMaterialIds) // convert set to array
          }
        }
      };
    }

    case actionType.SELECT_FAMILY_MEMBER: {
      return {
        ...state,
        ui: { ...state.ui, selectedFamilyMemberId: payload }
      };
    }

    // store discretized data only for whichever family member is selected
    // and clear that from redux storage whenever not necessary
    // to improve frontend performance
    case actionType.FETCH_FAMILY_MEMBER_DISCRETIZED:
      return {
        ...state,
        ui: { ...state.ui, familyDiscretizedData: payload }
      };

    case actionType.CLEAR_FAMILY_MEMBER_DISCRETIZED:
      return {
        ...state,
        ui: { ...state.ui, familyDiscretizedData: {} }
      };

    default:
      return state;
  }
}
