import React, { PureComponent } from "react";
import {
  Grid,
  Paper,
  Typography,
  withStyles,
  FormLabel,
  TextField,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip
} from "@material-ui/core";
import EditActions from "components/EditActions/EditActions";
import SetPoint from "./components/SetPoint/SetPoint";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { connect } from "react-redux";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SetPointSelector from "MetaComponent/selectors/SetPoint";
import SetPointApi from "MetaComponent/api/SetPoint";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import IconTooltip from "components/IconTooltip/IconTooltip";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import NumberInput from "components/NumberInput/NumberInput";
import SetPointTable from "./components/SetPointTable/SetPointTable";
import Order from "components/Order/Order";
import Direction from "components/Direction/Direction";
import PolarizationOut from "components/PolarizationOut/PolarizationOut";
import LoadingOverlay from "components/LoadingOverlay/LoadingOverlay";
import { xTooltip } from "./components/SetPoint/components/SetPointForm/SetPointForm";
import Polarization from "components/Polarization/Polarization";
import DeleteIcon from "@material-ui/icons/Delete";
import { cloneDeep } from "lodash";

const styles = theme => ({
  basicPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  textField: {
    marginBottom: 5
  },
  title: {
    float: "left"
  },
  setPointBack: {
    float: "left"
  },
  setPointForward: {
    float: "right"
  },
  setPointTitle: {
    margin: "auto"
  }
});

const requiredFieldText = "required";

export const yTooltip = `This setting defines the accuracy of the calculation. Increasing
“y” increases the accuracy but also increases the
calculation time. Set the number of diffraction orders considered in y direction for this simulation.`;

export const unitOptions = Object.freeze(["μm", "nm"]);

const designTargetSchema = Yup.object().shape({
  name: Yup.string().required(requiredFieldText),
  wavelength: Yup.number().required(requiredFieldText),
  order_x: Yup.number()
    .required(requiredFieldText)
    .integer("Order x must be an integer"),
  order_y: Yup.number()
    .required(requiredFieldText)
    .integer("Order y must be an integer"),
  diffractive_order_x: Yup.number()
    .required(requiredFieldText)
    .integer("Accuracy x must be an integer"),
  diffractive_order_y: Yup.number()
    .required(requiredFieldText)
    .integer("Accuracy y must be an integer")
});

/**
 * a component to allow the user to select the far field wavefront target of a design target
 * @author Akira Kotsugai
 */
export class DesignTargetDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      warnUserAboutSetPoint: false,
      saving: false,
      editingIncidentLight: null
    };
  }

  /**
   * every time the active design target changes we cancel the editing
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.focusedDesignTargetId !== this.props.focusedDesignTargetId) {
      this.setState({
        editingIncidentLight: null
      });
      this.cancelEditingFocusedDesignTarget();
    }
  }

  dictIntToFloat(dict) {
    const copyDict = Object.assign({}, dict);
    for (const key of Object.keys(copyDict)) {
      let value = copyDict[key];
      if (!isNaN(value)) {
        copyDict[key] = Number(value);
      }
    }
    return copyDict;
  }

  /**
   * @returns {Object} the focused design target object
   */
  getFocusedDesignTarget = () => {
    return this.props.designTargets.byId[this.props.focusedDesignTargetId];
  };

  /**
   * it changes the editing state to true and sets the set point slide direction to up
   * because the state could be dirty and slide the set point component from the side directions
   * which dont make sense. we slide to side direction when the user is changing the selected set point
   */
  editFocusedDesignTarget = () => {
    this.setState({
      editing: true
    });
  };

  /**
   * it changes the editing state to false
   */
  cancelEditingFocusedDesignTarget = (resetSP = false) => {
    this.setState({
      editing: false,
      ...(resetSP ? { editingIncidentLight: null } : {})
    });
    this.formResetter();
  };

  getValueorNone = value => {
    if (value === null || value === undefined || value === "null") {
      return "";
    } else {
      return value;
    }
  };

  /**
   * it creates set point options for the design target with both static and database options
   * and the dynamic options are filtered by compatible family
   * @returns {Object[]} the set point options
   */
  getSetPointOptions() {
    const { openMetaComponent, compatibleSetPointsIds } = this.props;
    let staticOptions = [];
    if (openMetaComponent) {
      staticOptions.push("NEW");
    }
    // temporary workaround for showing design targets in library where openMetaComponent is not present
    const setPointIds = compatibleSetPointsIds
      ? compatibleSetPointsIds
      : Object.values(this.props.setPoints.allIds);
    let dynamicOptions = setPointIds.map(setPointId => "" + setPointId);
    return [...staticOptions, ...dynamicOptions];
  }

  /**
   * it creates a set point and append it to the list of available set points
   * @param {Object} setPoint - the editing set point values
   * @param {Boolean} selectSetPoint - whether it should set the set point in the selected design target
   * @callback
   */
  createSetPoint = (setPoint, selectSetPoint) => {
    const {
      createSetPointAction,
      compatibleSetPointsIds,
      updateSelectedDesignTarget
    } = this.props;
    return createSetPointAction({
      ...setPoint,
      incident_light: this.dictIntToFloat(setPoint.incident_light)
    }).then(setPointId => {
      let newSDTProperties = {
        list_of_set_points: [...compatibleSetPointsIds, setPointId]
      };
      if (selectSetPoint) {
        newSDTProperties["set_point"] = setPointId;
      }
      return updateSelectedDesignTarget(newSDTProperties).then(
        () => setPointId
      );
    });
  };

  deleteSetPoint = async setPointId => {
    const { deleteSetPointAction, showConfirmDialog } = this.props;
    const confirmAction = async () => {
      this.setState({ saving: true });
      await deleteSetPointAction(setPointId);
      this.setState({ saving: false });
      this.setState({ editingIncidentLight: null });
    };
    showConfirmDialog(
      "Delete the selected set point?",
      "This may result in making other target(s) that rely on the same setpoint unusuable until a new set point is added to those target(s).",
      confirmAction,
      undefined,
      false
    );
  };

  updateSetPoint = setPoint => {
    if (!setPoint) return;
    const {
      updateSetPointAction
      // compatibleSetPointsIds,
      // updateSelectedDesignTarget
    } = this.props;
    return updateSetPointAction(setPoint.id, {
      ...setPoint,
      incident_light: this.dictIntToFloat(setPoint.incident_light)
    }).then(({ payload }) => {
      this.setState({
        editingIncidentLight: payload[0]
      });
    });
  };

  /**
   * it creates and selects the created record in the design's edit mode
   * @param {Object} setPoint - the editing set point values
   */
  createAndSelectSetPoint = setPoint => {
    return this.createSetPoint(setPoint).then(setPointId =>
      this.fieldValueSetter("set_point", "" + setPointId)
    );
  };

  /**
   * the initial form values will consist of some of the design target properties
   * @returns {Object} the form values
   */
  getFormInitialValues() {
    const {
      id,
      name,
      description,
      order_out,
      polarization_out,
      optimize_for,
      direction_out,
      unit
    } = this.getFocusedDesignTarget();
    let set_point = null;
    let weight = null;
    let diffractive_order = null;
    let wavelength = "";
    // if (this.props.openMetaComponent) {
    const selectedDesignTarget = Object.values(
      this.props.selectedDesignTargets.byId
    ).find(sdt => sdt.design_target === id);
    set_point = selectedDesignTarget.set_point;
    weight = selectedDesignTarget.weight;
    diffractive_order = selectedDesignTarget.diffractive_order;
    // }
    if (!this.state.editingIncidentLight) {
      const editingIncidentLight = this.props.setPoints.byId[set_point];
      this.setState({ editingIncidentLight });
      if (editingIncidentLight) {
        wavelength = editingIncidentLight.incident_light.wavelength;
      }
    } else {
      wavelength = this.state.editingIncidentLight.incident_light.wavelength;
    }
    return {
      id,
      name,
      description,
      order_x: order_out[0],
      order_y: order_out[1],
      polarization_out,
      optimize_for,
      direction_out,
      set_point: set_point ? "" + set_point : "NEW",
      unit,
      weight,
      wavelength,
      diffractive_order_x: diffractive_order?.X,
      diffractive_order_y: diffractive_order?.Y
    };
  }

  /**
   * it saves the design target form values if the orders are compatible with the set point orders
   * else it asks the user if they want to create and select a new design target with compatible orders
   * @param {Object} values - form values
   */
  saveDesignTarget = async values => {
    const { setPoints, showConfirmDialog, openMetaComponent } = this.props,
      { editingIncidentLight } = this.state,
      {
        order_x,
        order_y,
        set_point,
        diffractive_order_x,
        diffractive_order_y
      } = values,
      thereIsASetPointSelected = set_point !== "NEW",
      thereIsANewSetPointSelected = set_point === "NEW",
      thereIsNoSetPointSelected = editingIncidentLight == null;
    // if (thereIsNoSetPointSelected) {
    //   this.showNoSetPointWarning();
    //   return
    // }
    if (
      openMetaComponent &&
      !thereIsNoSetPointSelected &&
      (thereIsASetPointSelected || thereIsANewSetPointSelected)
    ) {
      const setPoint = setPoints.byId[set_point],
        ordersAreCompatible =
          order_x <= diffractive_order_x && order_y <= diffractive_order_y;
      if (!ordersAreCompatible) {
        const confirmAction = async () => {
          this.setState({ saving: true });
          // creates a set point from the selected one with compatible orders
          // and updates the design target with it
          let newSetPoint = { ...setPoint };
          delete newSetPoint.id;
          delete newSetPoint.set_point_id;
          // newSetPoint.diffractive_order.X = order_x;
          // newSetPoint.diffractive_order.Y = order_y;
          await this.createSetPoint(newSetPoint, true);
          // since the set point creation already changes the sdt.set_point
          // we ignore the set point when updating the design target
          let designTargetValuesToSubmit = {
            ...values,
            diffractive_order_x: order_x,
            diffractive_order_y: order_y
          };
          delete designTargetValuesToSubmit.set_point;
          await this.updateDesignTarget(designTargetValuesToSubmit);
        };
        showConfirmDialog(
          "Incompatible accuracy",
          "The accuracy is greater than the diffraction order, would you like to increase the accuracy accordingly",
          confirmAction,
          undefined,
          false
        );
      } else {
        this.setState({ saving: true });
        await this.updateDesignTarget(values);
        if (editingIncidentLight?.id === "NEW") {
          var response = await this.createSetPoint(editingIncidentLight, true);
          this.onSelectSetPoint(response);
        } else {
          await this.updateSetPoint(editingIncidentLight);
        }
        this.setState({ saving: false });
      }
    } else if (!openMetaComponent) {
      await this.updateDesignTarget(values);
    } else {
      this.showNoSetPointWarning();
    }
  };

  /**
   * it removes and shows the warning message.
   * it is necessary otherwise the message is only shown once
   */
  showNoSetPointWarning() {
    this.setState({ warnUserAboutSetPoint: false }, () =>
      this.setState({ warnUserAboutSetPoint: true })
    );
  }

  /**
   * it calls the action to update a design target, updates the selected design target
   * and cancels the editing when it succeeds
   * @param {Object} values - form values
   */
  updateDesignTarget = async values => {
    let preparedValues = { ...values };
    preparedValues.order_out = [preparedValues.order_x, preparedValues.order_y];
    delete preparedValues.order_x;
    delete preparedValues.order_y;
    let sdtPayload = {};
    if (preparedValues.weight) {
      sdtPayload["weight"] = preparedValues.weight;
    }
    if (preparedValues.set_point && preparedValues.set_point !== "NEW") {
      sdtPayload["set_point"] = preparedValues.set_point;
    }
    if (
      preparedValues.diffractive_order_x !== null &&
      preparedValues.diffractive_order_y !== null
    ) {
      sdtPayload["diffractive_order"] = {
        X: preparedValues.diffractive_order_x,
        Y: preparedValues.diffractive_order_y
      };
    }
    if (Object.keys(sdtPayload).length) {
      await this.props.updateSelectedDesignTarget(sdtPayload);
    }
    await this.props
      .updateDesignTargetAction(values.id, preparedValues)
      .then(() => this.cancelEditingFocusedDesignTarget());
    this.setState({ saving: false });
  };

  /**
   * changes the value of the editing set point field
   * @callback
   * @param {Number} setPointId - the id of the set point to be selected
   */
  onSelectSetPoint = setPointId => {
    var exisitingSps = this.getSetPointOptions().filter(sp => sp !== "NEW");
    var editingIncidentLight;
    if (setPointId === "NEW") {
      const existingEditingIncidentLight = this.state.editingIncidentLight;
      editingIncidentLight = {
        incident_light_type: "PW",
        unit: "nm",
        // create based on whats visible on screen or
        // create new one based on selected target SP (from db)
        incident_light: {
          azimut: 0.0,
          zenit: 0.0,
          wavelength:
            exisitingSps && this.props.setPoints.byId[exisitingSps[0]]
              ? this.props.setPoints.byId[exisitingSps[0]]?.incident_light
                  ?.wavelength
              : "",
          amplitude: 1,
          polarization: "TM",
          incident_light_type: "PW",
          focal_spot_x: 0,
          focal_spot_y: 0,
          focal_spot_z: 0,
          beam_divergence: 0.0
          // copy from existing if available on screen
        },
        ...existingEditingIncidentLight,
        name: "new SP",
        id: "NEW"
      };
    } else {
      editingIncidentLight = this.props.setPoints.byId[setPointId];
    }
    this.setState({ editingIncidentLight });
    this.fieldValueSetter("set_point", "" + setPointId);
  };

  /**
   * @returns {Object[]} available set point objects
   */
  listSetPointOptions() {
    const availableSetPoints = this.getSetPointOptions().filter(
      id => id !== "NEW"
    );
    return availableSetPoints.map(id => this.props.setPoints.byId[id]);
  }

  render() {
    const { classes, openMetaComponent } = this.props,
      {
        editing,
        warnUserAboutSetPoint,
        saving,
        editingIncidentLight
      } = this.state,
      selectedDesignTarget = this.getFocusedDesignTarget(),
      mcSelectedDesignTarget = Object.values(
        this.props.selectedDesignTargets.byId
      ).find(sdt => sdt.design_target === selectedDesignTarget?.id);
    return selectedDesignTarget ? (
      <>
        <Paper className={classes.basicPaper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h5" component="h3">
                {`${selectedDesignTarget.name} details`}
              </Typography>
              <div style={{ float: "right" }}>
                <EditActions
                  isEditing={editing}
                  isSaving={false}
                  onEdit={this.editFocusedDesignTarget}
                  onCancel={() => this.cancelEditingFocusedDesignTarget(true)}
                  onSave={() => {
                    if (!editingIncidentLight) {
                      this.showNoSetPointWarning();
                      return;
                    }
                    this.formSubmitter();
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} style={{ position: "relative" }}>
              {saving && <LoadingOverlay />}
              <Formik
                initialValues={this.getFormInitialValues()}
                validationSchema={designTargetSchema}
                onSubmit={this.saveDesignTarget}
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  submitForm,
                  resetForm,
                  setFieldValue
                }) => {
                  // binding the form submitter, the form resetter and the field value setter
                  // to allow access from other places
                  this.formSubmitter = submitForm;
                  this.formResetter = resetForm;
                  this.fieldValueSetter = setFieldValue;

                  var setPoint = (
                    <div>
                      <SetPoint
                        setPoint={
                          values.set_point === "NEW"
                            ? null
                            : this.props.setPoints.byId[
                                parseInt(values.set_point)
                              ]
                        }
                        // onSave={this.createAndSelectSetPoint}
                      />
                    </div>
                  );
                  return (
                    <Form>
                      <Grid container spacing={2} style={{ marginLeft: "3px" }}>
                        <Grid container spacing={1}>
                          <Grid item xs={3}>
                            <TextField
                              required
                              className={classes.textField}
                              label={"Name"}
                              name={"name"}
                              disabled={!editing}
                              error={touched.name && errors.name}
                              helperText={touched.name && errors.name}
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              className={classes.textField}
                              name={"description"}
                              label={"Description"}
                              disabled={!editing}
                              multiline={true}
                              rows={1}
                              rowsMax={4}
                              value={values.description}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl style={{ width: "90%" }}>
                              <div>
                                <FormLabel
                                  style={{ fontSize: 12 }}
                                  disabled={!editing}
                                  htmlFor="optimize_for"
                                >
                                  Optimize for
                                </FormLabel>
                                <IconTooltip
                                  text={
                                    "Aspect of the target wavefront to be approximated by the meta-component."
                                  }
                                />
                              </div>
                              <UnselfishSelect
                                disabled={!editing}
                                style={{ marginTop: 4, marginBottom: 5 }}
                                value={values.optimize_for}
                                name={"optimize_for"}
                                onChange={handleChange}
                                MenuProps={{
                                  disableScrollLock: true
                                }}
                              >
                                <MenuItem value="amplitude">Amplitude</MenuItem>
                                <MenuItem value="phase">Phase</MenuItem>
                                <MenuItem value="complex">Complex</MenuItem>
                              </UnselfishSelect>
                            </FormControl>
                          </Grid>
                          {openMetaComponent && (
                            <Grid item xs={3}>
                              <div>
                                <FormLabel
                                  style={{ fontSize: 12 }}
                                  disabled={!editing}
                                  htmlFor="weight"
                                >
                                  Weight
                                </FormLabel>
                                <IconTooltip
                                  text={
                                    "Positive value to weigh the importance of this target. A high value is very important whereas a low value is little important."
                                  }
                                />
                              </div>
                              <NumberInput
                                className={classes.textField}
                                name={"weight"}
                                disabled={!editing}
                                value={values.weight}
                                onChange={handleChange}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              variant="h6"
                              component="h6"
                            >
                              Incident Light
                            </Typography>
                            <div
                              style={{ float: "right", marginRight: "30px" }}
                            >
                              <Tooltip title="Delete settings">
                                <IconButton
                                  test-data="deleteSpButton"
                                  name="deleteSpButton"
                                  edge="end"
                                  aria-label="Delete Set Point"
                                  disabled={
                                    !editing || !editingIncidentLight?.id
                                  }
                                  onClick={() =>
                                    this.deleteSetPoint(
                                      editingIncidentLight?.id
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl style={{ width: "90%" }}>
                              <div>
                                <FormLabel
                                  style={{ fontSize: 12 }}
                                  disabled={!editing}
                                  htmlFor="selected_incident_light"
                                >
                                  Selected
                                </FormLabel>
                              </div>
                              <UnselfishSelect
                                disabled={!editing}
                                style={{ marginTop: 4, marginBottom: 5 }}
                                value={parseInt(values.set_point)}
                                name="selected_incident_light"
                                onChange={({ target }) => {
                                  this.onSelectSetPoint(target.value);
                                }}
                                MenuProps={{
                                  disableScrollLock: true
                                }}
                              >
                                {this.listSetPointOptions()
                                  .filter(sp => sp != undefined)
                                  .map(sp => (
                                    <MenuItem value={sp.id}>{sp.name}</MenuItem>
                                  ))}
                                <MenuItem
                                  value="NEW"
                                  disabled={
                                    openMetaComponent?.family_is_pdk ||
                                    mcSelectedDesignTarget.target_for_pdk
                                  }
                                >
                                  New*
                                </MenuItem>
                              </UnselfishSelect>
                            </FormControl>
                          </Grid>
                        </Grid>
                        {editingIncidentLight && (
                          <>
                            <Grid container>
                              <Grid item xs={3}>
                                <TextField
                                  required
                                  className={classes.textField}
                                  label={"Name"}
                                  name={"spname"}
                                  test-data={"spNameField"}
                                  disabled={
                                    !editing ||
                                    openMetaComponent?.family_is_pdk ||
                                    mcSelectedDesignTarget.target_for_pdk
                                  }
                                  // error={touched.name && errors.name}
                                  // helperText={touched.name && errors.name}
                                  value={editingIncidentLight?.name || ""}
                                  onChange={({ target }) =>
                                    this.setState({
                                      editingIncidentLight: {
                                        ...editingIncidentLight,
                                        name: target.value
                                      }
                                    })
                                  }
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <div>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    disabled={!editing}
                                    htmlFor="amplitude"
                                  >
                                    Amplitude
                                  </FormLabel>
                                </div>
                                <NumberInput
                                  className={classes.textField}
                                  name={"amplitude"}
                                  disabled={
                                    !editing ||
                                    openMetaComponent?.family_is_pdk ||
                                    mcSelectedDesignTarget.target_for_pdk
                                  }
                                  value={this.getValueorNone(
                                    editingIncidentLight?.incident_light
                                      .amplitude
                                  )}
                                  onChange={({ target }) =>
                                    this.setState({
                                      editingIncidentLight: {
                                        ...editingIncidentLight,
                                        incident_light: {
                                          ...editingIncidentLight.incident_light,
                                          amplitude: target.value
                                        }
                                      }
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <div>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    disabled={!editing}
                                    htmlFor="wavelength"
                                  >
                                    Wavelength
                                  </FormLabel>
                                </div>
                                <NumberInput
                                  className={classes.textField}
                                  name={"wavelength"}
                                  disabled={
                                    !editing ||
                                    openMetaComponent?.family_is_pdk ||
                                    mcSelectedDesignTarget.target_for_pdk
                                  }
                                  value={this.getValueorNone(
                                    editingIncidentLight?.incident_light
                                      .wavelength
                                  )}
                                  error={
                                    touched.wavelength && errors.wavelength
                                  }
                                  helperText={
                                    touched.wavelength && errors.wavelength
                                  }
                                  onChange={({ target }) => {
                                    setFieldValue("wavelength", target.value);
                                    this.setState({
                                      editingIncidentLight: {
                                        ...editingIncidentLight,
                                        incident_light: {
                                          ...editingIncidentLight.incident_light,
                                          wavelength: target.value
                                        }
                                      }
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <div>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    disabled={!editing}
                                    htmlFor="Unit"
                                  >
                                    Wavelength Unit
                                  </FormLabel>
                                  <IconTooltip text="Unit of the wavelength size." />
                                </div>
                                <UnselfishSelect
                                  style={{ marginTop: 4 }}
                                  name="unit"
                                  value={editingIncidentLight?.unit}
                                  onChange={({ target }) =>
                                    this.setState({
                                      editingIncidentLight: {
                                        ...editingIncidentLight,
                                        unit: target.value
                                      }
                                    })
                                  }
                                  disabled={
                                    !editing ||
                                    openMetaComponent?.family_is_pdk ||
                                    mcSelectedDesignTarget.target_for_pdk
                                  }
                                >
                                  {unitOptions.map(unit => (
                                    <MenuItem
                                      name="PolarizationOption"
                                      value={unit}
                                    >
                                      {unit}
                                    </MenuItem>
                                  ))}
                                </UnselfishSelect>
                              </Grid>
                              <Polarization
                                updateFieldCallback={async (value, keyName) => {
                                  await this.setState({
                                    editingIncidentLight: {
                                      ...editingIncidentLight,
                                      incident_light: {
                                        ...editingIncidentLight.incident_light,
                                        polarization: value.polarization
                                      }
                                    }
                                  });
                                }}
                                polarization={
                                  editingIncidentLight?.incident_light
                                    .polarization
                                }
                                gridColumnXs={2}
                                isSweep={false}
                                disabled={
                                  !editing ||
                                  openMetaComponent?.family_is_pdk ||
                                  mcSelectedDesignTarget.target_for_pdk
                                }
                              />
                            </Grid>
                            <Grid container>
                              <Grid item xs={3}>
                                <div>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    disabled={!editing}
                                    htmlFor="incident_light_type"
                                  >
                                    Type
                                  </FormLabel>
                                </div>
                                <UnselfishSelect
                                  style={{ marginTop: 4 }}
                                  name="incident_light_type"
                                  value={
                                    editingIncidentLight?.incident_light_type
                                  }
                                  onChange={({ target }) =>
                                    this.setState({
                                      editingIncidentLight: {
                                        ...editingIncidentLight,
                                        incident_light: {
                                          ...editingIncidentLight?.incident_light,
                                          focal_spot_x:
                                            editingIncidentLight?.incident_light
                                              ?.focal_spot_x || 0,
                                          focal_spot_y:
                                            editingIncidentLight?.incident_light
                                              ?.focal_spot_y || 0,
                                          focal_spot_z:
                                            editingIncidentLight?.incident_light
                                              ?.focal_spot_z || 0,
                                          beam_divergence:
                                            editingIncidentLight?.incident_light
                                              ?.beam_divergence || 0
                                        },
                                        incident_light_type: target.value
                                      }
                                    })
                                  }
                                  disabled={!editing}
                                >
                                  <MenuItem
                                    name="incident_light_type_option"
                                    value="PW"
                                  >
                                    Plane wave
                                  </MenuItem>
                                  <MenuItem
                                    name="incident_light_type_option"
                                    value="GB"
                                  >
                                    Gaussian Beam
                                  </MenuItem>
                                </UnselfishSelect>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={2}>
                                <div>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    disabled={!editing}
                                    htmlFor="azimut"
                                  >
                                    Azimuth
                                  </FormLabel>
                                  <IconTooltip text="Angle between incident wave and x-axis (in-plane)" />
                                </div>
                                <NumberInput
                                  className={classes.textField}
                                  name={"azimut"}
                                  disabled={
                                    !editing ||
                                    openMetaComponent?.family_is_pdk ||
                                    mcSelectedDesignTarget.target_for_pdk
                                  }
                                  value={this.getValueorNone(
                                    editingIncidentLight?.incident_light.azimut
                                  )}
                                  onChange={({ target }) =>
                                    this.setState({
                                      editingIncidentLight: {
                                        ...editingIncidentLight,
                                        incident_light: {
                                          ...editingIncidentLight.incident_light,
                                          azimut: target.value
                                        }
                                      }
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <div>
                                  <FormLabel
                                    style={{ fontSize: 12 }}
                                    disabled={!editing}
                                    htmlFor="zenit"
                                  >
                                    Zenith
                                  </FormLabel>
                                  <IconTooltip text="Angle of incident wave to surface normal" />
                                </div>
                                <NumberInput
                                  className={classes.textField}
                                  name={"zenit"}
                                  disabled={
                                    !editing ||
                                    openMetaComponent?.family_is_pdk ||
                                    mcSelectedDesignTarget.target_for_pdk
                                  }
                                  value={this.getValueorNone(
                                    editingIncidentLight?.incident_light.zenit
                                  )}
                                  onChange={({ target }) =>
                                    this.setState({
                                      editingIncidentLight: {
                                        ...editingIncidentLight,
                                        incident_light: {
                                          ...editingIncidentLight.incident_light,
                                          zenit: target.value
                                        }
                                      }
                                    })
                                  }
                                />
                              </Grid>
                              {editingIncidentLight?.incident_light_type ===
                                "GB" && (
                                <Grid item xs={2}>
                                  <div>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      disabled={!editing}
                                      htmlFor="focal_spot_x"
                                    >
                                      Focal Spot X
                                    </FormLabel>
                                    {/* <IconTooltip text="" /> */}
                                  </div>
                                  <NumberInput
                                    className={classes.textField}
                                    name={
                                      "focal_spot_x" ||
                                      openMetaComponent?.family_is_pdk ||
                                      mcSelectedDesignTarget.target_for_pdk
                                    }
                                    disabled={!editing}
                                    error={
                                      touched.focal_spot_x &&
                                      errors.focal_spot_x
                                    }
                                    helperText={
                                      touched.focal_spot_x &&
                                      errors.focal_spot_x
                                    }
                                    allowNegative
                                    value={
                                      editingIncidentLight?.incident_light
                                        .focal_spot_x
                                    }
                                    onChange={({ target }) =>
                                      this.setState({
                                        editingIncidentLight: {
                                          ...editingIncidentLight,
                                          incident_light: {
                                            ...editingIncidentLight.incident_light,
                                            focal_spot_x: target.value
                                          }
                                        }
                                      })
                                    }
                                  />
                                </Grid>
                              )}
                              {editingIncidentLight?.incident_light_type ===
                                "GB" && (
                                <Grid item xs={2}>
                                  <div>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      disabled={!editing}
                                      htmlFor="focal_spot_y"
                                    >
                                      Focal Spot Y
                                    </FormLabel>
                                    {/* <IconTooltip text="" /> */}
                                  </div>
                                  <NumberInput
                                    className={classes.textField}
                                    name={
                                      "focal_spot_y" ||
                                      openMetaComponent?.family_is_pdk ||
                                      mcSelectedDesignTarget.target_for_pdk
                                    }
                                    disabled={!editing}
                                    value={
                                      editingIncidentLight?.incident_light
                                        .focal_spot_y
                                    }
                                    allowNegative
                                    onChange={({ target }) =>
                                      this.setState({
                                        editingIncidentLight: {
                                          ...editingIncidentLight,
                                          incident_light: {
                                            ...editingIncidentLight.incident_light,
                                            focal_spot_y: target.value
                                          }
                                        }
                                      })
                                    }
                                  />
                                </Grid>
                              )}
                              {editingIncidentLight?.incident_light_type ===
                                "GB" && (
                                <Grid item xs={2}>
                                  <div>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      disabled={
                                        !editing ||
                                        openMetaComponent?.family_is_pdk ||
                                        mcSelectedDesignTarget.target_for_pdk
                                      }
                                      htmlFor="focal_spot_z"
                                    >
                                      Focal Spot Z
                                    </FormLabel>
                                    {/* <IconTooltip text="" /> */}
                                  </div>
                                  <NumberInput
                                    className={classes.textField}
                                    name={"focal_spot_z"}
                                    disabled={!editing}
                                    value={
                                      editingIncidentLight?.incident_light
                                        .focal_spot_z
                                    }
                                    allowNegative
                                    onChange={({ target }) =>
                                      this.setState({
                                        editingIncidentLight: {
                                          ...editingIncidentLight,
                                          incident_light: {
                                            ...editingIncidentLight.incident_light,
                                            focal_spot_z: target.value
                                          }
                                        }
                                      })
                                    }
                                  />
                                </Grid>
                              )}
                              {editingIncidentLight?.incident_light_type ===
                                "GB" && (
                                <Grid item xs={2}>
                                  <div>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      disabled={!editing}
                                      htmlFor="beam_divergence"
                                    >
                                      Beam Divergence
                                    </FormLabel>
                                  </div>
                                  <NumberInput
                                    className={classes.textField}
                                    name={"beam_divergence"}
                                    disabled={!editing}
                                    value={
                                      editingIncidentLight?.incident_light
                                        .beam_divergence
                                    }
                                    onChange={({ target }) =>
                                      this.setState({
                                        editingIncidentLight: {
                                          ...editingIncidentLight,
                                          incident_light: {
                                            ...editingIncidentLight.incident_light,
                                            beam_divergence: target.value
                                          }
                                        }
                                      })
                                    }
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </>
                        )}

                        {!mcSelectedDesignTarget.target_for_pdk && (
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                className={classes.title}
                                variant="h6"
                                component="h6"
                              >
                                Metacell Accuracy
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Order
                                required
                                name="diffractive_order_x"
                                className={classes.textField}
                                disabled={
                                  !editing ||
                                  openMetaComponent?.family_is_pdk ||
                                  mcSelectedDesignTarget.target_for_pdk
                                }
                                error={
                                  touched.diffractive_order_x &&
                                  errors.diffractive_order_x
                                }
                                helperText={
                                  touched.diffractive_order_x &&
                                  errors.diffractive_order_x
                                }
                                value={this.getValueorNone(
                                  values.diffractive_order_x
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                orderType={"Accuracy X"}
                                toolTipText={xTooltip}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Order
                                required
                                name="diffractive_order_y"
                                className={classes.textField}
                                disabled={
                                  !editing ||
                                  openMetaComponent?.family_is_pdk ||
                                  mcSelectedDesignTarget.target_for_pdk
                                }
                                error={
                                  touched.diffractive_order_y &&
                                  errors.diffractive_order_y
                                }
                                helperText={
                                  touched.diffractive_order_y &&
                                  errors.diffractive_order_y
                                }
                                value={this.getValueorNone(
                                  values.diffractive_order_y
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                orderType={"Accuracy Y"}
                                toolTipText={yTooltip}
                              />
                            </Grid>
                          </Grid>
                        )}
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              variant="h6"
                              component="h6"
                            >
                              Output Light
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Order
                              required
                              name="order_x"
                              className={classes.textField}
                              disabled={!editing}
                              error={touched.order_x && errors.order_x}
                              helperText={touched.order_x && errors.order_x}
                              value={this.getValueorNone(values.order_x)}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              orderType={"Order X"}
                              toolTipText={
                                "Diffraction order of the light exiting the component. The absolute value of order X should not be larger than the accuracy X of the set point"
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Direction
                              disabled={!editing}
                              value={values.direction_out}
                              name={"direction_out"}
                              onChange={handleChange}
                              style={{ width: "90%" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <PolarizationOut
                              style={{ width: "90%" }}
                              disabled={!editing}
                              value={values.polarization_out}
                              name={"polarization_out"}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3}>
                            <Order
                              required
                              name="order_y"
                              className={classes.textField}
                              disabled={!editing}
                              error={touched.order_y && errors.order_y}
                              helperText={touched.order_y && errors.order_y}
                              value={this.getValueorNone(values.order_y)}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              orderType={"Order Y"}
                              toolTipText={
                                "Diffraction order of the light exiting the component. The absolute value of order Y should not be larger than the accuracy Y of the set point"
                              }
                            />
                          </Grid>
                          {/* </Grid> */}
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
        {warnUserAboutSetPoint && (
          <DirectionSnackbar message="you must select a set point." />
        )}
      </>
    ) : null;
  }
}

const mapStateToProps = state => ({
  designTargets: DesignTargetSelector.getDesignTargets(state),
  setPoints: SetPointSelector.getSetPoints(state),
  selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
    state
  )
});

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    updateSetPointAction: (id, properties) =>
      dispatch(SetPointApi.updateSetPoint(id, properties)),
    createSetPointAction: setPoint =>
      dispatch(SetPointApi.createSetPoint(setPoint)),
    deleteSetPointAction: setPointId =>
      dispatch(SetPointApi.deleteSetPoint(setPointId)),
    updateDesignTargetAction: (id, properties) =>
      dispatch(DesignTargetApi.updateDesignTarget(id, properties))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(DesignTargetDetails)));
