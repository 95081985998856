import React, { PureComponent } from "react";
import { withStyles, Grid } from "@material-ui/core";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import StructureItem from "./components/StructureItem/StructureItem";
import OperatorItem from "./components/OperatorItem/OperatorItem";
import MergeItem from "./components/MergeItem/MergeItem";
import PropTypes from "prop-types";
import StructureApi from "MetaCell/api/Structure";

/**
 * A component the combination of parameterized structures
 * @author Akira Kotsugai
 */
export class StructureCombination extends PureComponent {
  mergeStructure = (operator, structure) => {
    const { combinationItems, parameters, updateCombination } = this.props;
    const newCombinationItems = [...combinationItems, operator, structure];
    const newParameters = Array.isArray(parameters)
      ? [...parameters, null]
      : [parameters, null];
    updateCombination(newCombinationItems, newParameters);
  };

  deleteStructure = async itemIndex => {
    const {
      combinationItems,
      parameters,
      getSelectedStructureIndex,
      updateCombination,
      combinationUsedMaterial,
      layer
    } = this.props;
    const selectedStructureIndex = getSelectedStructureIndex(itemIndex);
    const newCombinationItems = combinationItems.filter(
      (item, index) => index !== itemIndex && index !== itemIndex - 1
    );
    const newParameters = parameters.filter(
      (item, index) => index !== selectedStructureIndex
    );
    updateCombination(newCombinationItems, newParameters);
    await StructureApi.deleteUsedMaterial(
      combinationUsedMaterial,
      layer.simulation
    );
    this.props.showParams(0);
  };

  updateOperator = (itemIndex, newOperator) => {
    const { combinationItems, parameters, updateCombination } = this.props;
    let newCombinationItems = [...combinationItems];
    newCombinationItems[itemIndex] = newOperator;
    updateCombination(newCombinationItems, parameters);
  };

  updateStructure = (itemIndex, newStructure) => {
    const {
      combinationItems,
      parameters,
      updateCombination,
      getSelectedStructureIndex
    } = this.props;
    if (combinationItems[itemIndex] !== newStructure) {
      let newCombinationItems = [...combinationItems];
      newCombinationItems[itemIndex] = newStructure;
      let newParameters = [...parameters];
      const selectedStructureIndex = getSelectedStructureIndex(itemIndex);
      newParameters[selectedStructureIndex] = null;
      updateCombination(newCombinationItems, newParameters);
    }
  };

  render = () => {
    const { combinationItems, isFamilySimulation } = this.props;
    return (
      <Grid container spacing={2}>
        {combinationItems.map((item, index) => {
          return this.props.operatorOptions
            .map(({ name }) => name)
            .includes(item) ? (
            <OperatorItem
              key={index}
              operator={item}
              options={this.props.operatorOptions}
              itemIndex={index}
              onEdit={this.updateOperator}
              disabled={isNaN(this.props.layer.id) || isFamilySimulation}
            />
          ) : (
            <StructureItem
              itemIndex={index}
              key={index}
              structure={item}
              onDelete={index !== 0 && this.deleteStructure}
              onEdit={this.updateStructure}
              selected={index === this.props.selectedItemIndex}
              options={this.props.parameterizedStructures}
              showParams={this.props.showParams}
              disabled={isNaN(this.props.layer.id) || isFamilySimulation}
            />
          );
        })}
        <MergeItem
          structureOptions={this.props.parameterizedStructures}
          operatorOptions={this.props.operatorOptions}
          mergeStructure={this.mergeStructure}
          disabled={isNaN(this.props.layer.id) || isFamilySimulation}
        />
      </Grid>
    );
  };
}

StructureCombination.propTypes = {
  /**
   * possible parameterized structures
   */
  parameterizedStructures: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * the list of structures and operators
   */
  combinationItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * the list of possible operators
   */
  operatorOptions: PropTypes.arrayOf(PropTypes.obj).isRequired,
  /**
   * the active structure amongst the structure combination
   */
  selectedItemIndex: PropTypes.number.isRequired,
  /**
   * the layer structure parameters
   */
  parameters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired,
  /**
   * callback to update the layer structure and its parameters
   */
  updateCombination: PropTypes.func.isRequired,
  /**
   * callback for showing parameters of a different structure
   */
  showParams: PropTypes.func.isRequired
};

export default withErrorBoundary(StructureCombination);
