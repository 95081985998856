/**
 * @constant
 * @typedef {Object} StructureActionType
 * @property {String} ADD_LAYER - tells the reducer to add a layer
 * @property {String} DELETE_LAYER - tells the reducer to delete a layer
 * @property {String} COPY_LAYER - tells the reducer to copy a layer
 * @property {String} MOVE_LAYER_UP - tells the reducer to move a layer up
 * @property {String} MOVE_LAYER_DOWN - tells the reducer to move a layer down
 * @property {String} SELECT_LAYER - tells the reducer to select a layer
 * @property {String} UPSERT_LAYERS - tells the reducer to set a list of layers
 * @property {String} SELECT_USED_MATERIAL - tells the reducer to select a used material
 * @property {String} APPLY_USED_MATERIAL_EDITING - tells the reducer to save the used material
 * being edited
 * @property {String} UPSERT_USED_MATERIALS - tells the reducer to set a list of used materials in the state
 * @property {String} RESET_STRUCTURE - tells the reducer to empty layers and used materials
 * @property {String} UPDATE_EDITING_LAYER_AND_REPLACE_USED_MATERIALS - tells the reducer to update entities related to the editing layer
 * that had the image changed
 * @property {String} SET_PARAMETERIZED_STRUCTURES - tells the reducer to set the parameterized structures
 * @global
 */
export const actionType = Object.freeze({
  ADD_LAYER: "ADD_LAYER",
  DELETE_LAYER: "DELETE_LAYER",
  MOVE_LAYER_UP: "MOVE_LAYER_UP",
  MOVE_LAYER_DOWN: "MOVE_LAYER_DOWN",
  SELECT_LAYER: "SELECT_LAYER",
  UPSERT_LAYERS: "UPSERT_LAYERS",
  SELECT_USED_MATERIAL: "SELECT_USED_MATERIAL",
  APPLY_USED_MATERIAL_EDITING: "APPLY_USED_MATERIAL_EDITING",
  UPSERT_USED_MATERIALS: "UPSERT_USED_MATERIALS",
  DELETE_USED_MATERIAL: "DELETE_USED_MATERIAL",
  RESET_STRUCTURE: "RESET_STRUCTURE",
  PATCH_USED_MATERIAL: "PATCH_USED_MATERIAL",
  UPDATE_EDITING_LAYER_AND_REPLACE_USED_MATERIALS:
    "UPDATE_EDITING_LAYER_AND_REPLACE_USED_MATERIALS",
  SET_PARAMETERIZED_STRUCTURES: "SET_PARAMETERIZED_STRUCTURES",
  FETCH_FAMILY_STRUCTURES: "FETCH_FAMILY_STRUCTURES",
  SELECT_FAMILY_MEMBER: "SELECT_FAMILY_MEMBER",
  FETCH_FAMILY_MEMBER_DISCRETIZED: "FETCH_FAMILY_MEMBER_DISCRETIZED",
  CLEAR_FAMILY_MEMBER_DISCRETIZED: "CLEAR_FAMILY_MEMBER_DISCRETIZED"
});

/**
 * Action creator for the structure state, it does not need to be unit tested because it
 *  is already tested inside the Structure reducer's unit tests
 * @author Akira Kotsugai
 * consist of an instruction {@link StructureActionType} and some data.
 */
export default class StructureAction {
  static updateEditingLayerAndReplaceUsedMaterials = layer => ({
    type: actionType.UPDATE_EDITING_LAYER_AND_REPLACE_USED_MATERIALS,
    payload: layer
  });

  static upsertLayers = layers => ({
    type: actionType.UPSERT_LAYERS,
    payload: layers
  });

  static addLayer = (layer, newUsedMaterials, layersToUpdate) => ({
    type: actionType.ADD_LAYER,
    payload: { layer, newUsedMaterials, layersToUpdate }
  });

  static deleteLayer = (layerId, layersToUpdate) => ({
    type: actionType.DELETE_LAYER,
    payload: { layerId, layersToUpdate }
  });

  static selectLayer = layerId => ({
    type: actionType.SELECT_LAYER,
    payload: layerId
  });

  static selectUsedMaterial = materialId => ({
    type: actionType.SELECT_USED_MATERIAL,
    payload: materialId
  });

  static upsertUsedMaterials = (usedMaterials, reset) => ({
    type: actionType.UPSERT_USED_MATERIALS,
    payload: usedMaterials,
    reset
  });

  static deleteUsedMaterial = usedMaterialId => ({
    type: actionType.DELETE_USED_MATERIAL,
    payload: usedMaterialId
  });

  static reset = () => ({
    type: actionType.RESET_STRUCTURE,
    payload: {}
  });

  static patchUsedMaterial = usedMaterial => ({
    type: actionType.PATCH_USED_MATERIAL,
    payload: usedMaterial
  });

  static setParameterizedStructures = parameterizedStructures => ({
    type: actionType.SET_PARAMETERIZED_STRUCTURES,
    payload: parameterizedStructures
  });

  static fetchFamilyStructures = familyStructures => ({
    type: actionType.FETCH_FAMILY_STRUCTURES,
    payload: familyStructures
  });

  static selectFamilyMember = familyMemberId => ({
    type: actionType.SELECT_FAMILY_MEMBER,
    payload: familyMemberId
  });

  static fetchFamilyDiscretizedData = familyDiscretizedData => ({
    type: actionType.FETCH_FAMILY_MEMBER_DISCRETIZED,
    payload: familyDiscretizedData
  });

  static clearFamilyDiscretizedData = () => ({
    type: actionType.CLEAR_FAMILY_MEMBER_DISCRETIZED,
    payload: {}
  });
}
