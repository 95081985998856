import React, { PureComponent } from "react";
import MaterialTable from "components/MaterialTable/src/index";
import { isEqual } from "lodash";

/**
 * A component that adds more functionalities to the material-table table
 * such as a keyboard friendly navigation
 * @author Akira Kotsugai
 */
export class EnhancedMaterialTable extends PureComponent {
  constructor() {
    super();
    this.state = { rowsRefs: {}, selectedEntityId: -1, isEditing: false };
    this.tableRef = React.createRef();
  }

  /**
   * given the entity objects we create references to the rows as soon as the component mounts.
   * @param {Object} prevProps
   */
  componentDidMount() {
    if (this.props.setTableRef) {
      this.props.setTableRef(this.tableRef);
    }
    this.updateRowsRefs(
      this.props.data,
      this.focusOnSelectedRowIfShouldBeVisible
    );
  }

  focusOnSelectedRowIfShouldBeVisible() {
    if (this.props.selectedShouldBeVisible) {
      this.focusOnRow(this.getSelectedEntityId());
    }
  }

  getSelectedEntityId() {
    return this.props.selectedEntityId !== undefined
      ? this.props.selectedEntityId
      : this.state.selectedEntityId;
  }

  /**
   * everytime the entities change, we must make sure the table does not break if it is being edited,
   * update the references to the rows and focus on the selected entity if it changed
   * @param {Object} prevProps - the previous props
   * @param {Object} prevState - the previous state
   */
  componentDidUpdate(prevProps, prevState) {
    this.handleTableEditingState(prevProps);

    const { data } = this.props,
      prevEntityArray = prevProps.data,
      entitiesChanged = !isEqual(data, prevEntityArray);
    if (entitiesChanged) this.updateRowsRefs(data);

    const selectedEntityIsManagedByTheParent =
        this.props.selectedEntityId !== undefined,
      selectedEntityId = this.getSelectedEntityId(),
      prevSelectedEntityId = selectedEntityIsManagedByTheParent
        ? prevProps.selectedEntityId
        : prevState.selectedEntityId;
    this.handleChangeOfSelectedEntity(
      prevSelectedEntityId,
      selectedEntityId,
      entitiesChanged
    );

    const tableWasDisabled = !prevProps.disabled && this.props.disabled;
    if (tableWasDisabled) {
      this.focusOnSelectedRowIfShouldBeVisible();
    }
  }

  /**
   * because the table breaks everytime the component updates while being edited
   * we need to cancel the editing when that happens.
   * @param {Object} prevProps - the previous props
   */
  handleTableEditingState = prevProps => {
    const propsChanged = !isEqual(prevProps, this.props);

    if (propsChanged) {
      this.setState({ isEditing: false });
    }

    if (!this.state.isEditing) {
      this.tableRef.current &&
        this.tableRef.current.setState({ lastEditingRow: undefined });
    }
  };

  /**
   * here we focus on the newly selected entity
   * but when the entity rows change it means that a layer was added or deleted, so we do not focus
   * on the selected entity in this case because the focus should not be there, but where the user clicked.
   * @param {Number} prevSelectedEntityId - the previous selected entity id
   * @param {Number} selectedEntityId - the currently selected entity id
   * @param {Boolean} entitiesChanged - whether or not the entities changed
   */
  handleChangeOfSelectedEntity = (
    prevSelectedEntityId,
    selectedEntityId,
    entitiesChanged
  ) => {
    if (
      prevSelectedEntityId !== selectedEntityId &&
      selectedEntityId !== -1 &&
      !entitiesChanged
    ) {
      this.focusOnRow(selectedEntityId);
    }
  };

  focusOnRow(entityId) {
    const rowRef = this.state.rowsRefs[entityId];
    if (rowRef) {
      rowRef.current.focus();
    }
  }

  /**
   * it creates new refs to the rows
   * @param {Object[]} data
   */
  updateRowsRefs(data, callback) {
    let rowsRefs = {};
    data.forEach(entity => {
      rowsRefs[entity.id] = React.createRef();
    });
    this.setState({ rowsRefs }, callback);
  }

  /**
   * it calls the select entity callback if the selected item is managed by the parent
   * otherwise it selects the entity internally
   * @param {Number} entityId - the entity to select
   */
  selectEntity = entityId => {
    if (this.props.selectEntity) this.props.selectEntity(entityId);
    else this.setState({ selectedEntityId: entityId });
  };

  /**
   * it focus on the entity above or below the selected entity.
   * @param {String} arrow - the pressed arrow
   */
  handlePressedArrow = arrow => {
    const { data } = this.props,
      selectedEntityId = this.props.selectedEntityId
        ? this.props.selectedEntityId
        : this.state.selectedEntityId;
    const index = data.findIndex(entity => entity.id === selectedEntityId);
    let entityToFocusOnId;
    if (arrow === "ArrowUp" && index !== 0)
      entityToFocusOnId = data[index - 1]?.id;
    else if (arrow === "ArrowDown" && data[index + 1] !== undefined)
      entityToFocusOnId = data[index + 1].id;

    if (entityToFocusOnId !== undefined) {
      this.selectEntity(entityToFocusOnId);
    }
  };

  /**
   * @param {Object} event - the click event
   * @param {Object} rowData - the entity row data
   */
  handleRowClick = (event, rowData) => {
    this.selectEntity(rowData.id);
  };

  /**
   * it calls the row key down handler from the parent if any was given and calls the pressed
   * arrow handler if a vertical arrow was pressed and prevents the main page from scrolling
   * or clicks on the save button if the key pressed from an editing row was enter
   * @param {Object} event - the keydown event
   * @callback
   */
  handleRowKeyDown = event => {
    const { onRowKeyDown } = this.props,
      { key } = event;
    onRowKeyDown && onRowKeyDown(event);
    if (!this.state.isEditing && (key === "ArrowUp" || key === "ArrowDown")) {
      event.preventDefault();
      this.handlePressedArrow(key);
    } else if (this.state.isEditing && key === "Enter") {
      const saveButton = event.currentTarget.querySelector('[title="Save"]');
      if (saveButton) {
        saveButton.click();
      }
    } else if (key === "Enter") {
      event.preventDefault();
      const editButton = event.currentTarget.getElementsByTagName("button")[0];
      if (editButton) editButton.click();
    } else if (key == "Escape") {
      const cancelButton = event.currentTarget?.querySelector(
        '[title="Cancel"]'
      );
      if (cancelButton) {
        cancelButton.click();
      }
    }
  };

  /**
   * it starts editing the entity that was double clicked
   * @param {Object} event - the double click event
   * @callback
   */
  handleRowDoubleClick = event => {
    const editButton = event.currentTarget.getElementsByTagName("button")[0];
    if (editButton) {
      editButton.click();
    }
  };

  /**
   * it first selects the layer, waits 200ms to make sure the component was fully rerendered and clicks on the edit button
   * @param {Number} entityId - the entity id
   */
  selectAndEditEntity = entityId => {
    this.state.rowsRefs[entityId].current.click();
    setTimeout(
      () =>
        this.state.rowsRefs[entityId].current
          .getElementsByTagName("button")[0]
          .click(),
      200
    );
  };

  render() {
    const slimPadding = { padding: "0px 0px 0px 10px" },
      selectedEntityId = this.props.selectedEntityId
        ? this.props.selectedEntityId
        : this.state.selectedEntityId;
    return (
      <MaterialTable
        {...this.props}
        allowEditMode={rowData => {
          const tryingToEditTheSelectedEntity = rowData.id === selectedEntityId;
          if (!tryingToEditTheSelectedEntity) {
            this.selectAndEditEntity(rowData.id);
          }
          return tryingToEditTheSelectedEntity;
        }}
        onStartEditing={() => {
          this.setState({ isEditing: true });
        }}
        onStopEditing={approved => {
          this.setState({ isEditing: false, selectedEntityId: -1 });
          if (!approved) {
            this.props.onEditingCancelled && this.props.onEditingCancelled();
          }
        }}
        tableRef={this.tableRef}
        onRowClick={!this.props.disabled && this.handleRowClick}
        onRowKeyDown={this.handleRowKeyDown}
        onRowDoubleClick={
          !this.props.disableDoubleClick && this.handleRowDoubleClick
        }
        rowsRefs={this.state.rowsRefs}
        options={{
          ...this.props.options,
          rowStyle: rowData => {
            return {
              backgroundColor:
                rowData.id === selectedEntityId &&
                !this.props.disabled &&
                !this.props.disableSelectionHighlight
                  ? "#967cf9"
                  : (this.props.options.cellStyle &&
                      this.props.options.cellStyle.backgroundColor) ||
                    "#ffffff",
              opacity:
                rowData.id !== selectedEntityId && this.props.disabled ? 0.2 : 1
            };
          },
          actionsCellStyle: this.props.slim
            ? { ...this.props.options.actionsCellStyle, ...slimPadding }
            : this.props.options.actionsCellStyle
        }}
        columns={
          this.props.slim
            ? this.props.columns.map(column => {
                return {
                  ...column,
                  cellStyle: slimPadding,
                  headerStyle: slimPadding
                };
              })
            : this.props.columns
        }
      />
    );
  }
}

export default EnhancedMaterialTable;
