import React, { PureComponent } from "react";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import EditActions from "components/EditActions/EditActions";
import FFWFTargetForm from "./components/FFWFTargetForm/FFWFTargetForm";
import { isEqual } from "lodash";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import DirectionSnackbar from "components/Snackbar/Snackbar";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  title: {
    float: "left"
  }
});

/**
 * a component to show information about the selected ffwf target
 * @author Akira Kotsugai
 */
export class FFWFTarget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditingFFWFTarget: false,
      wavefront: undefined,
      snackbar: {
        message: "",
        visible: false
      }
    };
  }

  componentDidMount() {
    const { isEditing } = this.props;
    this.initialize();
    if (isEditing) this.editFFWFTarget();
  }

  /**
   * it supposely takes the formik submitter and assign it to this component, so that
   * the formik submitter can be accessed from outside the formik component.
   * @param {*} submitFormFunction - the formik function that submits the form
   * @callback
   */
  bindSubmitForm = submitFormFunction => {
    this.formSubmitter = submitFormFunction;
  };

  /**
   * it supposely takes the formik resetter and assign it to this component, so that
   * the formik resetter can be accessed from outside the formik component.
   * @param {*} resetFormFunction - the formik function that submits the form
   * @callback
   */
  bindResetForm = resetFormFunction => {
    this.formResetter = resetFormFunction;
  };

  /**
   * every time the active ffwf target changes, we restart the component state,
   * because a different ffwf target is active.
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { ffwfTarget } = this.props;
    if (!isEqual(prevProps.ffwfTarget, ffwfTarget)) {
      this.initialize();
    }
  }

  /**
   * when initializing the component, we should make sure that it is not on edit mode
   * unless we are creating a brand new ffwf target.
   */
  initialize() {
    const isCreatingNew = this.props.ffwfTarget === null;
    if (!isCreatingNew) {
      this.cancelEditingFFWFTarget();
      this.ensureThatWavefrontDataIsLoaded();
    }
  }

  /**
   * when we load all the ffwf targets they dont contain the wave_front
   * because it can be large data, so we opted to only load it on demand,
   * but it stores the wavefront internally if it is not supposed use redux
   */
  ensureThatWavefrontDataIsLoaded() {
    const { ffwfTarget, useWavefrontInternally } = this.props;
    if (useWavefrontInternally) {
      return DesignTargetApi.requestFFWFTargetWavefront(
        ffwfTarget.id
      ).then(wavefront => this.setState({ wavefront }));
    } else if (!ffwfTarget.wave_front) {
      this.props.loadWavefront(ffwfTarget.id);
    }
  }

  /**
   * it saves the editing FFWFTarget and leaves the edit mode. if there is no wavefront and it
   * is creation mode, it first asks for a confirmation.
   * @param {Object} formEditingValues - the form values.
   */
  save = (formEditingValues, onFinish) => {
    const commitChanges = () => {
        try {
          this.props.onSave(formEditingValues, onFinish).catch(e => {
            this.setState({
              snackbar: {
                visible: true,
                message: e || "Failed to save."
              }
            });
            onFinish();
          });
        } catch (e) {
          console.log(e);
        }
        this.cancelEditingFFWFTarget();
      },
      noWavefrontInserted = !formEditingValues.wavefrontFile,
      isCreateMode = this.props.ffwfTarget === null;

    if (isCreateMode && noWavefrontInserted) {
      this.setState({
        snackbar: {
          visible: true,
          message:
            "A default wavefront value is used. You can edit the wavefront by creating a new design or by importing one."
        }
      });
    }
    commitChanges();
  };

  /**
   * it changes the editing state to true
   */
  editFFWFTarget = () => {
    this.setState({
      isEditingFFWFTarget: true
    });
  };

  /**
   * it changes the editing state to false and calls the formik resetter
   */
  cancelEditingFFWFTarget = () => {
    this.setState({
      isEditingFFWFTarget: false
    });
    this.formResetter && this.formResetter();
  };

  render() {
    const { classes, ffwfTarget } = this.props,
      { isEditingFFWFTarget } = this.state,
      isCreatingNew = ffwfTarget === null;
    return (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Typography
                  className={classes.title}
                  variant="h5"
                  component="h3"
                >
                  {ffwfTarget ? ffwfTarget.name : "New"}
                </Typography>
                <div style={{ float: "right" }}>
                  <EditActions
                    isEditing={isEditingFFWFTarget || isCreatingNew}
                    isSaving={false}
                    onEdit={this.editFFWFTarget}
                    onCancel={
                      isEditingFFWFTarget && this.cancelEditingFFWFTarget
                    }
                    onSave={() => {
                      this.formSubmitter();
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {(!this.props.useWavefrontInternally ||
              (this.props.useWavefrontInternally &&
                this.state.wavefront !== undefined)) && (
              <FFWFTargetForm
                isEditing={isEditingFFWFTarget}
                ffwfTarget={ffwfTarget}
                bindSubmitForm={this.bindSubmitForm}
                bindResetForm={this.bindResetForm}
                onSubmit={this.save}
                wavefront={this.state.wavefront}
                rowLayout={this.props.rowLayout}
                sampleScripts={this.props.sampleScripts}
                requestWavefront={this.props.loadWavefront}
              />
            )}
          </Grid>
        </Grid>
        {this.state.snackbar.visible && (
          <DirectionSnackbar message={this.state.snackbar.message} />
        )}
      </Paper>
    );
  }
}

export default withStyles(styles)(FFWFTarget);
