import React, { PureComponent } from "react";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";
import {
  Button,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DesignSelector from "MetaComponent/selectors/Design";
import DesignApi from "MetaComponent/api/Design";
import DesignJobActions from "./components/DesignJobActions/DesignJobActions";
import DesignJobDropdown from "./components/DesignJobDropdown/DesignJobDropdown";
import JsonDialog from "components/JsonDialog/JsonDialog";
import debounce from "lodash.debounce";
import { isEqual } from "lodash";
import Helper from "MetaComponent/helper/Design";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import DesignAction from "MetaComponent/actions/Design";
import Spinner from "components/Spinner/Spinner";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import HelperUtils from "MetaCell/helper/HelperUtils";
import { RequestFabiractionDialog } from "./components/RequestFabricationDialog/RequestFabricationDialog";
import UserSelector from "BaseApp/selectors/User";
import IconTooltip from "components/IconTooltip/IconTooltip";
import NumberInput from "components/NumberInput/NumberInput";
import { MenuItem, Select } from "@material-ui/core";

export const styles = theme => ({
  buttonWrapper: {
    position: "relative",
    marginTop: 20
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 50
  },
  progress: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
    width: "100%"
  },
  right: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    textAlign: "center",
    padding: "0 30px",
    position: "fixed",
    right: 0,
    top: 150
  },
  buttonMargin: {
    marginBottom: "15px"
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "250"
    },
    [theme.breakpoints.up("md")]: {
      width: "500px"
    }
  },
  responsiveFlex: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row"
    }
  }
});

/**
 * A component created to be the content for Meta Cell component's design.
 * @author Akira Kotsugai
 * @param {Object} props - the props passed by parent components
 */
export class DesignCanvas extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showJsonDialog: false,
      jsonToShow: null,
      jobStatus: null,
      polling: true,
      currentDesignJob: null,
      message: "",
      disabledActions: "",
      loadingActions: [],
      showRequestFabricationDialog: false,
      snackbar: {
        message: "",
        visible: false
      },
      nrPoints: 100,
      exportFormat: "GDS",
      downloadMask: false, // if the user clicked on generate mask and didn't click away, download straight away
      mask: {
        status: "",
        message: "",
        createDate: ""
      },
      maskStatusFetched: false,
      radialMask: false
    };
  }

  /**
   *
   * handle the returned api error
   */
  handleError(error) {
    if (error.status === 403 || error.status === 400) {
      this.setState({
        message: error.status === 403 ? error.data.detail : error.data,
        polling: false
      });
    } else if (error.status === 503) {
      this.setState({ polling: false, message: null });
    }
  }

  /**
   * it sets the open page for this component as soon as the component mounts
   */
  componentDidMount() {
    this.props.setPage(MetaComponentPaths.DESIGN);
    this.disableExportButtons();
    this.getDesignJobProgress();
    const { designJobs, selectedJobId } = this.props;
    if (
      selectedJobId &&
      designJobs.byId[selectedJobId]?.status === "DONE" &&
      designJobs.byId[selectedJobId].mask_job !== undefined &&
      designJobs.byId[selectedJobId].mask_job !== null &&
      !this.state.mask.status
    ) {
      this.getMaskStatus();
    } else {
      this.setState({ disabledActions: "" });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { designJobs, selectedJobId } = this.props;
    if (!isEqual(prevProps.designJobs.allIds, designJobs.allIds))
      this.getDesignJobProgress();
    if (!isEqual(prevProps.selectedJobId, selectedJobId) && selectedJobId) {
      this.disableExportButtons();
      this.setState({ maskStatusFetched: false });
      if (designJobs.byId[selectedJobId].mask_job === null) {
        this.setState({
          disabledActions: "",
          loadingActions: [],
          mask: { status: "" }
        });
      }
    }
    if (
      selectedJobId &&
      designJobs.byId[selectedJobId]?.status === "DONE" &&
      designJobs.byId[selectedJobId].mask_job !== undefined &&
      designJobs.byId[selectedJobId].mask_job !== null &&
      !this.state.maskStatusFetched
    ) {
      this.setState({ disabledActions: "mask,map" });
      this.getMaskStatus();
      this.setState({ maskStatusFetched: true });
    }
  }

  disableExportButtons() {
    const { designJobs, selectedJobId } = this.props;
    if (
      selectedJobId &&
      (designJobs.byId[selectedJobId].status === "ERROR" ||
        designJobs.byId[selectedJobId].status === "FAILED" ||
        designJobs.byId[selectedJobId].status === "STOPPED")
    ) {
      const status = designJobs.byId[selectedJobId].status;
      this.setState({ disabledActions: `${status},report,mask,map` });
    } else {
      this.setState({ disabledActions: "mask,map" });
    }
  }

  componentWillUnmount() {
    this.getDesignJobProgressWithDelay.cancel();
    this.getMaskStatusWithDelay.cancel();
    this.getReportStatusWithDelay.cancel();
    this.getDesignMapStatusWithDelay.cancel();
    this.setState({
      downloadMask: false,
      mask: { status: "" },
      maskStatusFetched: false
    });
  }

  /**
   * it gets the design job status from the backend endpoint and depending on the response
   * it decides whether it should call itself again, but with delay for the next call.
   */
  getDesignJobProgress = async () => {
    const { polling } = this.state,
      { designJobs, getDesignJob, openMetaComponentId } = this.props;
    const designJob = Helper.getMostRecentJob(
      Object.values(designJobs.byId).filter(
        job => job.meta_component === openMetaComponentId
      )
    );
    if (designJob) {
      return DesignApi.getDesignJobProgress(designJob.id)
        .then(({ data }) => {
          if (designJob.progress !== data.progress) {
            this.setState(
              {
                polling: false
              },
              () => {
                this.setState({
                  polling: true
                });
              }
            );
          }
          this.setState({ currentDesignJob: data });
          if (
            data.status === "ERROR" ||
            data.status === "DONE" ||
            data.status === "STOPPED" ||
            data.status === "FAILED"
          ) {
            this.setState({ polling: false });
            getDesignJob(data.id);
          } else if (polling) {
            this.getDesignJobProgressWithDelay();
          }
          return Promise.resolve();
        })
        .catch(() => {
          this.setState({ polling: false });
        });
    } else {
      this.setState({ polling: false });
    }
  };

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getDesignJobProgressWithDelay = debounce(() => {
    this.getDesignJobProgress();
  }, 5000);

  /**
   * it opens the json dialog with errors and warnings of the selected design job
   */
  onShowResultsErrorsAndWarnings = () => {
    const { selectedJobId, designJobs } = this.props;
    this.setState({
      jsonToShow: {
        errors: designJobs.byId[selectedJobId].errors,
        warnings: designJobs.byId[selectedJobId].warnings
      }
    });
    this.showJsonDialog();
  };

  showResultMetrics = () => {
    const { selectedJobId, designJobs } = this.props;
    this.setState({
      jsonToShow: {
        metrics: designJobs.byId[selectedJobId].metrics || {}
      }
    });
    this.showJsonDialog();
  };

  showJsonDialog = () => {
    this.setState({ showJsonDialog: true });
  };

  hideJsonDialog = () => {
    this.setState({ showJsonDialog: false });
  };

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getMaskStatusWithDelay = debounce(() => {
    this.getMaskStatus();
  }, 10000);

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getReportStatusWithDelay = debounce(() => {
    this.getReportStatus();
  }, 5000);

  getMemberMapStatusWithDelay = debounce(() => {
    this.getMemberMapStatus();
  }, 5000);

  getDesignMapStatusWithDelay = debounce(() => {
    this.getDesignMapStatus();
  }, 5000);

  /**
   * helpers for handling mask and report generation status checks
   */
  handleApiStatus = (status, callback, errorMessage, blockMask) => {
    if (status === "QUEUED" || status === "RUNNING") {
      if (blockMask) {
        this.setState({
          disabledActions: "mask",
          loadingActions: ["mask"],
          mask: { status: status }
        });
      }
      return callback();
    }

    this.setState({ disabledActions: "", loadingActions: [] });
    if (status === "DONE") {
      return "";
    } else if (status === "ERROR") {
      return errorMessage;
    }
  };

  handleDownloadError = message => {
    this.setState({
      message: message,
      disabledActions: "",
      loadingActions: [],
      mask: { status: "ERROR", message: "" }
    });
  };

  /**
   * it keeps checking the status of a mask generation until it is finished
   */
  getMaskStatus() {
    const { selectedJobId } = this.props;
    return DesignApi.getDesignMaskStatus(selectedJobId)
      .then(resp => resp.data)
      .then(data => {
        const status = data.status;
        let error_message = "Failed to generate mask";
        if (
          data.errors &&
          typeof data.errors === "object" &&
          data.errors.exception
        ) {
          error_message = data.errors.exception;
        } else if (data.errors && typeof data.errors !== "object") {
          error_message = data.errors;
        }
        const result = this.handleApiStatus(
          status,
          this.getMaskStatusWithDelay,
          error_message,
          true
        );
        const createDate = new Date(data.file_created_at).toLocaleString();
        this.setState(prevState => {
          if (status === "DONE" && prevState.downloadMask) {
            this.getMask();
          }
          return {
            mask: { status: status, message: result, createDate: createDate }
          };
        });
      })
      .catch(() => this.handleDownloadError("Failed to generate mask"));
  }

  /**
   * it stops a running mask job
   * @param {Number} jobId - the design job id
   */
  stopMaskJob = designJobId => {
    this.getMaskStatusWithDelay.cancel();
    DesignApi.stopMaskJob(designJobId)
      .then(response => {
        this.getMaskStatus();
      })
      .catch(error => console.log("Design API: failed to stop mask job"));
  };

  /**
   * it keeps checking the status of a report generation until it is finished
   */
  getReportStatus() {
    const { selectedJobId } = this.props;
    return DesignApi.getDesignReportStatus(selectedJobId)
      .then(resp => resp.data.status)
      .then(status => {
        const result = this.handleApiStatus(
          status,
          this.getReportStatusWithDelay,
          "Failed to generate report"
        );
        if (result) {
          this.setState({ message: result });
        }
        if (status === "DONE") {
          return this.getReport();
        }
      })
      .catch(() => this.handleDownloadError("Failed to generate report"));
  }

  getMemberMapStatus() {
    const { selectedJobId } = this.props;
    return DesignApi.getDesignMemberMapStatus(selectedJobId)
      .then(resp => resp.data.status)
      .then(status => {
        if (status === "QUEUED" || status === "RUNNING") {
          return this.getMemberMapStatusWithDelay();
        } else if (status === "DONE") {
          return this.getMemberMap();
        }
      });
  }

  getDesignMapStatus() {
    const { selectedJobId } = this.props;
    return DesignApi.getDesignJobMaskProgress(selectedJobId)
      .then(resp => resp.data.status)
      .then(status => {
        if (status === "QUEUED" || status === "RUNNING") {
          return this.getDesignMapStatusWithDelay();
        } else if (status === "DONE") {
          return this.getDesignMap();
        } else if (status === "ERROR") {
          return this.handleDownloadError("Failed to generate the design map");
        }
      })
      .catch(() =>
        this.handleDownloadError("Failed to generate the design map")
      );
  }

  startMaskGeneration = () => {
    const { selectedJobId } = this.props;
    const exportFormat = this.state.exportFormat.toLowerCase();
    if (exportFormat === "json") {
      return this.startMemberMapGeneration();
    }
    this.setState({
      disabledActions: "mask,map",
      loadingActions: ["mask"],
      downloadMask: true
    });
    return DesignApi.startDesignMask(
      selectedJobId,
      this.state.nrPoints,
      this.state.radialMask,
      exportFormat
    ).then(() => this.getMaskStatusWithDelay());
  };

  startReportGeneration = () => {
    const { selectedJobId } = this.props;
    this.setState({ disabledActions: "report", loadingActions: ["report"] });
    return DesignApi.startDesignReport(selectedJobId).then(d =>
      this.getReportStatusWithDelay()
    );
  };

  startMemberMapGeneration = () => {
    const { selectedJobId } = this.props;
    this.setState({
      disabledActions: "mask,map",
      loadingActions: ["mask"]
    });
    return DesignApi.startMemberMapJob(selectedJobId).then(() =>
      this.getMemberMapStatusWithDelay()
    );
  };

  startDesignMapDownload = () => {
    // this outputs the same map, but without any additional
    // component data and in a csv format
    const { selectedJobId } = this.props;
    this.setState({
      disabledActions: "mask,map",
      loadingActions: ["map"]
    });
    return DesignApi.startDesignMapJob(
      selectedJobId,
      this.state.radialMask
    ).then(() => this.getDesignMapStatusWithDelay());
  };

  /**
   * it retrieves a mask gds file.
   */
  getMask = async () => {
    const { selectedJobId, metaComponents, openMetaComponentId } = this.props;
    DesignApi.getDesignMask(selectedJobId).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${metaComponents.byId[openMetaComponentId].name}_design_mask_export.zip`
      );
      link.click();
      window.URL.revokeObjectURL(url);
      this.setState({ disabledActions: "", loadingActions: [] });
    });
  };

  /**
   * it retrieves a report pdf file.
   */
  getReport = async () => {
    const { selectedJobId, metaComponents, openMetaComponentId } = this.props;
    DesignApi.getDesignReport(selectedJobId).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${metaComponents.byId[openMetaComponentId].name}_design_report.pdf`
      );
      link.click();
      window.URL.revokeObjectURL(url);
      this.setState({ disabledActions: "", loadingActions: [] });
    });
  };

  getMemberMap = async () => {
    const { selectedJobId, metaComponents, openMetaComponentId } = this.props;
    DesignApi.getDesignMemberMapJson(selectedJobId).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${metaComponents.byId[openMetaComponentId].name}_design_membermap.json.zip`
      );
      link.click();
      window.URL.revokeObjectURL(url);
      this.setState({ disabledActions: "", loadingActions: [] });
    });
  };

  /**
   * it retrieves pure design map in csv format
   */
  getDesignMap = async () => {
    const { selectedJobId, metaComponents, openMetaComponentId } = this.props;
    DesignApi.getDesignMapCSV(selectedJobId).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${metaComponents.byId[openMetaComponentId].name}_design_map.csv.zip`
      );
      link.click();
      window.URL.revokeObjectURL(url);
      this.setState({ disabledActions: "", loadingActions: [] });
    });
  };

  /**
   * @param {Object[]} targetsMissingData - design target objects
   * @param {String} missingDataLabel - name of field
   * @returns {Boolean}
   */
  handleTargetMissingData(targetsMissingData, missingDataLabel) {
    const targetNames = targetsMissingData.map(target => target.name);
    const multiple = targetsMissingData.length > 1;
    const message = `${HelperUtils.joinWords(targetNames, true)} ${
      multiple ? "don't" : "doesn't"
    } have a specified ${missingDataLabel}.`;
    this.setState({ message });
  }

  /**
   * checks whether there is sufficient wavefront data
   */
  async validate() {
    const { designTargets, selectedDesignTargets } = this.props;
    let targetsMissingWavefront = [];
    let targetsMissingSetPoint = [];
    for (const selectedDesignTarget of Object.values(
      selectedDesignTargets.byId
    )) {
      const designTarget =
        designTargets.byId[selectedDesignTarget.design_target];
      if (!designTarget) {
        continue;
      }
      const doesntHaveFFWF = !designTarget?.FFWFTarget;
      if (doesntHaveFFWF) {
        // we get a fresh design target because its wavefront may have changed
        let nfwf = null;
        nfwf = await DesignTargetApi.requestNFWFTargetWavefrontShape(
          designTarget.id
        );
        if (!nfwf) {
          targetsMissingWavefront.push(designTarget);
        }
      }
      if (!selectedDesignTarget.set_point) {
        targetsMissingSetPoint.push(designTarget);
      }
    }

    if (targetsMissingWavefront.length) {
      this.handleTargetMissingData(targetsMissingWavefront, "wavefront");
      return false;
    }
    if (targetsMissingSetPoint.length) {
      this.handleTargetMissingData(targetsMissingSetPoint, "set point");
      return false;
    }
    this.setState({ message: "" });
    return true;
  }

  /**
   * start the design phase for the meta component
   */
  startDesign = async () => {
    const valid = await this.validate();
    if (valid) {
      const { openMetaComponentId, startDesign } = this.props;
      startDesign(openMetaComponentId, this.handleError.bind(this));
      this.setState({ polling: true, currentDesignJob: null, message: "" });
    }
  };

  /**
   * @returns {Object} the open meta component entity
   */
  getOpenMetaComponent = () => {
    const { openMetaComponentId, metaComponents } = this.props;
    return metaComponents.byId[openMetaComponentId];
  };

  onShowNewJobDialog = () => {
    const { showConfirmDialog } = this.props;

    const title = "New design job";
    const message = `Are you sure you want to start a new design job?`;
    const confirmAction = this.startDesign;
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  onShowRequestFabricationDialog = () => {
    this.setState({
      showRequestFabricationDialog: true
    });
  };

  closeRequestFabricationDialog = () => {
    // possibly reset fields
    this.setState({
      showRequestFabricationDialog: false
    });
  };

  getJobsList = designJobs => {
    const { openMetaComponentId } = this.props;
    return Object.values(designJobs.byId).filter(
      job =>
        job.meta_component === openMetaComponentId &&
        (job.status === "ERROR" ||
          job.status === "DONE" ||
          job.status === "STOPPED" ||
          job.status === "FAILED")
    );
  };

  /**
   * it stops a running design job
   * @param {Number} jobId - the job id
   */
  stopDesignJob = jobId => {
    DesignApi.stopDesignJob(jobId)
      .then(response => {
        this.getDesignJobProgress();
      })
      .catch(error => console.log("Design API: failed to stop design job"));
  };

  setSnackbar = message => {
    this.setState({
      snackbar: {
        message,
        visible: true
      }
    });
  };

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        visible: false
      }
    });
  };

  onGDSPointsChange = value => {
    this.setState({
      nrPoints: value
    });
  };

  onFormatSelect = e => {
    this.setState({
      exportFormat: e.target.value
    });
  };

  getExportFormatOptions = () => {
    return ["GDS", "OAS", "STL", "DXF", "JSON"];
  };

  render() {
    const { classes, designJobs, user, selectedJobId } = this.props;
    const {
      jsonToShow,
      showJsonDialog,
      polling,
      currentDesignJob,
      message,
      disabledActions,
      loadingActions,
      showRequestFabricationDialog,
      nrPoints,
      exportFormat,
      mask
    } = this.state;
    const jobsList = this.getJobsList(designJobs);
    const openMetaComponent = this.getOpenMetaComponent();
    const designJobStatus = ["FAILED", "ERROR", "STOPPED"];
    const btnAction = designJobStatus.filter(status =>
      disabledActions.includes(status)
    );

    const renderDesignJobProgress = () => (
      <div>{selectedJobId && designJobs.byId[selectedJobId].status}</div>
    );

    const FormRow = ({ label, tooltip, children }) => (
      <Grid
        item
        xs={12}
        justifyContent="space-between"
        className={classes.responsiveFlex}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Grid item xs={6}>
          <FormLabel style={{ fontSize: 12, marginRight: "30px" }}>
            {label} {tooltip && <IconTooltip text={tooltip} />}
          </FormLabel>
        </Grid>
        <Grid item xs={6}>
          {children}
        </Grid>
      </Grid>
    );

    const actionButton = (name, onClick, disabled, loading, testName) => (
      <Button
        test-data={testName ? `${testName.toLowerCase()}Button` : ""}
        name={name}
        className={classes.buttonMargin}
        variant="contained"
        onClick={onClick}
        disabled={disabled}
      >
        {loading && <Spinner className={classes.circularProgress} size={20} />}
        {name}
      </Button>
    );

    const actions = [
      {
        name: "Mask",
        label: "Generate new mask",
        handler: this.startMaskGeneration
      },
      { name: "Report", label: "Report", handler: this.startReportGeneration },
      {
        name: "Map",
        label: "Download map",
        handler: this.startDesignMapDownload
      }
    ];

    return (
      <div className={classes.wrapper}>
        {polling && (
          <div className={classes.progress}>
            {currentDesignJob && (
              <Typography>Design status: {currentDesignJob.status}</Typography>
            )}

            <div className={classes.wrapper}>
              <Spinner name="Waiting" size={68} timeout={180000} />
            </div>
            <div className={classes.wrapper}>
              {currentDesignJob && (
                <Typography>
                  {currentDesignJob.progress && currentDesignJob.progress[0]} %
                </Typography>
              )}
            </div>
            <br />
            {currentDesignJob && (
              <LinearProgress
                name="SimulationProgress"
                variant="determinate"
                value={
                  currentDesignJob.progress && currentDesignJob.progress[0]
                }
              />
            )}
            <br />
            {currentDesignJob && polling && (
              <div>
                <Typography>{currentDesignJob.progress_message}</Typography>
              </div>
            )}
            {currentDesignJob && polling && (
              <div
                className={classes.buttonWrapper}
                style={{ textAlign: "center" }}
              >
                <Button
                  test-data="stopBtn"
                  name="StopDesignButton"
                  className={classes.buttonMargin}
                  variant="contained"
                  onClick={this.stopDesignJob.bind(this, currentDesignJob.id)}
                >
                  Stop Design
                </Button>
              </div>
            )}
          </div>
        )}

        {message && (
          <DirectionSnackbar
            message={message}
            onCloseCallback={() => this.setState({ message: "" })}
          />
        )}

        {!polling && (
          <div>
            {designJobs.loaded && jobsList.length === 0 && (
              <div className={classes.buttonWrapper}>
                <Button
                  name="StartDesign"
                  variant="contained"
                  color="primary"
                  disabled={polling}
                  onClick={this.startDesign}
                >
                  Start Design
                </Button>
              </div>
            )}

            {designJobs.loaded && jobsList.length > 0 && (
              <div style={{ width: "100%" }}>
                {designJobs.byId[selectedJobId]?.status === "DONE" ? (
                  <Grid
                    container
                    spacing={8}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      {renderDesignJobProgress()}
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Typography variant="h6">Design Export</Typography>
                        <Grid
                          container
                          spacing={4}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormRow
                            label="Max Number of GDS Points"
                            tooltip="Set the desired maximal number of points for each structure cross-section to define non-straight lines within the Mask export in GDS format."
                          >
                            <NumberInput
                              inputProps={{
                                style: {
                                  textAlign: "left",
                                  minWidth: 100,
                                  marginLeft: "10px"
                                }
                              }}
                              onChange={event =>
                                this.onGDSPointsChange(event.target.value)
                              }
                              value={nrPoints}
                              autoFocus
                            />
                          </FormRow>

                          <FormRow label="Export format">
                            <Select
                              value={exportFormat || "GDS"}
                              inputProps={{ name: "FormatOptions" }}
                              onChange={this.onFormatSelect}
                              style={{ width: 200, paddingLeft: "10px" }}
                            >
                              {this.getExportFormatOptions().map(
                                (format, index) => (
                                  <MenuItem key={index} value={format}>
                                    {format}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormRow>

                          <FormRow label="Apply radial mask">
                            <Checkbox
                              onClick={() =>
                                this.setState({
                                  radialMask: !this.state.radialMask
                                })
                              }
                              checked={this.state.radialMask}
                              color="primary"
                            />
                          </FormRow>

                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", gap: "16px" }}
                            className={classes.responsiveFlex}
                          >
                            {btnAction.length > 0
                              ? actions.map(({ name, label, handler }) => (
                                  <Tooltip
                                    key={name}
                                    title={`The design has been ${btnAction}. Please rerun the design.`}
                                  >
                                    <span className={classes.span}>
                                      {actionButton(
                                        label,
                                        handler,
                                        disabledActions.includes(
                                          name.toLowerCase()
                                        ),
                                        loadingActions.includes(
                                          name.toLowerCase()
                                        ),
                                        name.toLowerCase()
                                      )}
                                    </span>
                                  </Tooltip>
                                ))
                              : actions.map(({ name, label, handler }) =>
                                  actionButton(
                                    label,
                                    handler,
                                    disabledActions.includes(
                                      name.toLowerCase()
                                    ),
                                    loadingActions.includes(name.toLowerCase()),
                                    name.toLowerCase()
                                  )
                                )}
                          </Grid>

                          {mask.status === "DONE" &&
                            !loadingActions.includes("mask") && (
                              <Grid item xs={12} style={{ paddingTop: "0px" }}>
                                {actionButton(
                                  "Download mask",
                                  () => {
                                    this.setState({
                                      disabledActions: "maskDownload",
                                      loadingActions: ["maskDownload"]
                                    });
                                    this.getMask();
                                  },
                                  disabledActions.includes("maskDownload"),
                                  loadingActions.includes("maskDownload"),
                                  "maskdownload"
                                )}
                                <Grid item xs={12}>
                                  {mask.createDate ? (
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      Download the mask generated on{" "}
                                      {mask.createDate}.
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      Download previously generated mask.
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingBottom: "10px" }}
                                >
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    Generated mask is stored for up to a week,
                                    generating a new mask will overwrite the
                                    existing mask.
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}

                          {mask.status === "ERROR" &&
                            !loadingActions.includes("mask") && (
                              <Grid item xs={12}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    An error occurred while generating the mask.
                                    Please try again.
                                  </Typography>
                                </Grid>
                                <Typography variant="caption" color="error">
                                  {mask.message}
                                </Typography>
                              </Grid>
                            )}

                          {(mask.status === "RUNNING" ||
                            mask.status === "QUEUED" ||
                            loadingActions.includes("mask")) && (
                            <Grid item xs={12} style={{ paddingTop: "0px" }}>
                              <Grid item xs={12}>
                                {actionButton(
                                  "Stop mask generation",
                                  () => {
                                    this.stopMaskJob(currentDesignJob.id);
                                  },
                                  false,
                                  false,
                                  "maskstop"
                                )}
                              </Grid>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                The mask is being generated.{" "}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                A download button will appear as soon as the
                                generation has been completed.
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                ) : (
                  renderDesignJobProgress()
                )}

                <div className={classes.right}>
                  <DesignJobDropdown designJobs={jobsList} />
                  <DesignJobActions
                    disabledActions={disabledActions.split(",")}
                    loadingActions={loadingActions}
                    showResultsErrorsAndWarnings={
                      this.onShowResultsErrorsAndWarnings
                    }
                    showNewJobDialog={this.onShowNewJobDialog}
                    allowRequestFabrication={openMetaComponent.family_is_pdk}
                    onRequestFabricationDialog={
                      this.onShowRequestFabricationDialog
                    }
                    selectedJobId={selectedJobId}
                    designJobs={designJobs}
                    showResultMetrics={this.showResultMetrics}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <JsonDialog
          open={showJsonDialog}
          data={jsonToShow}
          onClose={this.hideJsonDialog}
        />

        <RequestFabiractionDialog
          open={showRequestFabricationDialog}
          onClose={this.closeRequestFabricationDialog}
          user={user}
          design_job={currentDesignJob}
          setSnackbar={this.setSnackbar}
          resetSnackbar={this.resetSnackbar}
        />
        {this.state.snackbar.visible ? (
          <DirectionSnackbar
            persistent={true}
            message={this.state.snackbar.message}
            anchor={{ vertical: "center", horizontal: "center" }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
    designJobs: DesignSelector.getDesignJobs(state),
    selectedJobId: DesignSelector.getSelectedJobId(state),
    designTargets: DesignTargetSelector.getDesignTargets(state),
    selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
      state
    ),
    user: UserSelector.getUser(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDesignJobs: openMetaComponentId =>
      dispatch(DesignApi.getDesignJobs(openMetaComponentId)),
    getDesignJob: jobId => dispatch(DesignApi.getDesignJob(jobId)),
    startDesign: (metaComponentId, errorHandler) =>
      dispatch(DesignApi.run(metaComponentId, errorHandler)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    resetJobs: () => dispatch(DesignAction.resetJobs())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DesignCanvas));
