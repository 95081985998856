import React, { PureComponent } from "react";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";
import {
  Typography,
  LinearProgress,
  TextField,
  Paper,
  Grid,
  Button,
  MenuItem,
  FormControl,
  FormLabel
} from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DesignSelector from "MetaComponent/selectors/Design";
import AnalysisApi from "MetaComponent/api/Analysis";
import ODAApi from "MetaComponent/api/ODA";
import JobActions from "components/JobActions/JobActions";
import AnalysisJobResult from "./components/AnalysisJobResult/AnalysisJobResult";
import DesignJobDropdown from "MetaComponent/containers/DesignCanvas/components/DesignJobDropdown/DesignJobDropdown";
import AnalysisJobDropdown from "./components/AnalysisJobDropdown/AnalysisJobDropdown";
import debounce from "lodash.debounce";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import SetPointForm from "MetaComponent/containers/TargetCanvas/components/DesignTargetDetails/components/SetPoint/components/SetPointForm/SetPointForm";
import AnalysisVariables from "./components/AnalysisVariables/AnalysisVariables";
import AnalysisDetails from "./components/AnalysisDetails/AnalysisDetails";
import AnalysisSelector from "MetaComponent/selectors/Analysis";
import AnalysisSettings from "./components/AnalysisSettings/AnalysisSettings";
import AnalysisAction from "MetaComponent/actions/Analysis";
import SetPointSelector from "MetaComponent/selectors/SetPoint";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import FFWFTargetSelector from "MetaComponent/selectors/FFWTTarget";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import DesignApi from "MetaComponent/api/Design";
import DesignAction from "MetaComponent/actions/Design";
import JsonDialog from "components/JsonDialog/JsonDialog";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Spinner from "components/Spinner/Spinner";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import FamilyApi from "MetaCell/api/Family";

export const styles = theme => ({
  buttonWrapper: {
    position: "relative",
    marginTop: 20
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 50
  },
  progress: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
    width: "100%"
  },
  left: {
    paddingRight: 260
  },
  right: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    textAlign: "center",
    padding: "0 30px",
    position: "fixed",
    right: 0,
    top: 150
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  nameInput: {
    marginLeft: 10
  }
});

const AnalysisJobSubmissionMode = Object.freeze({
  CREATE: "create",
  UPDATE: "update"
});

/**
 * A component created to be the content for Meta component's analysis.
 * @author Akira Kotsugai
 * @param {Object} props - the props passed by parent components
 */
export class AnalysisCanvas extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      jsonToShow: null,
      polling: true,
      setPointSubmissionIsReady: false,
      settingsSubmissionIsReady: false,
      outgoingConditionsIsReady: false,
      submissionMode: null,
      analysisName: this.getDefaultAnalysisName(),
      nfwf_type: null,
      selected_oda: null,
      defaultSetPoint: null,
      defaultSettings: null,
      exporting: false,
      message: null,
      designOdaJobs: [],
      selected_oda_details: null
    };
    this.setPointFormSubmitter = null;
    this.analysisSettingsFormSubmitter = null;
  }

  /**
   * takes the submitter and binds to this component to allow triggering the submission
   * from this component
   * @param {*} formSubmitter - the formik submitter
   * @callback
   */
  bindSetPointFormSubmitter = formSubmitter => {
    this.setPointFormSubmitter = formSubmitter;
  };

  /**
   * * takes the submitter and binds to this component to allow triggering the submission
   * from this component
   * @param {*} formSubmitter
   */
  bindAnalysisSettingsFormSubmitter = formSubmitter => {
    this.analysisSettingsFormSubmitter = formSubmitter;
  };

  /**
   * * takes the submitter and binds to this component to allow triggering the submission
   * from this component
   * @param {*} formSubmitter
   */
  bindAnalysisOutgoingConditionsFormSubmitter = formSubmitter => {
    this.analysisOutgoingConditionsFormSubmitter = formSubmitter;
  };

  /**
   * it calls the form submitters to trigger their validations individually
   * @param {String} - the submission mode
   */
  validateForms = submissionMode => {
    this.setState({ submissionMode });
    this.setPointFormSubmitter();
    this.analysisSettingsFormSubmitter();
    this.analysisOutgoingConditionsFormSubmitter();
  };

  /**
   * it sets the open page for this component as soon as the component mounts and triggers
   * the status poll
   */
  componentDidMount() {
    this.props.setPage(MetaComponentPaths.ANALYSIS);
    this.init();
  }

  /**
   * it updates the analysis name in the component state and triggers the progress pool
   * when the selected analysis job changes.
   * @param {Object} prevProps - the previous props
   * @param {Object} prevState - the previous state
   * @param {*} snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedAnalysisJobId, selectedDesignJobId } = this.props;
    this.submitAnalysisIfReady(prevState);
    const analysisJobChanged =
        prevProps.selectedAnalysisJobId !== selectedAnalysisJobId,
      designJobChanged = prevProps.selectedDesignJobId !== selectedDesignJobId;
    if (analysisJobChanged || designJobChanged) {
      this.init();
    }
  }

  /**
   * it sets the correct analysis name on the state if there is an analysis selected
   * and gets the job progress
   */
  async init() {
    const { analysisJobs, selectedAnalysisJobId } = this.props;
    const defaultSetPoint = await this.getDefaultSetPoint();
    const defaultSettings = await this.getDefaultSettings();
    const designComponent = await this.getDesignComponent();
    await this.getDesignODAJobList();
    const { designOdaJobs } = this.state;
    this.setState(
      {
        analysisName: selectedAnalysisJobId
          ? analysisJobs.byId[selectedAnalysisJobId].name
          : this.getDefaultAnalysisName(),
        nfwf_type: selectedAnalysisJobId
          ? analysisJobs.byId[selectedAnalysisJobId].nfwf_type
          : "LPA",
        selected_oda: selectedAnalysisJobId
          ? analysisJobs.byId[selectedAnalysisJobId].selected_oda
          : null,
        selected_oda_details:
          selectedAnalysisJobId &&
          analysisJobs.byId[selectedAnalysisJobId].selected_oda
            ? designOdaJobs.find(
                odaJob =>
                  odaJob.id ==
                  analysisJobs.byId[selectedAnalysisJobId].selected_oda
              )
            : null,
        polling: selectedAnalysisJobId !== null,
        defaultSetPoint,
        defaultSettings,
        designComponent
      },
      () => this.getAnalysisJobProgress()
    );
  }

  /**
   * checks the ready state in order to trigger a submission to create or update an analysis job.
   * @param {*} prevState
   */
  submitAnalysisIfReady(prevState) {
    const {
      setPointSubmissionIsReady,
      settingsSubmissionIsReady,
      outgoingConditionsIsReady,
      submissionMode
    } = this.state;
    if (
      prevState.setPointSubmissionIsReady !== setPointSubmissionIsReady ||
      prevState.settingsSubmissionIsReady !== settingsSubmissionIsReady ||
      prevState.outgoingConditionsIsReady !== outgoingConditionsIsReady
    ) {
      if (
        setPointSubmissionIsReady &&
        settingsSubmissionIsReady &&
        outgoingConditionsIsReady
      ) {
        this.setState(
          {
            setPointSubmissionIsReady: false,
            settingsSubmissionIsReady: false,
            outgoingConditionsIsReady: false,
            polling: true
          },
          () => {
            if (submissionMode === AnalysisJobSubmissionMode.CREATE) {
              this.createDesignAnalysis();
            } else if (submissionMode === AnalysisJobSubmissionMode.UPDATE) {
              this.updateDesignAnalysis();
            }
          }
        );
      }
    }
  }

  componentWillUnmount() {
    this.getAnalysisJobProgressWithDelay.cancel();
    this.getExportStatusWithDelay.cancel();
  }

  /**
   * it gets the analysis job status from the backend endpoint and depending on the response
   * it decides whether it should call itself again, but with delay for the next call.
   */
  async getAnalysisJobProgress() {
    const { polling } = this.state,
      {
        selectedAnalysisJobId,
        getAnalysisJobProgressAction,
        analysisJobs,
        openMetaComponentId,
        metaComponents
      } = this.props;
    const openMetaComponent = metaComponents.byId[openMetaComponentId];
    if (selectedAnalysisJobId) {
      try {
        const selectedAnalysisJob = selectedAnalysisJobId
          ? analysisJobs.byId[selectedAnalysisJobId]
          : null;
        const oldProgress = selectedAnalysisJob?.progress;
        const data = await getAnalysisJobProgressAction(selectedAnalysisJobId);
        if (oldProgress !== data.progress) {
          this.setState(
            {
              polling: false
            },
            () => {
              this.setState({
                polling: true
              });
            }
          );
        }
        if (
          data.status === "ERROR" ||
          data.status === "DONE" ||
          data.status === "STOPPED" ||
          data.status === "FAILED"
        ) {
          this.setState({ polling: false });
          if (data.status == "ERROR" && openMetaComponent.family_is_pdk) {
            const pdk_response = await FamilyApi.getFamilyPdkPreferences(
              openMetaComponent.family
            );
            const pdk_preferences = pdk_response.data;

            this.setState({
              message: `PDK analysis failed, you can find more information in the Errors/Warning Tab. For detailed information on allowed setpoints for this PDK please checkout ${pdk_preferences.instructions_link} link.`
            });
          } else if (data.status == "DONE" && data.warnings?.length) {
            this.setState({
              message: data.warnings.join()
            });
          }
        } else if (polling) {
          this.getAnalysisJobProgressWithDelay();
        }
      } catch (exception) {
        console.log(exception);
        if (polling) {
          this.setState({ polling: false });
        }
      }
    } else {
      this.setState({ polling: false });
    }
  }

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getAnalysisJobProgressWithDelay = debounce(() => {
    this.getAnalysisJobProgress();
  }, 5000);

  /**
   * starts the design analysis and selects the created analysis immediately
   */
  createDesignAnalysis = async () => {
    const { createAnalysis, selectAnalysisJob } = this.props;
    try {
      const id = await createAnalysis(this.getDataToSubmit());
      selectAnalysisJob(id);
      this.setState({
        message: null
      });
    } catch (e) {
      this.handlePermissionError(e.response);
    }
    return;
  };

  handlePermissionError(response) {
    if (response.status === 403) {
      this.setState({ polling: false, message: null }, () =>
        this.setState({ message: response.data.detail })
      );
    } else if (response.status === 503) {
      this.setState({ polling: false, message: null });
    }
  }

  updateDesignAnalysis = async () => {
    const { updateAnalysis, selectedAnalysisJobId } = this.props;
    try {
      await updateAnalysis(selectedAnalysisJobId, this.getDataToSubmit());
      this.setState(
        {
          message: null
        },
        this.getAnalysisJobProgress
      );
    } catch (e) {
      this.handlePermissionError(e.response);
    }
    return;
  };

  /**
   * @returns {Object} - the temp state values
   */
  getDataToSubmit() {
    const { selectedDesignJobId } = this.props,
      { analysisName, nfwf_type, dataToSubmit, selected_oda } = this.state;
    return {
      ...dataToSubmit,
      swept_variables: this.getTempVariables(),
      design_job: selectedDesignJobId,
      name: analysisName ? analysisName : this.getDefaultAnalysisName(),
      nfwf_type: nfwf_type,
      selected_oda: selected_oda
    };
  }

  /**
   * it opens a confirm dialog of which the action creates an analysis job
   */
  onShowNewJobDialog = () => {
    const { showConfirmDialog } = this.props;
    const title = "New Analysis";
    const message = `Are you sure you want to create a new design analysis?`;
    const confirmAction = () =>
      this.validateForms(AnalysisJobSubmissionMode.CREATE);
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  /**
   * it opens a confirm dialog of which the action updates the selected analysis job
   */
  onShowUpdateJobDialog = () => {
    const { showConfirmDialog } = this.props;
    const title = "Update Analysis";
    const message = `Are you sure you want to rerun the analysis with the actual parameters?`;
    const confirmAction = () =>
      this.validateForms(AnalysisJobSubmissionMode.UPDATE);
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  showResultMetrics = () => {
    const { selectedAnalysisJobId, analysisJobs } = this.props;
    this.setState({
      jsonToShow: {
        metrics: analysisJobs.byId[selectedAnalysisJobId]?.metrics || {}
      }
    });
  };

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getExportStatusWithDelay = debounce(() => {
    this.getExportStatus();
  }, 5000);

  /**
   * it keeps checking the status of a mask generation until it is finished
   */
  getExportStatus() {
    const { selectedAnalysisJobId } = this.props;
    return AnalysisApi.getAnalysisExportStatus(selectedAnalysisJobId)
      .then(resp => resp.data.status)
      .then(status => {
        if (status === "QUEUED" || status === "RUNNING") {
          return this.getExportStatusWithDelay();
        } else if (status === "DONE") {
          return this.exportAnalysis();
        } else if (status === "ERROR") {
          this.setState({
            message: "Failed to export analysis"
          });
          this.setState({ disabledActions: "", loadingActions: [] });
        }
      })
      .catch(e => {
        this.setState({
          message: "Failed to export analysis"
        });
        this.setState({ disabledActions: "", loadingActions: [] });
      })
      .catch(e => {
        this.setState({
          message: "Failed to export analysis"
        });
        this.setState({ disabledActions: "", loadingActions: [] });
      });
  }

  startAnalysisExport = () => {
    const { selectedAnalysisJobId } = this.props;
    this.setState({
      disabledActions: "exportAnalysis",
      loadingActions: ["exportAnalysis"],
      exporting: true
    });
    return AnalysisApi.startAnalysisExport(selectedAnalysisJobId).then(() =>
      this.getExportStatusWithDelay()
    );
  };

  /**
   * it exports a zipped file containing the raw data of the selected analysis.
   */
  exportAnalysis = async () => {
    const { selectedAnalysisJobId, analysisJobs } = this.props;
    const analysisJob = analysisJobs.byId[selectedAnalysisJobId];
    AnalysisApi.exportAnalysis(selectedAnalysisJobId)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${analysisJob.name}.data.zip`);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error))
      .finally(() => this.setState({ exporting: false }));
  };

  getDesignJobsList = designJobs => {
    const { openMetaComponentId } = this.props;
    return Object.values(designJobs.byId).filter(
      job =>
        job.meta_component === openMetaComponentId &&
        (job.status === "ERROR" || job.status === "DONE")
    );
  };

  /**
   * @param {Object} analysisJobs - the analysis jobs state
   * @returns {Object[]} the list of analysis jobs that belong to the selected design job
   */
  getAnalysisJobsList = analysisJobs => {
    const { selectedDesignJobId } = this.props;
    return Object.values(analysisJobs.byId).filter(
      job => job.design_job === selectedDesignJobId
    );
  };

  /**
   * @returns {Object[]} - the analysis swept variables in a shape that the swept inputs can find
   */
  getTempVariables() {
    return this.props.tempVariables.map(tempVariable => ({
      ...tempVariable,
      variableName: tempVariable.name,
      simulation: -1 // since sweep inputs requires a simulation id, -1 means that there is no simulationId
    }));
  }

  /**
   * it updates the data analysis job data for submission and tells the state that the settings
   * are ready for submission.
   * @param {*} analysisSettingsData
   */
  setAnalysisSettingsReady = analysisSettingsData => {
    this.setState({
      dataToSubmit: { ...this.state.dataToSubmit, ...analysisSettingsData },
      settingsSubmissionIsReady: true
    });
  };

  /**
   * it updates the data analysis job data for submission and tells the state that the outgoing conditions
   * are ready for submission.
   * @param {*} outgoingConditionsData
   */
  setAnalysisOutgoingConditionsReady = outgoingConditionsData => {
    this.setState({
      dataToSubmit: { ...this.state.dataToSubmit, ...outgoingConditionsData },
      outgoingConditionsIsReady: true
    });
  };

  /**
   * it updates the data analysis job data for submission and tells the state that the set point
   * is ready for submission.
   * @param {*} setPointData
   */
  setSetPointReady = setPointData => {
    const { incident_light, diffractive_order, unit } = setPointData;
    this.setState({
      dataToSubmit: {
        ...this.state.dataToSubmit,
        incident_light,
        diffractive_order,
        set_point_unit: unit
      },
      setPointSubmissionIsReady: true
    });
  };

  getDefaultAnalysisName() {
    const { selectedDesignJobId, analysisJobs } = this.props;
    const designAnalysisJobs = Object.values(analysisJobs.byId).filter(
      analysisJob => analysisJob.design_job === selectedDesignJobId
    );
    return `Analysis ${designAnalysisJobs.length + 1}`;
  }

  /**
   * @param {Object} analysisJob
   * @returns {Object} only fields that are related to set point
   */
  getSetPoint(analysisJob) {
    const { diffractive_order, incident_light, set_point_unit } = analysisJob;
    return {
      diffractive_order,
      incident_light,
      unit: set_point_unit
    };
  }

  /**
   * @param {Object} analysisJob
   * @returns {Object} only fields that are related to analysis settings
   */
  getSettings(analysisJob) {
    let {
      selected_wave_front,
      width,
      height,
      rows_count,
      columns_count,
      center_coord,
      euler_angles,
      ff_target_unit,
      angular_center_angles,
      opening_angles,
      angular_unit,
      ff_prop_type,
      ff_zero_padding,
      ff_bandlimit
    } = analysisJob;
    if (angular_center_angles && angular_center_angles[0] === null) {
      angular_center_angles[0] = 0;
    }
    if (angular_center_angles && angular_center_angles[1] === null) {
      angular_center_angles[1] = 0;
    }
    if (opening_angles && opening_angles[0] === null) {
      opening_angles[0] = 180;
    }
    if (opening_angles && opening_angles[1] === null) {
      opening_angles[1] = 180;
    }
    return {
      selected_wave_front,
      width,
      height,
      rows_count,
      columns_count,
      center_coord,
      euler_angles,
      ff_target_unit,
      angular_center_angles,
      opening_angles,
      angular_unit,
      ff_prop_type,
      ff_zero_padding,
      ff_bandlimit
    };
  }

  /**
   * @param {Object} analysisJob
   * @returns {Object} only fields that are related to analysis outgoing conditions
   */
  getOutgoingConditions(analysisJob) {
    const { direction_out, order_out, polarization_out } = analysisJob;
    return {
      direction_out,
      order_out,
      polarization_out
    };
  }

  /**
   * @returns {Object} the first set point used in the design
   */
  async getDefaultSetPoint() {
    const { selectedDesignJobId } = this.props;
    if (selectedDesignJobId) {
      const targets = await DesignApi.getDesignJobTargets(selectedDesignJobId);
      const setPoint = targets.set_points[0];
      const {
          incident_light_type,
          incident_light,
          diffractive_order,
          unit
        } = setPoint,
        {
          azimut,
          zenit,
          wavelength,
          amplitude,
          polarization,
          focal_spot_x,
          focal_spot_y,
          focal_spot_z,
          beam_divergence
        } = incident_light,
        { X, Y } = diffractive_order;
      return {
        azimut,
        zenit,
        wavelength,
        amplitude,
        polarization,
        incident_light_type,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        beam_divergence,
        X,
        Y,
        unit
      };
    }
    return null;
  }

  /**
   * @returns {Object} the far field settings used in the design if it exists
   * or the near field settings
   */
  async getDefaultSettings() {
    const { selectedDesignJobId } = this.props;
    if (selectedDesignJobId) {
      const targets = await DesignApi.getDesignJobTargets(selectedDesignJobId);
      let ffwf,
        nfwf,
        target_quantity = null;
      if (targets.ffwfs.length) {
        ffwf = targets.ffwfs[0];
        target_quantity = ffwf.target_quantity;
      } else {
        nfwf = targets.nfwfs[0];
      }
      return {
        selected_wave_front: ffwf
          ? ffwf.target_quantity === "angular"
            ? "FOURIER"
            : "FFWF"
          : "NFWF",
        width: ffwf ? ffwf.WFWidth : nfwf.NFWidth,
        height: ffwf ? ffwf.WFHeight : nfwf.NFHeight,
        ff_target_unit: ffwf ? ffwf.unit : nfwf.unit,
        rows_count: ffwf ? ffwf.WFHeight : nfwf.NFHeight,
        columns_count: ffwf ? ffwf.WFWidth : nfwf.NFWidth,
        X: ffwf ? ffwf.center_coord[0] : 0,
        Y: ffwf ? ffwf.center_coord[1] : 0,
        Z: ffwf ? ffwf.center_coord[2] : 0,
        alpha: ffwf ? ffwf.euler_Angles[0] : 0,
        beta: ffwf ? ffwf.euler_Angles[1] : 0,
        gama: ffwf ? ffwf.euler_Angles[2] : 0,
        angular_center_angle_x:
          ffwf && target_quantity === "angular"
            ? ffwf.angular_center_angles[0]
            : 0,
        angular_center_angle_y:
          ffwf && target_quantity === "angular"
            ? ffwf.angular_center_angles[1]
            : 0,
        opening_angle_x:
          ffwf && target_quantity === "angular" ? ffwf.opening_angles[0] : 180,
        opening_angle_y:
          ffwf && target_quantity === "angular" ? ffwf.opening_angles[1] : 180,
        angular_unit:
          ffwf && target_quantity === "angular" ? ffwf.angular_unit : "degrees",
        ff_prop_type: "ASM",
        ff_zero_padding: true,
        ff_bandlimit: true
      };
    }
    return null;
  }

  /**
   * @returns {Object} the component properties used to run the selected design
   */
  async getDesignComponent() {
    const { selectedDesignJobId } = this.props;
    if (selectedDesignJobId) {
      const data = await DesignApi.getDesignJobTargets(selectedDesignJobId);
      return data.meta_component;
    }
    return null;
  }

  /**
   * it filters only variables that are used by the job
   * @param {Object} job - the analysis job
   */
  getUsedVariables(job) {
    if (job) {
      const { swept_variables } = job;
      if (swept_variables.length) {
        const jobstring = JSON.stringify(job);
        return swept_variables.filter(variable =>
          jobstring.includes(`"=${variable.name}"`)
        );
      }
    }
    return [];
  }

  hideJsonDialog = () => {
    this.setState({ jsonToShow: null });
  };

  /**
   * it opens the json dialog with errors of the analysis jobs
   * @param {Object} errors - the errors
   */
  onShowResultsErrorsAndWarnings = (errors, warnings) => {
    this.setState({
      jsonToShow: {
        errors,
        warnings
      }
    });
  };

  /**
   * it stops a running analysis job
   * @param {Number} jobId - the job id
   */
  stopAnalysisJob = jobId => {
    return AnalysisApi.stopAnalysisJob(jobId).then(response => {
      this.getAnalysisJobProgress();
      return Promise.resolve();
    });
  };

  getDesignODAJobList = async () => {
    const { selectedDesignJobId } = this.props;
    if (selectedDesignJobId) {
      const odaJobList = await ODAApi.getFinishedODAJobsByDesignId(
        selectedDesignJobId
      );
      this.setState({
        designOdaJobs: odaJobList
      });
    }
  };

  getSelectedOdaDetails = async () => {
    const { selected_oda } = this.state;
    if (selected_oda) {
      const oda_details = await ODAApi.getODAJobDetails(selected_oda);
      this.setState({
        selected_oda_details: oda_details
      });
    }
  };

  render() {
    const {
        classes,
        designJobs,
        analysisJobs,
        selectedAnalysisJobId,
        setPoints,
        selectedDesignTargets,
        ffwfTargets,
        designTargets,
        metaComponents,
        openMetaComponentId
      } = this.props,
      {
        polling,
        jsonToShow,
        defaultSetPoint,
        defaultSettings,
        designComponent,
        message,
        selected_oda_details
      } = this.state,
      openMetaComponent = metaComponents.byId[openMetaComponentId],
      designJobsList = this.getDesignJobsList(designJobs),
      analysisJobsList = this.getAnalysisJobsList(analysisJobs),
      selectedAnalysisJob = selectedAnalysisJobId
        ? analysisJobs.byId[selectedAnalysisJobId]
        : null,
      setPoint = selected_oda_details
        ? this.getSetPoint(selected_oda_details)
        : selectedAnalysisJob
        ? this.getSetPoint(selectedAnalysisJob)
        : null,
      settings = selectedAnalysisJob
        ? this.getSettings(selectedAnalysisJob)
        : null,
      outgoingConditions = selected_oda_details
        ? this.getOutgoingConditions(selected_oda_details)
        : selectedAnalysisJob
        ? this.getOutgoingConditions(selectedAnalysisJob)
        : null,
      sweptVariables = selectedAnalysisJob
        ? selectedAnalysisJob.swept_variables
        : [];
    return (
      <div>
        <div className={classes.wrapper}>
          {polling && (
            <div className={classes.progress}>
              {selectedAnalysisJob && (
                <Typography>
                  Analysis status: {selectedAnalysisJob.status}
                </Typography>
              )}
              <div className={classes.wrapper}>
                <Spinner name="Waiting" size={68} timeout={180000} />
              </div>
              <div className={classes.wrapper}>
                {selectedAnalysisJob && (
                  <Typography>{`${selectedAnalysisJob.progress} %`}</Typography>
                )}
              </div>
              <br />
              {selectedAnalysisJob && (
                <>
                  <LinearProgress
                    name="SimulationProgress"
                    variant="determinate"
                    value={
                      selectedAnalysisJob.progress &&
                      selectedAnalysisJob.progress
                    }
                  />
                  {(selectedAnalysisJob.status === "RUNNING" ||
                    selectedAnalysisJob.status === "QUEUED") && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "relative",
                        marginTop: 20
                      }}
                    >
                      <Button
                        test-data="stopBtn"
                        name="StopAnalysisButton"
                        variant="contained"
                        onClick={() =>
                          this.stopAnalysisJob(selectedAnalysisJob.id)
                        }
                      >
                        Stop Analysis
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {!polling && (
            <div>
              {analysisJobs.loaded &&
                designJobs.loaded &&
                setPoints.loaded &&
                selectedDesignTargets.loaded &&
                ffwfTargets.loaded &&
                designTargets.loaded &&
                designComponent && (
                  <div>
                    {selectedAnalysisJob &&
                      selectedAnalysisJob.status === "DONE" && (
                        <AnalysisJobResult
                          jobId={selectedAnalysisJob.id}
                          sweptVariables={this.getUsedVariables(
                            selectedAnalysisJob
                          )}
                          ffwfDx={selectedAnalysisJob.center_coord[0]}
                          ffwfDy={selectedAnalysisJob.center_coord[1]}
                          ffwfDz={selectedAnalysisJob.center_coord[2] || null}
                          ffwfWidth={selectedAnalysisJob.width}
                          ffwfHeight={selectedAnalysisJob.height}
                          columns_count={selectedAnalysisJob.columns_count}
                          rows_count={selectedAnalysisJob.rows_count}
                          ffwfUnit={selectedAnalysisJob.ff_target_unit}
                          angularUnit={selectedAnalysisJob.angular_unit}
                          nfwfWidth={designComponent.width}
                          nfwfHeight={designComponent.height}
                          nfwfUnit={designComponent.unit}
                          angular_center_angle_x={
                            selectedAnalysisJob.angular_center_angles[0]
                          }
                          angular_center_angle_y={
                            selectedAnalysisJob.angular_center_angles[1]
                          }
                          opening_angle_x={
                            selectedAnalysisJob.opening_angles[0]
                          }
                          opening_angle_y={
                            selectedAnalysisJob.opening_angles[1]
                          }
                          setPoint={setPoint}
                          outgoingConditions={outgoingConditions}
                        />
                      )}
                    <div className={classes.left}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <TextField
                            className={classes.nameInput}
                            name="name"
                            label="Name"
                            value={this.state.analysisName}
                            onChange={e =>
                              this.setState({ analysisName: e.target.value })
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl style={{ width: "100%" }}>
                            <div>
                              <FormLabel
                                style={{ fontSize: 12 }}
                                htmlFor="nfwf_type"
                              >
                                NFWF Type
                              </FormLabel>
                            </div>
                            <UnselfishSelect
                              name="nfwf_type"
                              value={this.state.nfwf_type}
                              onChange={e => {
                                let nfwf_type = e.target.value;
                                let new_state = {
                                  nfwf_type
                                };
                                let callback = null;
                                if (nfwf_type === "ODA") {
                                  callback = this.getDesignODAJobList;
                                } else {
                                  new_state["selected_oda"] = null;
                                  new_state["selected_oda_details"] = null;
                                }
                                this.setState(new_state, callback);
                              }}
                            >
                              <MenuItem name="nfwf_type_option" value={"LPA"}>
                                Local Periodic Approximation
                              </MenuItem>
                              <MenuItem name="nfwf_type_option" value={"ODA"}>
                                Overlapping Domain Analysis
                              </MenuItem>
                            </UnselfishSelect>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          {this.state.nfwf_type === "ODA" && (
                            <FormControl style={{ width: "100%" }}>
                              <div>
                                <FormLabel
                                  style={{ fontSize: 12 }}
                                  htmlFor="nfwf_type"
                                >
                                  Calculated ODAs
                                </FormLabel>
                                {/* <IconTooltip text={unitTooltip} /> */}
                              </div>
                              <UnselfishSelect
                                name="selected_oda"
                                value={this.state.selected_oda}
                                onChange={e => {
                                  this.setState(
                                    { selected_oda: e.target.value },
                                    this.getSelectedOdaDetails
                                  );
                                }}
                              >
                                {this.state.designOdaJobs.map(doj => (
                                  <MenuItem name="selected_oda" value={doj.id}>
                                    {doj.name}
                                  </MenuItem>
                                ))}
                              </UnselfishSelect>
                            </FormControl>
                          )}
                        </Grid>
                        {this.state.selected_oda_details && (
                          <Grid
                            item
                            xs={12}
                            style={{ paddingLeft: 10, paddingRight: 10 }}
                          >
                            <Typography test-data="oda_message">
                              Using settings from selected calculated ODA
                            </Typography>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={6}
                          style={{ paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Paper className={classes.paper} elevation={3}>
                            {defaultSetPoint && (
                              <SetPointForm
                                disableAll={this.state.selected_oda_details}
                                setPoint={setPoint}
                                isEditing={true}
                                bindResetForm={() => {}}
                                bindSubmitForm={this.bindSetPointFormSubmitter}
                                isSweep
                                sweptVariables={this.getTempVariables()}
                                onSubmit={this.setSetPointReady}
                                defaultValues={defaultSetPoint}
                                hideAccuracy={openMetaComponent.family_is_pdk}
                              />
                            )}
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <AnalysisDetails
                            outgoingConditions={outgoingConditions}
                            bindResetForm={() => {}}
                            bindSubmitForm={
                              this.bindAnalysisOutgoingConditionsFormSubmitter
                            }
                            disabled={this.state.selected_oda_details}
                            onSubmit={this.setAnalysisOutgoingConditionsReady}
                          />
                          <div style={{ margin: 18 }} />
                          <AnalysisVariables sweptVariables={sweptVariables} />
                        </Grid>
                        <Grid item xs={12}>
                          {defaultSettings && (
                            <AnalysisSettings
                              settings={settings}
                              bindResetForm={() => {}}
                              bindSubmitForm={
                                this.bindAnalysisSettingsFormSubmitter
                              }
                              sweptVariables={this.getTempVariables()}
                              onSubmit={this.setAnalysisSettingsReady}
                              defaultValues={defaultSettings}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.right}>
                      <DesignJobDropdown designJobs={designJobsList} />
                      <AnalysisJobDropdown analysisJobs={analysisJobsList} />
                      <JobActions
                        jobType={"analysis"}
                        onNewJob={this.onShowNewJobDialog}
                        showUpdateJobDialog={
                          selectedAnalysisJob && this.onShowUpdateJobDialog
                        }
                        exportAnalysis={
                          selectedAnalysisJob &&
                          selectedAnalysisJob.status !== "STOPPED" &&
                          this.startAnalysisExport
                        }
                        exporting={this.state.exporting}
                        showResultsErrorsAndWarnings={
                          selectedAnalysisJob &&
                          (selectedAnalysisJob.errors ||
                            selectedAnalysisJob.warnings) &&
                          (() =>
                            this.onShowResultsErrorsAndWarnings(
                              selectedAnalysisJob.errors,
                              selectedAnalysisJob.warnings
                            ))
                        }
                        errors={selectedAnalysisJob?.errors}
                        warnings={selectedAnalysisJob?.warnings}
                        showResultMetrics={
                          selectedAnalysisJob && this.showResultMetrics
                        }
                      />
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
        <JsonDialog
          open={jsonToShow !== null}
          data={jsonToShow}
          onClose={this.hideJsonDialog}
        />
        {message && <DirectionSnackbar message={message} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    designJobs: DesignSelector.getDesignJobs(state),
    selectedDesignJobId: DesignSelector.getSelectedJobId(state),
    selectedAnalysisJobId: AnalysisSelector.getSelectedJobId(state),
    tempVariables: AnalysisSelector.getTempSweptVariables(state),
    analysisJobs: AnalysisSelector.getAnalysisJobs(state),
    setPoints: SetPointSelector.getSetPoints(state),
    selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
      state
    ),
    ffwfTargets: FFWFTargetSelector.getFFWTTargets(state),
    designTargets: DesignTargetSelector.getDesignTargets(state),
    metaComponents: DirectoryExplorerSelector.getMetaComponents(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createAnalysis: designJobId => dispatch(AnalysisApi.start(designJobId)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    selectAnalysisJob: jobId => dispatch(AnalysisAction.selectJob(jobId)),
    getAnalysisJobProgressAction: jobId =>
      dispatch(AnalysisApi.getAnalysisJobProgress(jobId)),
    updateAnalysis: (analysisId, properties) =>
      dispatch(AnalysisApi.restart(analysisId, properties)),
    resetDesignJobs: () => dispatch(DesignAction.resetJobs())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(AnalysisCanvas)));
