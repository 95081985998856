import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import StructureSelector from "MetaCell/selectors/Structure";
import MaterialSelector from "MetaCell/selectors/Material";
import StructureAction from "MetaCell/actions/Structure";
import SweepInput from "components/SweepInput/SweepInput";
import Autocomplete from "components/Autocomplete";
import StructureApi from "MetaCell/api/Structure";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import Sync from "@material-ui/icons/Sync";
import LoadingOverlay from "components/LoadingOverlay/LoadingOverlay";
import Grid from "@material-ui/core/Grid";

export const UsedMaterialField = ({ field }) => {
  return <span>{field || field === "0" ? field : "taken from library"}</span>;
};

/**
 * A component to display all materials used by the selected layer
 * @author Ibtihel
 */
export class LayerUsedMaterials extends PureComponent {
  constructor() {
    super();
    this.tableRef = null;
    this.state = {
      InvertMaterialBtnDisabled: false
    };
  }
  /**
   * it saves the editing used material
   * @callback
   */
  saveEditingUsedMaterial = newData => {
    return new Promise((resolve, reject) => {
      const { applyUsedMaterialEditingAction, updateCombination } = this.props;
      applyUsedMaterialEditingAction(newData)
        .then(() => {
          if (updateCombination) {
            updateCombination();
          }
          resolve();
        })
        .catch(() => reject());
    });
  };

  /**
   * it gets a material's name by the id
   * @param {Number} materialId
   * @return {String} the material' name
   */
  getMaterialName = materialId => {
    if (materialId == null) return "";
    const material = this.props.layerUsedMaterialsMaterials.find(
      material => material?.id === materialId
    );
    if (material == null) return "";
    return material.name;
  };

  /**
   * it gets a material's color
   * @param {Number} materialId
   * @return {String} the material's color
   */
  getMaterialColor = materialId => {
    const { materials } = this.props;
    const material = Object.values(materials.byId).find(
      material => material.id === materialId
    );
    if (material == null) return "";
    return material.color;
  };

  /**
   * it unselects the used material if esc was pressed
   * @param {Object} event - the keydown event
   * @callback
   */
  handleRowKeyDown = event => {
    if (event.key === "Escape") {
      this.props.selectUsedMaterialAction(-1);
    }
  };

  setTableRef = ref => {
    this.tableRef = ref;
  };

  // Swap materials function
  swap(object1, prop1, object2, prop2) {
    [object1[prop1], object2[prop2]] = [object2[prop2], object1[prop1]];
  }

  // Patches two materials used to invert them
  async invertMaterialsUsed(layerUsedMaterials) {
    this.setState({
      InvertMaterialBtnDisabled: true
    });

    const [firstMaterial, secondMaterial] = layerUsedMaterials;

    try {
      await Promise.all([
        this.swap(firstMaterial, "material", secondMaterial, "material"),
        this.swap(
          firstMaterial,
          "absorptionCoeff",
          secondMaterial,
          "absorptionCoeff"
        ),
        this.swap(
          firstMaterial,
          "refractiveIndex",
          secondMaterial,
          "refractiveIndex"
        ),
        this.saveEditingUsedMaterial(firstMaterial),
        this.saveEditingUsedMaterial(secondMaterial)
      ]);
      this.setState({
        InvertMaterialBtnDisabled: false
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const {
      layerUsedMaterials,
      getSelectOptions,
      layer,
      selectUsedMaterialAction,
      selectedUsedMaterialId,
      isFamilySimulation
    } = this.props;

    const { InvertMaterialBtnDisabled } = this.state;
    if (isFamilySimulation) {
      this.setState({ InvertMaterialBtnDisabled: true });
    }
    if (layer === undefined) {
      return <div />;
    }
    return (
      <>
        <Grid
          name="InvertMaterialLayerStack"
          item
          style={{
            display: "block",
            position: "relative"
          }}
        >
          {InvertMaterialBtnDisabled && !isFamilySimulation && (
            <LoadingOverlay />
          )}
          <EnhancedMaterialTable
            style={{ marginTop: 15, overflow: "auto" }}
            slim
            setTableRef={this.setTableRef}
            selectedEntityId={selectedUsedMaterialId}
            selectEntity={selectUsedMaterialAction}
            onRowKeyDown={this.handleRowKeyDown}
            options={{
              paging: false,
              search: false,
              sorting: false,
              draggable: false,
              actionsColumnIndex: 4
            }}
            actions={
              layerUsedMaterials.length >= 2 && [
                {
                  icon: Sync,
                  tooltip: "Invert layer materials",
                  isFreeAction: true,
                  name: "invertButton",
                  disabled: InvertMaterialBtnDisabled,
                  onClick: () => {
                    this.invertMaterialsUsed(layerUsedMaterials);
                  }
                }
              ]
            }
            title={
              this.props.title ||
              `Materials used by layer ${layer && layer.name}`
            }
            columns={[
              {
                title: "Material",
                field: "material",
                render: rowData => {
                  return <span>{this.getMaterialName(rowData.material)}</span>;
                },
                editComponent: props => {
                  const { value, onChange } = props;
                  const options = getSelectOptions(value).sort((a, b) =>
                    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
                  );
                  return (
                    <Autocomplete
                      id="material"
                      name="material"
                      options={options}
                      value={this.props.materials.byId[value]?.name || ""}
                      initialInputValue={
                        this.props.materials.byId[value]?.name || ""
                      }
                      onChange={onChange}
                    />
                  );
                }
              },
              {
                title: "Color",
                field: "color",
                render: rowData => {
                  return (
                    <Paper
                      style={{
                        width: 15,
                        height: 15,
                        backgroundColor: this.getMaterialColor(rowData.material)
                      }}
                    />
                  );
                },
                editComponent: props => {
                  return (
                    <Paper
                      style={{
                        width: 15,
                        height: 15,
                        backgroundColor: this.getMaterialColor(
                          props.rowData.material
                        )
                      }}
                    />
                  );
                }
              },
              {
                title: "n",
                field: "refractiveIndex",
                tooltip:
                  "Refractive index (-). Leave empty to use library values",
                render: rowData => {
                  return <UsedMaterialField field={rowData.refractiveIndex} />;
                },
                editComponent: props => {
                  return (
                    <SweepInput
                      name="refractive_index"
                      disabled={false}
                      value={props.value ? props.value : ""}
                      onChange={newValue => props.onChange(newValue)}
                      handleSave={() => {
                        const oldData = {
                          ...props.rowData,
                          refractiveIndex: null
                        };
                        this.tableRef.current.onEditingApproved(
                          "update",
                          props.rowData,
                          oldData
                        );
                      }}
                      allowEmpty
                    />
                  );
                }
              },
              {
                title: "k",
                field: "absorptionCoeff",
                tooltip:
                  "Absorption coefficient (-). Leave empty to use library values",
                render: rowData => {
                  return <UsedMaterialField field={rowData.absorptionCoeff} />;
                },
                editComponent: props => {
                  return (
                    <SweepInput
                      name="absorption_coeff"
                      disabled={false}
                      value={props.value ? props.value : ""}
                      onChange={newValue => props.onChange(newValue)}
                      handleSave={() => {
                        const oldData = {
                          ...props.rowData,
                          absorptionCoeff: null
                        };
                        this.tableRef.current.onEditingApproved(
                          "update",
                          props.rowData,
                          oldData
                        );
                      }}
                      allowEmpty
                    />
                  );
                }
              }
            ]}
            data={layerUsedMaterials}
            editable={{
              isEditable: rowData =>
                !isNaN(this.props.layer.id) && !isFamilySimulation,
              onRowUpdate: newData => this.saveEditingUsedMaterial(newData)
            }}
            localization={{
              header: {
                actions: ""
              }
            }}
          />
        </Grid>
      </>
    );
  }
}

LayerUsedMaterials.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  materials: MaterialSelector.getMaterials(state),
  usedMaterials: StructureSelector.getUsedMaterials(state),
  selectedUsedMaterialId: StructureSelector.getSelectedUsedMaterialId(state)
});

const mapDispatchToProps = dispatch => {
  return {
    selectUsedMaterialAction: materialId =>
      dispatch(StructureAction.selectUsedMaterial(materialId)),
    applyUsedMaterialEditingAction: layer =>
      dispatch(StructureApi.applyUsedMaterialEditing(layer))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerUsedMaterials);
