import React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import {
  DescriptionOutlined,
  PlayArrowTwoTone,
  PlayArrowOutlined
} from "@material-ui/icons";
import { itemType } from "components/DirectoryExplorer/components/ExplorerTree/ExplorerTree";
import { withTreeItemHandlers } from "components/DirectoryExplorer/components/ExplorerTree/components/withTreeItemHandlers/withTreeItemHandlers";

/**
 * A component to display an item of type Simulation {@link ExplorerTreeItemType} in the tree explorer
 * @author Akira Kotsugai
 * @param {Object} props - the object is expected to have a simulation, the id of the parent item,
 * a style resolver a focus handler, a double click handler and the id of the open simulation.
 * @return {Component} a tree item component
 */
export const SimulationItem = ({
  item,
  getItemContentStyle,
  openCallback,
  openSimulationId,
  openSimulationRef,
  grouperType,
  handleClick
}) => {
  const nodeId = item[grouperType] + "_" + item.id;
  const highlight = openSimulationId === item.id;

  return (
    <TreeItem
      name="TreeSimulationItem"
      ref={item.id === openSimulationId ? openSimulationRef : null}
      test-data="simulation"
      classes={{
        content: getItemContentStyle(item.id, itemType.SIMULATION, highlight)
      }}
      id={item.id}
      nodeId={nodeId}
      text={item.name}
      label={item.name}
      type={itemType.SIMULATION}
      icon={
        !!item.family_id ? (
          <DescriptionOutlined
            style={{ marginRight: 5 }}
            test-data="fileIcon"
          />
        ) : item.id === openSimulationId ? (
          <PlayArrowTwoTone
            name="SimulationOpen"
            test-data="open"
            color="primary"
          />
        ) : (
          <PlayArrowOutlined name="SimulationClosed" test-data="closed" />
        )
      }
      onClick={e => handleClick(e)}
      onDoubleClick={e => openCallback(item.id)}
    />
  );
};

export default withTreeItemHandlers(SimulationItem);
